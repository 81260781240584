import React, { useCallback } from 'react';

import { LeadOrganisation } from '@customTypes/shared/LeadOrganisation';
import { Column } from '@customTypes/table';

import LinkButton from '@components/button/LinkButton';
import TableWithFetch from '@components/tableWithFetch/TableWithFetch';
import { useDispatch, useSelector } from 'react-redux';
import {
    getLeadOrganisations,
    getLeadOrganisationsCurrentPage,
    getLeadOrganisationsIsFetching,
    getLeadOrganisationsPageSize,
    getLeadOrganisationsTotalCount,
    getLeadOrganisationsTotalPages,
} from '@selectors/leadOrganisations';
import { fetchLeadOrganisations } from '@actions/leadOrganisations';
import ButtonRow from '@components/button/ButtonRow';

const LeadOrganisationsTable: React.FC = () => {
    const dispatch = useDispatch();
    const isFetching = useSelector(getLeadOrganisationsIsFetching);
    const leadOrganisations = useSelector(getLeadOrganisations);
    const currentPage = useSelector(getLeadOrganisationsCurrentPage);
    const pageSize = useSelector(getLeadOrganisationsPageSize);
    const totalPages = useSelector(getLeadOrganisationsTotalPages);
    const totalCount = useSelector(getLeadOrganisationsTotalCount);

    const fetchData = useCallback(
        (page: number, pageSize: number, searchTerm: string) => {
            dispatch(fetchLeadOrganisations(page, pageSize, searchTerm));
        },
        [dispatch],
    );

    return (
        <TableWithFetch
            columns={columns}
            rows={Object.values(leadOrganisations)}
            isLoading={isFetching}
            fetchData={fetchData}
            currentPage={currentPage}
            pageSize={pageSize}
            totalPages={totalPages}
            totalCount={totalCount}
            searchType="leadOrganisations"
        />
    );
};

export default LeadOrganisationsTable;

const columns: Column<LeadOrganisation>[] = [
    {
        key: 1,
        heading: 'Name',
        getValue: row => row.name,
        searchable: true,
    },
    {
        key: 2,
        heading: 'Workbooks ID',
        getValue: row => `${row.workbooksID}`,
        searchable: true,
    },
    {
        key: 3,
        heading: 'Company Number',
        getValue: row => row.companyNumber,
        searchable: true,
    },
    {
        key: 4,
        heading: '',
        getValue: row => (
            <ButtonRow alignment="right">
                <LinkButton color="grey" href={`lead-organisations/${row.id}`}>
                    View Details
                </LinkButton>
            </ButtonRow>
        ),
    },
];
