import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { ImmerseUKContactsResponse } from '@customTypes/shared/ImmerseUKContact';

export const fetchPendingImmerseUKContactsRequest = createAction(
    'fetchPendingImmerseUKContactsRequest',
);
export const fetchPendingImmerseUKContactsSuccess = createAction<ImmerseUKContactsResponse>(
    'fetchPendingImmerseUKContactsSuccess',
);
export const fetchPendingImmerseUKContactsFailure = createAction(
    'fetchPendingImmerseUKContactsFailure',
);

export const fetchPendingImmerseUKContacts = (
    page: number,
    pageSize: number,
    searchTerm: string,
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchPendingImmerseUKContactsRequest());
    try {
        const { data }: Response = await api.get(
            `immerseContacts/pending?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}`,
        );

        dispatch(fetchPendingImmerseUKContactsSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchPendingImmerseUKContactsFailure, e);
    }
};

interface Response {
    data: ImmerseUKContactsResponse;
}
