import React, { useState } from 'react';

import { Sync } from '@customTypes/shared/Sync';

import {
    syncHealthValues,
    syncStatusColors,
    syncStatusIcons,
    syncStatusNames,
    syncHealthIcons,
    syncHealthColors,
    syncHealthNames,
} from '@constants/enums/syncEnums';
import { formatTimeFromSeconds, splitOnUpperCase } from '@utils/generic';

import useDashboardSyncDetailsUtils from './hooks/useDashboardSyncDetailsUtils';

import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import Subtitle from '@components/typography/Subtitle';
import dayjs from 'dayjs';
import Tooltip from '@components/modal/Tooltip';

const DashboardSyncDetails: React.FC<SynsDetailsProps> = ({ syncs }) => {
    const [tooltipIndex, setTooltipIndex] = useState<number | null>(null);

    const {
        validHealthFrequencies,
        formatNextRunDate,
        getOrderedHealth,
        getMostRecentHealth,
        getDateNameFormat,
        getAmountToSlice,
        getAPIsList,
    } = useDashboardSyncDetailsUtils();

    return (
        <div className="dashboard-sync-pods flex-row flex-wrap">
            {Object.values(syncs).map(({ sync, stats, items, syncHealthByDate }, index) => {
                const lastSync = [...items].sort(
                    (a, b) => new Date(b.startedOn).getTime() - new Date(a.startedOn).getTime(),
                )[0];

                const lastHealth =
                    getMostRecentHealth(syncHealthByDate) || syncHealthValues.healthy;
                const orderedHealth = getOrderedHealth(syncHealthByDate);

                const lastEndedOn = lastSync && lastSync.endedOn ? dayjs(lastSync.endedOn) : null;
                const lastStartedOn = lastSync ? dayjs(lastSync.startedOn) : null;
                const lastSecondsDiff =
                    lastEndedOn && lastStartedOn ? lastEndedOn.diff(lastStartedOn, 'seconds') : 0;

                const sourceAPIs = !!sync.sourceAPIs
                    ? `<p class='title'><strong>Source API's</strong></p><p>${getAPIsList(
                          sync.sourceAPIs,
                      )}</p>`
                    : '';

                const destinationAPIs = !!sync.destinationAPIs
                    ? `<p class='title'><strong>Destination API's</strong></p><p>${getAPIsList(
                          sync.destinationAPIs,
                      )}</p>`
                    : '';

                return (
                    <ContentBlock key={sync.id} extraClasses="flex-column">
                        <div className="flex">
                            <div className="sync-details">
                                <Subtitle>
                                    {splitOnUpperCase(sync.name)}
                                    <i
                                        onMouseEnter={() => setTooltipIndex(index)}
                                        onMouseLeave={() => setTooltipIndex(null)}
                                        className="tooltip far fa-fw fa-info-circle"
                                    >
                                        <Tooltip
                                            visible={index === tooltipIndex}
                                            htmlText={`${sourceAPIs} ${destinationAPIs}`}
                                            customStyles={tooltipStyles}
                                            flipVertical
                                        />
                                    </i>
                                </Subtitle>
                                {stats.status ? (
                                    <p className={`status ${syncStatusColors[stats.status]}`}>
                                        <i
                                            className={`icon fa fa-${
                                                syncStatusIcons[stats.status]
                                            }`}
                                        ></i>{' '}
                                        {syncStatusNames[stats.status]}
                                    </p>
                                ) : (
                                    <p>--</p>
                                )}

                                <p>
                                    <strong>Next run</strong>:{' '}
                                    {stats.nextRun ? formatNextRunDate(stats.nextRun) : '--'}
                                </p>

                                <div className="current-stability">
                                    <i
                                        className={`health-icon far fa-fw fa-${syncHealthIcons[lastHealth]} ${syncHealthColors[lastHealth]}`}
                                        title="Recent stability"
                                    ></i>
                                    <p>{syncHealthNames[lastHealth]}</p>
                                </div>
                            </div>

                            <p>
                                <strong>Last run date / time</strong>
                                <br />
                                {lastSync && lastSync.endedOn
                                    ? `${dayjs(lastSync.startedOn).format(
                                          'DD/MM/YYYY HH:mm',
                                      )} - ${dayjs(lastSync.endedOn).format(
                                          'DD/MM/YYYY HH:mm',
                                      )} (${formatTimeFromSeconds(lastSecondsDiff)})`
                                    : '--'}
                            </p>

                            <p>
                                <strong>Last run summary</strong>
                                <br />
                                {lastSync ? (
                                    <>{lastSync.summary ? lastSync.summary : '--'}</>
                                ) : (
                                    '--'
                                )}
                            </p>
                        </div>

                        {validHealthFrequencies.includes(sync.syncFrequencyInterval) &&
                            !!orderedHealth.length && (
                                <div className="health-stats flex-row">
                                    {orderedHealth
                                        .map(item => {
                                            const health = item.health || syncHealthValues.healthy;

                                            return (
                                                <div key={item.date} className="item flex">
                                                    <p>
                                                        {getDateNameFormat(
                                                            item.date,
                                                            sync.syncFrequencyInterval,
                                                        )}
                                                    </p>
                                                    <i
                                                        className={`health-icon far fa-fw fa-${syncHealthIcons[health]} ${syncHealthColors[health]}`}
                                                    ></i>
                                                </div>
                                            );
                                        })
                                        .slice(
                                            Math.max(
                                                orderedHealth.length -
                                                    getAmountToSlice(sync.syncFrequencyInterval),
                                                0,
                                            ),
                                        )}
                                </div>
                            )}
                    </ContentBlock>
                );
            })}
        </div>
    );
};

const tooltipStyles = {
    bottom: '22px',
    right: '11px',
};

interface SynsDetailsProps {
    syncs: Record<number, Sync>;
}

export default DashboardSyncDetails;
