import React from 'react';
import dayjs from 'dayjs';

import { SyncItem } from '@customTypes/shared/Sync';
import { Column } from '@customTypes/table';

import { syncStatusNames, syncStatusColors, syncStatusIcons } from '@constants/enums/syncEnums';

import Table from '@components/table/Table';
import StatusBadge from '@components/table/StatusBadge';
import { formatTimeFromSeconds } from '@utils/generic';

const SyncSingleTable: React.FC<TableProps> = ({ items, isFetching }) => (
    <Table
        columns={columns}
        rows={Object.values(items)}
        isLoading={isFetching}
        defaultSortKey={2}
        defaultSortAsc={false}
        excludePagination
    />
);

const columns: Column<SyncItem>[] = [
    {
        key: 1,
        heading: 'Started',
        getValue: row => dayjs(row.startedOn).format('DD/MM/YYYY - HH:mm'),
        searchable: false,
    },
    {
        key: 2,
        heading: 'Completed',
        getValue: row => {
            if (!row.endedOn) return '--';
            return dayjs(row.endedOn).format('DD/MM/YYYY - HH:mm');
        },
        searchable: false,
    },
    {
        key: 3,
        heading: 'Runtime',
        getValue: row => {
            if (!row.startedOn || !row.endedOn) return '--';
            else {
                const endedOn = dayjs(row.endedOn);
                const startedOn = dayjs(row.startedOn);
                const seconds = endedOn.diff(startedOn, 'seconds');
                return formatTimeFromSeconds(seconds);
            }
        },
        searchable: false,
    },
    {
        key: 4,
        heading: 'State',
        getValue: ({ status, errorMessage }) => {
            if (status === null) return '--';
            return (
                <StatusBadge
                    status={status}
                    error={errorMessage}
                    color={syncStatusColors[status]}
                    icon={syncStatusIcons[status]}
                >
                    {syncStatusNames[status]}
                </StatusBadge>
            );
        },
        searchable: false,
    },
];

interface TableProps {
    items: SyncItem[];
    isFetching: boolean;
}

export default SyncSingleTable;
