import { useDispatch, useSelector } from 'react-redux';
import { postPendingChLeadResolve, deletePendingChLeadResolve } from '@actions/leadOrganisations';
import { getLeadOrganisationPendingChLeads } from '@selectors/leadOrganisations';
import { PendingChLead } from '@customTypes/shared/LeadOrganisation';

const usePendingChLeadResolve = (): ReturnProps => {
    const dispatch = useDispatch();
    const pendingChLeads = useSelector(getLeadOrganisationPendingChLeads);

    const resolvePendingChLead = (leadId: number) => {
        dispatch(postPendingChLeadResolve(leadId));
    };

    const deletePendingChLead = (leadId: number) => {
        dispatch(deletePendingChLeadResolve(leadId));
    };

    return { pendingChLeads, resolvePendingChLead, deletePendingChLead };
};

type ReturnFunction = (leadId: number) => void;

interface ReturnProps {
    pendingChLeads: Record<number, PendingChLead>;
    resolvePendingChLead: ReturnFunction;
    deletePendingChLead: ReturnFunction;
}

export default usePendingChLeadResolve;
