import ConfirmModal from '@components/modal/ConfirmModal';
import { useHistory } from 'react-router-dom';
import { AdminUser } from '@customTypes/shared/AdminUser';
import useDeleteAdmin from './hooks/useDeleteAdmin';

const DeleteAdminModal: React.FC<DeleteAdminProps> = ({ user }) => {
    const history = useHistory();
    const { deleteUser, isPosting } = useDeleteAdmin(user.id);

    return (
        <ConfirmModal
            title="Delete admin"
            description={`Are you sure you want to delete '${user.firstName} ${user.lastName}'?`}
            closeModal={() => history.replace(`/admins/${user.id}`)}
            handleSubmit={deleteUser}
            isPosting={isPosting}
            submitButtonColor="red"
        />
    );
};

interface DeleteAdminProps {
    user: AdminUser;
}

export default DeleteAdminModal;
