import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { CventContactsResponse } from '@customTypes/shared/CventContact';

export const fetchCventContactsRequest = createAction('fetchCventContactsRequest');
export const fetchCventContactsSuccess = createAction<CventContactsResponse>(
    'fetchCventContactsSuccess',
);
export const fetchCventContactsFailure = createAction('fetchCventContactsFailure');

export const fetchCventContacts = (page: number, pageSize: number, searchTerm: string) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(fetchCventContactsRequest());
    try {
        const { data }: Response = await api.get(
            `cventContacts?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}`,
        );

        dispatch(fetchCventContactsSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchCventContactsFailure, e);
    }
};

interface Response {
    data: CventContactsResponse;
}
