import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchSyncs, fetchSyncsRates, fetchSyncsRunning } from '@actions/syncs';
import {
    getSyncs,
    getSyncsFetchError,
    getSyncsIsFetching,
    getSyncsRates,
    getSyncsRatesFetchError,
    getSyncsRatesIsFetching,
    getSyncsRunning,
    getSyncsRunningFetchError,
    getSyncsRunningIsFetching,
} from '@selectors/syncs';

import { Sync, SyncRate } from '@customTypes/shared/Sync';

const useDashboardStatsFetch = (): ReturnProps => {
    const dispatch = useDispatch();
    const isFetchingSyncs = useSelector(getSyncsIsFetching);
    const isFetchingRates = useSelector(getSyncsRatesIsFetching);
    const isFetchingRunning = useSelector(getSyncsRunningIsFetching);
    const syncsError = useSelector(getSyncsFetchError);
    const ratesError = useSelector(getSyncsRatesFetchError);
    const runningError = useSelector(getSyncsRunningFetchError);
    const syncs = useSelector(getSyncs);
    const rates = useSelector(getSyncsRates);
    const running = useSelector(getSyncsRunning);

    useEffect(() => {
        dispatch(fetchSyncs());
        dispatch(fetchSyncsRates());
        dispatch(fetchSyncsRunning());
    }, [dispatch]);

    return {
        isFetchingSyncs,
        isFetchingRates,
        isFetchingRunning,
        syncsError,
        ratesError,
        runningError,
        syncs,
        rates,
        running,
    };
};

interface ReturnProps {
    isFetchingSyncs: boolean;
    isFetchingRates: boolean;
    isFetchingRunning: boolean;
    syncsError: string | null;
    ratesError: string | null;
    runningError: string | null;
    syncs: Record<number, Sync>;
    running: Record<number, Sync>;
    rates: SyncRate[];
}

export default useDashboardStatsFetch;
