import useAdminUsersFetch from './hooks/useAdminsFetch';
import { useSelector } from 'react-redux';

import AdminsTable from './AdminsTable';
import CreateAdminUserModal from './CreateAdminModal';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import LinkButton from '@components/button/LinkButton';
import { getAdminUsers, getAdminUsersIsFetching } from '@selectors/adminUsers';
import Helmet from '@components/generic/helmet/Helmet';
import PageTitle from '@components/typography/PageTitle';

const Admins: React.FC<AdminsProps> = ({ showCreateModal = false }) => {
    useAdminUsersFetch();
    const adminUsers = useSelector(getAdminUsers);
    const isFetching = useSelector(getAdminUsersIsFetching);

    return (
        <>
            <CreateHeader>
                <Helmet title="Admins" />
                <PageTitle>Admins</PageTitle>
                <LinkButton color="green" icon="plus" href="/admins/create">
                    Create
                </LinkButton>
            </CreateHeader>

            <AdminsTable adminUsers={adminUsers} isFetching={isFetching} />
            {showCreateModal && <CreateAdminUserModal />}
        </>
    );
};

interface AdminsProps {
    showCreateModal?: boolean;
}

export default Admins;
