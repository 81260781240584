import { useDispatch, useSelector } from 'react-redux';

import { syncCompany } from '@actions/companies';
import { getCompaniesIsSyncing } from '@selectors/companies';
import { StringNumberType } from '@customTypes/shared/Generics';

const useSingleCompanySync = (id: StringNumberType): ReturnProps => {
    const dispatch = useDispatch();
    const isSyncing = useSelector(getCompaniesIsSyncing);

    const handleSync = () => {
        dispatch(syncCompany(id));
    };

    return { isSyncing, handleSync };
};

type HandleSync = () => void;

interface ReturnProps {
    isSyncing: boolean;
    handleSync: HandleSync;
}

export default useSingleCompanySync;
