import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StringNumberType } from '@customTypes/shared/Generics';

import { fetchSingleImmerseUKContact } from '@actions/immerseUKContacts';
import { getImmerseUKContactsPostSuccess } from '@selectors/immerseUKContacts';
import usePrevious from '@hooks/usePrevious';

const useSingleImmerseUKContactFetch = (id: StringNumberType): void => {
    const dispatch = useDispatch();
    const postSuccess = useSelector(getImmerseUKContactsPostSuccess);
    const prevProps = usePrevious({ postSuccess });

    useEffect(() => {
        dispatch(fetchSingleImmerseUKContact(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (postSuccess && !prevProps.postSuccess) {
            dispatch(fetchSingleImmerseUKContact(id, true));
        }
    }, [dispatch, id, postSuccess, prevProps.postSuccess]);
};

export default useSingleImmerseUKContactFetch;
