import { DropdownOption } from '@customTypes/shared/DropdownOption';

export enum syncStatusValues {
    success = 0,
    running = 1,
    rateLimited = 2,
    errored = 3,
    waitingToRetry = 4,
    notYetRun = 5,
}

export const syncStatusNames: Record<number, string> = {
    [syncStatusValues.success]: 'Complete',
    [syncStatusValues.running]: 'Running',
    [syncStatusValues.rateLimited]: 'Rate-limited',
    [syncStatusValues.errored]: 'Errored',
    [syncStatusValues.waitingToRetry]: 'Waiting to retry',
    [syncStatusValues.notYetRun]: 'Not run yet',
};

export const syncStatusIcons: Record<number, string> = {
    [syncStatusValues.success]: 'check',
    [syncStatusValues.running]: 'cogs',
    [syncStatusValues.rateLimited]: 'hand-paper',
    [syncStatusValues.errored]: 'exclamation-triangle',
    [syncStatusValues.waitingToRetry]: 'clock',
    [syncStatusValues.notYetRun]: 'bell',
};

export const syncStatusColors: Record<number, string> = {
    [syncStatusValues.success]: 'green',
    [syncStatusValues.running]: 'yellow',
    [syncStatusValues.rateLimited]: 'yellow',
    [syncStatusValues.errored]: 'red',
    [syncStatusValues.waitingToRetry]: 'yellow',
    [syncStatusValues.notYetRun]: 'blue',
};

export enum syncFrequencyValues {
    minutes = 1,
    hours = 2, // by day
    days = 3, // by week
    weeks = 4,
    months = 5, // by month
    years = 6,
}

export const syncFrequencyNames: Record<number, string> = {
    [syncFrequencyValues.minutes]: 'Minutes',
    [syncFrequencyValues.hours]: 'Hours',
    [syncFrequencyValues.days]: 'Days',
    [syncFrequencyValues.weeks]: 'Weeks',
    [syncFrequencyValues.months]: 'Months',
    [syncFrequencyValues.years]: 'Years',
};

export enum syncRateFrequencyValues {
    seconds = 1,
    minutes = 2,
    hours = 3,
    days = 4,
    weeks = 5,
    months = 6,
    years = 7,
}

export const syncRateFrequencyNonPluralNames: Record<number, string> = {
    [syncRateFrequencyValues.seconds]: 'Second',
    [syncRateFrequencyValues.minutes]: 'Minute',
    [syncRateFrequencyValues.hours]: 'Hour',
    [syncRateFrequencyValues.days]: 'Day',
    [syncRateFrequencyValues.weeks]: 'Week',
    [syncRateFrequencyValues.months]: 'Month',
    [syncRateFrequencyValues.years]: 'Year',
};

export const syncRateFrequencyPluralNames: Record<number, string> = {
    [syncRateFrequencyValues.seconds]: 'Seconds',
    [syncRateFrequencyValues.minutes]: 'Minutes',
    [syncRateFrequencyValues.hours]: 'Hours',
    [syncRateFrequencyValues.days]: 'Days',
    [syncRateFrequencyValues.weeks]: 'Weeks',
    [syncRateFrequencyValues.months]: 'Months',
    [syncRateFrequencyValues.years]: 'Years',
};

export enum periodicSummaryTypeValues {
    daily = 0,
    weekly = 1,
    monthly = 2,
}

export const periodicSummaryTypeNames: Record<number, string> = {
    [periodicSummaryTypeValues.daily]: 'Daily',
    [periodicSummaryTypeValues.weekly]: 'Weekly',
    [periodicSummaryTypeValues.monthly]: 'Monthly',
};

export enum externalAPIValues {
    workbooks = 1,
    immerse = 2,
    cvent = 3,
    beauhurst = 4,
    companiesHouse = 5,
    dotDigital = 6,
    local = 7,
}

export const externalAPINames: Record<number, string> = {
    [externalAPIValues.workbooks]: 'Workbooks',
    [externalAPIValues.immerse]: 'Immerse',
    [externalAPIValues.cvent]: 'Cvent',
    [externalAPIValues.beauhurst]: 'Beauhurst',
    [externalAPIValues.companiesHouse]: 'Companies House',
    [externalAPIValues.dotDigital]: 'Dotdigital',
    [externalAPIValues.local]: 'Local',
};

export enum nodeTitleValues {
    DotdigitalContact = 1,
    WorkbooksPerson = 2,
    Company = 3,
    CompaniesHouseProfile = 4,
    WorkbooksOrganisation = 5,
    ImmerseContact = 6,
    WorkbooksCampaign = 7,
    DotdigitalMailingList = 8,
    BeauhurstCompany = 9,
    WorkbooksLeadOrganisation = 10,
    CventContact = 11,
    CventEvent = 12,
    WorkbooksCampaignMembership = 13,
    CventSession = 14,
    DotdigitalAddressBookContact = 15,
}

export const nodeTitleNames: Record<number, string> = {
    [nodeTitleValues.DotdigitalContact]: 'Dotdigital Contact',
    [nodeTitleValues.WorkbooksPerson]: 'Workbooks Person',
    [nodeTitleValues.Company]: 'Company',
    [nodeTitleValues.CompaniesHouseProfile]: 'Companies House Profile',
    [nodeTitleValues.WorkbooksOrganisation]: 'Workbooks Organisation',
    [nodeTitleValues.ImmerseContact]: 'Immerse UK Contact',
    [nodeTitleValues.WorkbooksCampaign]: 'Workbooks Campaign',
    [nodeTitleValues.DotdigitalMailingList]: 'Dotdigital Mailing List',
    [nodeTitleValues.BeauhurstCompany]: 'Beauhurst Company',
    [nodeTitleValues.WorkbooksLeadOrganisation]: 'Workbooks Lead Organisation',
    [nodeTitleValues.CventContact]: 'Cvent Contact',
    [nodeTitleValues.CventEvent]: 'Cvent Event',
    [nodeTitleValues.WorkbooksCampaignMembership]: 'Workbooks Campaign Membership',
    [nodeTitleValues.CventSession]: 'Cvent Session',
    [nodeTitleValues.DotdigitalAddressBookContact]: 'Dotdigital Address Book Contact',
};

export const dataSearchOptions: DropdownOption<number | null>[] = [
    { label: 'Any', value: null },
    { label: 'Dotdigital Contact', value: 1 },
    { label: 'Workbooks Person', value: 2 },
    { label: 'Companies House Profile', value: 4 },
    { label: 'Workbooks Organisation', value: 5 },
    { label: 'Immerse UK Contact', value: 6 },
    { label: 'Workbooks Campaign', value: 7 },
    { label: 'DotDigital Mailing List', value: 8 },
    { label: 'Beauhurst Company', value: 9 },
    { label: 'Workbooks Lead Organisation', value: 10 },
    { label: 'Cvent Contact', value: 11 },
    { label: 'Cvent Event', value: 12 },
    { label: 'Workbooks Campaign Membership', value: 13 },
];

export enum syncTypeValues {
    CompaniesFullSync = 1,
    CompaniesDailySync = 2,
    ContactsFullSync = 3,
    ContactsDailySync = 4,
    MailingListsSync = 5,
    ImmerseSync = 7,
    LeadOrganisationsDailySync = 8,
    LeadOrganisationsFullSync = 9,
    CventSync = 10,
    QualimentalSync = 12,
}

export const syncTypeOptions = [
    { label: 'Any', value: null },
    { label: 'Companies (Full Sync)', value: 1 },
    { label: 'Companies (Daily Sync)', value: 2 },
    { label: 'Contacts (Full Sync)', value: 3 },
    { label: 'Contacts (Daily Sync)', value: 4 },
    { label: 'Mailing Lists', value: 5 },
    { label: 'Immerse', value: 7 },
    { label: 'Lead Organisations (Daily Sync)', value: 8 },
    { label: 'Lead Organisations (Full Sync)', value: 9 },
    { label: 'Cvent Sync', value: 10 },
    { label: 'Qualimental', value: 12 },
];

export const isSuccessOptions = [
    { label: 'Successful', value: 'true' },
    { label: 'Errored', value: 'false' },
];

export const syncTypeNames: Record<number, string> = {
    [syncTypeValues.CompaniesFullSync]: 'Companies (Full Sync)',
    [syncTypeValues.CompaniesDailySync]: 'Companies (Daily Sync)',
    [syncTypeValues.ContactsFullSync]: 'Contacts (Full Sync)',
    [syncTypeValues.ContactsDailySync]: 'Contacts (Daily Sync)',
    [syncTypeValues.MailingListsSync]: 'Mailing Lists',
    [syncTypeValues.ImmerseSync]: 'Immerse',
    [syncTypeValues.LeadOrganisationsDailySync]: 'Lead Organisations (Daily Sync)',
    [syncTypeValues.LeadOrganisationsFullSync]: 'Lead Organisations (Full Sync)',
    [syncTypeValues.CventSync]: 'Cvent Sync',
    [syncTypeValues.QualimentalSync]: 'Qualimental Sync',
};

export enum syncFieldValues {
    ID = 0,
    WorkbooksID = 1,
    DotdigitalID = 2,
    ImmerseID = 3,
    BeauhurstID = 4,
    Name = 5,
    Postcode = 6,
    Phone = 7,
    FirstName = 8,
    LastName = 9,
    Email = 10,
    Address1 = 11,
    Address2 = 12,
    City = 13,
    StreetAddress = 14,
    Town = 15,
    JobTitle = 16,
    CompanyName = 17,
    CompanyNumber = 18,
    SicCodes = 19,
    NumberOfDirectors = 20,
    NumberOfEmployees = 21,
    Turnover = 22,
    CompaniesHouseProfiile = 23,
    IUKGrantsTotal = 24,
    TotalAmountGrants = 25,
    EmployerLink = 26,
    OrganisationName = 27,
    OrganisationNumber = 28,
    BeauhurstProfile = 29,
    MailingListName = 30,
    Status = 31,
    Category = 32,
    StartDate = 33,
    EndDate = 34,
    Contacts = 35,
    Visibility = 36,
    Description = 37,
    Twitter = 38,
    Membership = 39,
    LastSynced = 40,
    EmailType = 41,
    AddressBook = 42,
    WorkbooksPersonIDAdded = 43,
    CventEventID = 44,
    CventCode = 45,
    CreatedThroughReference = 46,
    IUKFundingRecipient = 47,
    WorkbooksCampaignID = 48,
    WorkbooksPersonID = 49,
    DotdigitalContactID = 50,
    DotdigitalAddressBookID = 51,
    LeadSourceType = 52,
}

export const syncFieldNames: Record<number, string> = {
    [syncFieldValues.ID]: 'ID',
    [syncFieldValues.WorkbooksID]: 'Workbooks ID',
    [syncFieldValues.DotdigitalID]: 'Dotdigital ID',
    [syncFieldValues.ImmerseID]: 'Immerse ID',
    [syncFieldValues.BeauhurstID]: 'Beauhurst ID',
    [syncFieldValues.Name]: 'Name',
    [syncFieldValues.Postcode]: 'Postcode',
    [syncFieldValues.Phone]: 'Phone',
    [syncFieldValues.FirstName]: 'First name',
    [syncFieldValues.LastName]: 'Last name',
    [syncFieldValues.Email]: 'Email',
    [syncFieldValues.Address1]: 'Address 1',
    [syncFieldValues.Address2]: 'Address 2',
    [syncFieldValues.City]: 'City',
    [syncFieldValues.StreetAddress]: 'Street Address',
    [syncFieldValues.Town]: 'Town',
    [syncFieldValues.JobTitle]: 'Job title',
    [syncFieldValues.CompanyName]: 'Company name',
    [syncFieldValues.CompanyNumber]: 'Company number',
    [syncFieldValues.SicCodes]: 'Sic Codes',
    [syncFieldValues.NumberOfDirectors]: 'No. of directors',
    [syncFieldValues.NumberOfEmployees]: 'No. of employees',
    [syncFieldValues.Turnover]: 'Turnover',
    [syncFieldValues.CompaniesHouseProfiile]: 'Companies House profile',
    [syncFieldValues.IUKGrantsTotal]: 'IUK Grants total',
    [syncFieldValues.TotalAmountGrants]: 'Total amount grants',
    [syncFieldValues.EmployerLink]: 'Employer link',
    [syncFieldValues.OrganisationName]: 'Organisation name',
    [syncFieldValues.OrganisationNumber]: 'Organisation number',
    [syncFieldValues.BeauhurstProfile]: 'Beauhurst profile',
    [syncFieldValues.MailingListName]: 'Mailing list name',
    [syncFieldValues.Status]: 'Status',
    [syncFieldValues.Category]: 'Category',
    [syncFieldValues.StartDate]: 'Start date',
    [syncFieldValues.EndDate]: 'End date',
    [syncFieldValues.Contacts]: 'Contacts',
    [syncFieldValues.Visibility]: 'Visibility',
    [syncFieldValues.Description]: 'Description',
    [syncFieldValues.Twitter]: 'Twitter',
    [syncFieldValues.Membership]: 'Membership',
    [syncFieldValues.LastSynced]: 'Last synced',
    [syncFieldValues.EmailType]: 'Email type',
    [syncFieldValues.AddressBook]: 'Address book',
    [syncFieldValues.WorkbooksPersonIDAdded]: 'Workbooks person added',
    [syncFieldValues.CventEventID]: 'Cvent Event ID',
    [syncFieldValues.CventCode]: 'Cvent Code',
    [syncFieldValues.CreatedThroughReference]: 'Created Through Reference',
    [syncFieldValues.IUKFundingRecipient]: 'IUK Funding Recipient',
    [syncFieldValues.WorkbooksCampaignID]: 'Workbooks Campaign ID',
    [syncFieldValues.WorkbooksPersonID]: 'Workbooks Person ID',
    [syncFieldValues.DotdigitalContactID]: 'Dotdigital Contact ID',
    [syncFieldValues.DotdigitalAddressBookID]: 'Dotdigital AddressBook ID',
};

export enum externalAPIMethodValues {
    Get = 1,
    Update = 2,
    Insert = 3,
    Delete = 4,
}

export const externalAPIMethodNames: Record<number, string> = {
    [externalAPIMethodValues.Get]: 'Read',
    [externalAPIMethodValues.Update]: 'Update',
    [externalAPIMethodValues.Insert]: 'Create',
    [externalAPIMethodValues.Delete]: 'Delete',
};

export enum syncHealthValues {
    healthy = 1,
    moderate = 2,
    poor = 3,
}

export const syncHealthNames: Record<number, string> = {
    [syncHealthValues.healthy]: 'Healthy',
    [syncHealthValues.moderate]: 'Moderate',
    [syncHealthValues.poor]: 'Poor',
};

export const syncHealthIcons: Record<number, string> = {
    [syncHealthValues.healthy]: 'check',
    [syncHealthValues.moderate]: 'ellipsis-h',
    [syncHealthValues.poor]: 'times',
};

export const syncHealthColors: Record<number, string> = {
    [syncHealthValues.healthy]: 'green',
    [syncHealthValues.moderate]: 'orange',
    [syncHealthValues.poor]: 'red',
};
