import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

export const postPendingChLeadResolveRequest = createAction('postPendingChLeadResolveRequest');
export const postPendingChLeadResolveSuccess = createAction<number>(
    'postPendingChLeadResolveSuccess',
);
export const postPendingChLeadResolveFailure = createAction('postPendingChLeadResolveFailure');

export const postPendingChLeadResolve = (id: number) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(postPendingChLeadResolveRequest());
    try {
        await api.post(`leadOrganisations/chLead/${id}/resolve`, {});
        dispatch(postPendingChLeadResolveSuccess(id));
    } catch (e) {
        handleApiErrors(dispatch, postPendingChLeadResolveFailure, e);
    }
};
