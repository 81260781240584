import { DotMailerConflictFormProps } from '@customTypes/shared/DotMailerContact';
import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

export const updateDotMailerConflictRequest = createAction('updateDotMailerConflictRequest');
export const updateDotMailerConflictSuccess = createAction<number>(
    'updateDotMailerConflictSuccess',
);
export const updateDotMailerConflictFailure = createAction('updateDotMailerConflictFailure');

export const updateDotMailerConflict = (
    id: number,
    contactID: number,
    postBody: DotMailerConflictFormProps,
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(updateDotMailerConflictRequest());
    try {
        await api.post(`dotmailerContacts/${contactID}/resolve`, postBody);
        dispatch(updateDotMailerConflictSuccess(id));
    } catch (e) {
        handleApiErrors(dispatch, updateDotMailerConflictFailure, e);
    }
};
