import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from '@utils/generic';

import {
    fetchDotMailerContactsRequest,
    fetchDotMailerContactsSuccess,
    fetchDotMailerContactsFailure,
    fetchPendingDotMailerContactsRequest,
    fetchPendingDotMailerContactsSuccess,
    fetchPendingDotMailerContactsFailure,
    fetchSingleDotMailerContactRequest,
    fetchSingleDotMailerContactSuccess,
    fetchSingleDotMailerContactFailure,
    syncDotMailerContactRequest,
    syncDotMailerContactSuccess,
    syncDotMailerContactFailure,
    updateDotMailerConflictRequest,
    updateDotMailerConflictSuccess,
    updateDotMailerConflictFailure,
} from '@actions/dotMailerContacts';

import {
    DotMailerContact,
    DotMailerContactConflict,
    DotMailerContactLog,
    DotMailerContactsResponse,
    DotMailerSingleContactResponse,
} from '@customTypes/shared/DotMailerContact';
import { PaginationProps } from '@customTypes/shared/Pagination';

interface DotMailerContactsState extends PaginationProps {
    isFetching: boolean;
    isSyncing: boolean;
    isPosting: boolean;
    fetchError: string | null;
    syncError: string | null;
    postError: string | null;
    postSuccess: boolean;
    contacts: Record<number, DotMailerContact>;
    pendingContacts: Record<number, DotMailerContact>;
    logs: Record<number, DotMailerContactLog>;
    conflicts: Record<number, DotMailerContactConflict>;
}

const initialState: DotMailerContactsState = {
    isFetching: false,
    isSyncing: false,
    isPosting: false,
    fetchError: null,
    syncError: null,
    postError: null,
    postSuccess: false,
    contacts: {},
    pendingContacts: {},
    logs: {},
    conflicts: {},
    currentPage: 1,
    pageSize: 10,
    totalCount: null,
    totalPages: null,
};

export default createReducer(initialState, {
    [fetchDotMailerContactsRequest.type]: handleFetchRequest,
    [fetchDotMailerContactsFailure.type]: handleFetchFailure,
    [fetchDotMailerContactsSuccess.type]: handleFetchAllSuccess,
    [fetchPendingDotMailerContactsRequest.type]: handleFetchRequest,
    [fetchPendingDotMailerContactsFailure.type]: handleFetchFailure,
    [fetchPendingDotMailerContactsSuccess.type]: handleFetchPendingSuccess,
    [fetchSingleDotMailerContactRequest.type]: handleFetchRequest,
    [fetchSingleDotMailerContactFailure.type]: handleFetchFailure,
    [fetchSingleDotMailerContactSuccess.type]: handleFetchSingleSuccess,
    [syncDotMailerContactRequest.type]: handleSyncRequest,
    [syncDotMailerContactFailure.type]: handleSyncFailure,
    [syncDotMailerContactSuccess.type]: handleSyncSuccess,
    [updateDotMailerConflictRequest.type]: handlePostRequest,
    [updateDotMailerConflictFailure.type]: handlePostFailure,
    [updateDotMailerConflictSuccess.type]: handleUpdateConflictSuccess,
});

function handleFetchRequest(state: DotMailerContactsState, action: PayloadAction<boolean>) {
    state.isFetching = true;
    state.fetchError = null;
    if (!action.payload) {
        state.isSyncing = false;
        state.logs = {};
        state.conflicts = {};
    }
}

function handleFetchFailure(state: DotMailerContactsState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.fetchError = action.payload;
}

function handleFetchAllSuccess(
    state: DotMailerContactsState,
    action: PayloadAction<DotMailerContactsResponse>,
) {
    const { contacts, currentPage, pageSize, totalCount, totalPages } = action.payload;

    state.isFetching = false;
    state.contacts = convertArrToObj(contacts);
    state.currentPage = currentPage;
    state.pageSize = pageSize;
    state.totalCount = totalCount;
    state.totalPages = totalPages;
}

function handleFetchPendingSuccess(
    state: DotMailerContactsState,
    action: PayloadAction<DotMailerContactsResponse>,
) {
    const { contacts, currentPage, pageSize, totalCount, totalPages } = action.payload;

    state.isFetching = false;
    state.pendingContacts = convertArrToObj(contacts);
    state.currentPage = currentPage;
    state.pageSize = pageSize;
    state.totalCount = totalCount;
    state.totalPages = totalPages;
}

function handleFetchSingleSuccess(
    state: DotMailerContactsState,
    action: PayloadAction<DotMailerSingleContactResponse>,
) {
    const { contact, conflicts, logs } = action.payload;

    state.isFetching = false;
    state.contacts[contact.id] = contact;
    state.conflicts = convertArrToObj(conflicts);
    state.logs = convertArrToObj(logs);
}

function handleSyncRequest(state: DotMailerContactsState) {
    state.isSyncing = true;
    state.syncError = null;
}

function handleSyncFailure(state: DotMailerContactsState, action: PayloadAction<string>) {
    state.isSyncing = false;
    state.syncError = action.payload;
}

function handleSyncSuccess(
    state: DotMailerContactsState,
    action: PayloadAction<DotMailerSingleContactResponse>,
) {
    const { contact, conflicts, logs } = action.payload;

    state.isSyncing = false;
    state.contacts[contact.id] = contact;
    state.conflicts = convertArrToObj(conflicts);
    state.logs = convertArrToObj(logs);
}

function handlePostRequest(state: DotMailerContactsState) {
    state.isPosting = true;
    state.postError = null;
    state.postSuccess = false;
}

function handlePostFailure(state: DotMailerContactsState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.postError = action.payload;
}

function handleUpdateConflictSuccess(state: DotMailerContactsState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.conflicts[action.payload];
}
