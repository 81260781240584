import dayjs from 'dayjs';

import { syncHealthValues, syncFrequencyValues } from '@constants/enums/syncEnums';
import { convertUKDateToDateFormat } from '@utils/generic';
import { externalAPINames, externalAPIValues } from '@constants/enums/apiEnums';

declare module 'dayjs' {
    interface Dayjs {
        fromNow(withoutSuffix?: boolean): string;
    }
}

const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

const useDashboardSyncDetailsUtils = (): ReturnProps => {
    return {
        validHealthFrequencies,
        formatNextRunDate,
        getOrderedHealth,
        getMostRecentHealth,
        getDateNameFormat,
        getAmountToSlice,
        getAPIsList,
    };

    function formatNextRunDate(date: string): string {
        const isBefore = dayjs(date).isBefore(dayjs());

        if (isBefore) return 'Due to start';
        return dayjs(date).fromNow();
    }

    function getOrderedHealth(syncHealthByDate: SyncHealthByDate): OrderedHealthReturnProps[] {
        const dates = Object.keys(syncHealthByDate);
        const sortedAsc = dates.sort(
            (a, b) =>
                convertUKDateToDateFormat(a).getTime() - convertUKDateToDateFormat(b).getTime(),
        );
        const ordered = sortedAsc.map(date => {
            return {
                date,
                health: syncHealthByDate[date],
            };
        });

        return ordered;
    }

    function getMostRecentHealth(syncHealthByDate: SyncHealthByDate): syncHealthValues {
        const dates = Object.keys(syncHealthByDate);
        const length = dates.length;
        const sortedAsc = dates.sort(
            (a, b) =>
                convertUKDateToDateFormat(a).getTime() - convertUKDateToDateFormat(b).getTime(),
        );
        const lastDate = sortedAsc[length - 1];

        return syncHealthByDate[lastDate];
    }

    function getDateNameFormat(date: string, syncFrequency: syncFrequencyValues): string {
        const convertDate = convertUKDateToDateFormat(date);
        let formattedDate = date;

        switch (syncFrequency) {
            case syncFrequencyValues.hours:
            case syncFrequencyValues.days:
            case syncFrequencyValues.months:
                formattedDate = dayjs(convertDate).format('DD MMM');
                break;
        }

        return formattedDate;
    }

    function getAmountToSlice(syncFrequency: syncFrequencyValues): number {
        let amountToSlice = 7;

        switch (syncFrequency) {
            case syncFrequencyValues.days:
                amountToSlice = 4;
                break;
            case syncFrequencyValues.months:
                amountToSlice = 2;
                break;
        }

        return amountToSlice;
    }

    function getAPIsList(apis: externalAPIValues[]): string {
        const list = apis.map(api => externalAPINames[api]);
        return list.join(', ');
    }
};

const validHealthFrequencies: number[] = [
    syncFrequencyValues.hours,
    syncFrequencyValues.days,
    syncFrequencyValues.months,
];

interface ReturnProps {
    validHealthFrequencies: number[];
    formatNextRunDate: (date: string) => string;
    getOrderedHealth: (syncHealthByDate: SyncHealthByDate) => OrderedHealthReturnProps[];
    getMostRecentHealth: (syncHealthByDate: SyncHealthByDate) => syncHealthValues;
    getDateNameFormat: (date: string, syncFrequency: syncFrequencyValues) => string;
    getAmountToSlice: (syncFrequency: syncFrequencyValues) => number;
    getAPIsList: (apis: externalAPIValues[]) => string;
}

type SyncHealthByDate = Record<string, number>;

interface OrderedHealthReturnProps {
    date: string;
    health: number;
}

export default useDashboardSyncDetailsUtils;
