import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { AdminUser, AdminUserPost } from '@customTypes/shared/AdminUser';

export const createAdminUserRequest = createAction('createAdminUserRequest');
export const createAdminUserSuccess = createAction<AdminUserPost>('createAdminUserSuccess');
export const createAdminUserFailure = createAction('createAdminUserFailure');

export const createAdminUser = (postbody: AdminUserPost) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(createAdminUserRequest());
    try {
        const { data }: CreateUserResponse = await api.post(`admins/create`, postbody);

        dispatch(createAdminUserSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, createAdminUserFailure, e);
    }
};

interface CreateUserResponse {
    data: AdminUser;
}
