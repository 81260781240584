import Form from '@components/form/Form';
import FormRow from '@components/form/FormRow';
import TextInput from '@components/form/TextInput';
import Modal from '@components/modal/Modal';
import React from 'react';
import { AdminUser } from '@customTypes/shared/AdminUser';

import useUpdateAdmin from './hooks/useUpdateAdmin';

const UpdateAdminModal: React.FC<UpdateAdminProps> = ({ user }) => {
    const {
        handleSubmit,
        handleChange,
        closeModal,
        isPosting,
        // error,
        formState: { email, firstName, lastName },
    } = useUpdateAdmin(user);

    return (
        <Modal title="Update admin" size="small" closeModal={closeModal}>
            <Form
                submitButtonColor="yellow"
                onCancel={closeModal}
                onSubmit={handleSubmit}
                isPosting={isPosting}
            >
                <FormRow>
                    <TextInput
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        label="Email address"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="firstName"
                        value={firstName}
                        onChange={handleChange}
                        label="First name"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="lastName"
                        value={lastName}
                        onChange={handleChange}
                        label="Last Name"
                        required
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

interface UpdateAdminProps {
    user: AdminUser;
}

export default UpdateAdminModal;
