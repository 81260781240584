import { DropdownOption } from '@customTypes/shared/DropdownOption';

export function isEmpty(item: any): boolean {
    if (Array.isArray(item)) return !item.length;
    if (typeof item === 'string') return !item.trim().length;
    if (item instanceof Date) return isNaN(item.valueOf());
    if (typeof item === 'object') return isObjEmpty(item);
    if (typeof item === 'number') return false;

    return !item;
}

function isObjEmpty(obj: Record<string, unknown>): boolean {
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) return false;
    }
    return true;
}

export function convertArrToObj<T>(arr: T[], field = 'id'): Record<string, T> {
    return arr.reduce<Record<string, T>>((acc, item) => {
        // todo
        //@ts-ignore
        acc[item[field]] = item;
        return acc;
    }, {});
}

export function splitOnUpperCase(initString: string): string {
    if (!initString) return '';
    return initString.match(/[A-Z][a-z]+/g)?.join(' ') || '';
}

export const calcAngle = (startX: number, startY: number, endX: number, endY: number) => {
    const dx = endX - startX;
    const dy = endY - startY;
    let theta = Math.atan2(dy, dx); // range (-PI, PI]
    theta *= 180 / Math.PI; // rads to degs, range (-180, 180]
    if (theta < 0) theta = 360 + theta; // range [0, 360)
    return theta;
};

export const rotate = (cx: number, cy: number, x: number, y: number, angle: number) => {
    const radians = (Math.PI / 180) * (angle * -1);
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    const nx = cos * (x - cx) + sin * (y - cy) + cx;
    const ny = cos * (y - cy) - sin * (x - cx) + cy;
    return [nx, ny];
};

export function convertUKDateToDateFormat(date: string): Date {
    const formattedDate = date.split('/');
    return new Date(+formattedDate[2], +formattedDate[1] - 1, +formattedDate[0]);
}

export function formatTimeFromSeconds(seconds: number): string {
    const hours = seconds / 3600;
    const minutes = (seconds % 3600) / 60;
    const hoursFixed = +hours.toFixed();
    const minutesFixed = +minutes.toFixed();
    if (!hoursFixed && !minutesFixed) return `${seconds} seconds`;
    return `${hours.toFixed()}hrs ${minutes.toFixed()}mins`;
}

export function convertEnumToSelectOptions(
    enums: Record<string, string>,
): DropdownOption<number>[] {
    const keys = Object.keys(enums);
    const formatted = keys.map(key => {
        return {
            label: enums[key],
            value: +key,
        };
    });

    return formatted;
}
