import React, { useState } from 'react';
import { Node } from '@customTypes/shared/Flow';
import { syncFieldNames, syncFieldValues, nodeTitleNames } from '@constants/enums/syncEnums';
import Tooltip from '@components/modal/Tooltip';
import dayjs from 'dayjs';
import SingleFlowNodeField from './SingleFlowNodeField';

const SingleFlowNode: React.FC<Props> = ({ node }) => {
    const { fields, title, isTransformSuccess, transformationErrorMessage } = node;
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipStyles = {
        bottom: '20px',
        right: '45px',
    };
    return (
        <div className="flow-node">
            {fields.map(({ fieldName, stringValue }, i) => {
                let valueToShow = stringValue;
                if (
                    fieldName === syncFieldValues.StartDate ||
                    fieldName === syncFieldValues.EndDate ||
                    fieldName === syncFieldValues.LastSynced
                ) {
                    const dateValue = dayjs(stringValue);
                    if (dateValue.isValid()) valueToShow = dateValue.format('DD/MM/YYYY hh:mm');
                }

                return (
                    <SingleFlowNodeField
                        key={i}
                        fieldName={syncFieldNames[fieldName] || '-- '}
                        valueToShow={valueToShow || '--'}
                    />
                );
            })}
            {/* If fields array is empty, display No Data message */}
            {!fields.length && (
                <div className="flow-node-field flex-row justify-between">
                    <span className="field-value">[No data]</span>
                </div>
            )}
            <div className="flow-node-title">{nodeTitleNames[title]}</div>
            <i
                onMouseEnter={() => !isTransformSuccess && setShowTooltip(true)}
                onMouseLeave={() => !isTransformSuccess && setShowTooltip(false)}
                className={`flow-node-icon icon far fa-fw fa-${
                    isTransformSuccess ? 'check' : 'times'
                }`}
            >
                <Tooltip
                    visible={showTooltip}
                    text={transformationErrorMessage || ''}
                    customStyles={tooltipStyles}
                    flipVertical
                    title="An error occurred while transforming the data"
                />
            </i>
        </div>
    );
};

interface Props {
    node: Node;
}
export default SingleFlowNode;
