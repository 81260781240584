import ActionButton from '@components/button/ActionButton';
import ButtonRow from '@components/button/ButtonRow';
import Description from '@components/typography/Description';
import Modal from './Modal';

const ErrorModal: React.FC<ErrorModalProps> = ({
    closeModal,
    title = 'Error',
    description = 'There was an error. Please try again.',
}) => {
    return (
        <Modal closeModal={closeModal} title={title} size="small">
            <Description>{description}</Description>
            <ButtonRow alignment="right">
                <ActionButton color="grey" onClick={closeModal}>
                    Close
                </ActionButton>
            </ButtonRow>
        </Modal>
    );
};
interface ErrorModalProps {
    closeModal: () => void;
    title?: string;
    description?: string;
}

export default ErrorModal;
