import React from 'react';

import { DotMailerContact } from '@customTypes/shared/DotMailerContact';
import { contactStatusNames } from '@constants/enums/contactEnums';

import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import Helmet from '@components/generic/helmet/Helmet';

const DotMailerContactSingleDetails: React.FC<ContactProps> = ({
    contact: {
        firstName,
        lastName,
        dotmailerID,
        workbooksID,
        email,
        companyName,
        newsletterSubscriptions,
        status,
    },
}) => (
    <ContentBlock>
        <Helmet title={`${firstName ? firstName : ''} ${lastName ? lastName : ''}`} />
        <ContentRow>
            <ContentItem label="Name">
                <p>
                    {!firstName && !lastName
                        ? '--'
                        : `${firstName ? firstName : ''} ${lastName ? lastName : ''}`}
                </p>
            </ContentItem>
            <ContentItem label="Dotmailer ID">
                <p>{!dotmailerID ? '--' : dotmailerID}</p>
            </ContentItem>
            <ContentItem label="Workbooks ID">
                <p>{!workbooksID ? '--' : workbooksID}</p>
            </ContentItem>
        </ContentRow>

        <ContentRow>
            <ContentItem label="Email address">
                <p>{!email ? '--' : email}</p>
            </ContentItem>
            <ContentItem label="Company">
                <p>{!companyName ? '--' : companyName}</p>
            </ContentItem>
            <ContentItem label="Status">
                <p>{contactStatusNames[status]}</p>
            </ContentItem>
        </ContentRow>

        <ContentRow>
            <ContentItem label="Subscriptions">
                <p>{!newsletterSubscriptions ? '--' : newsletterSubscriptions}</p>
            </ContentItem>
        </ContentRow>
    </ContentBlock>
);

interface ContactProps {
    contact: DotMailerContact;
}

export default DotMailerContactSingleDetails;
