import React from 'react';
import dayjs from 'dayjs';
import Table from '@components/table/Table';
import StatusBadge from '@components/table/StatusBadge';

import { LogModel } from '@customTypes/shared/LeadOrganisation';
import { Column } from '@customTypes/table';
import ButtonRow from '@components/button/ButtonRow';
import { logTypes } from '@constants/enums/companyEnums';

const LeadOrganisationsLogTable: React.FC<LogTableProps> = ({ logs, isFetching }) => {
    return (
        <Table
            columns={columns}
            rows={Object.values(logs)}
            isLoading={isFetching}
            defaultSortKey={2}
            defaultSortAsc={false}
            excludePagination
        />
    );
};

interface LogTableProps {
    logs: Record<number, LogModel>;
    isFetching: boolean;
}

const columns: Column<LogModel>[] = [
    {
        key: 1,
        heading: 'Description',
        getValue: row => row.body,
    },
    {
        key: 2,
        heading: 'Date of sync',
        getValue: row => `${dayjs(row.createdOn).format('DD/MM/YYYY')}`,
    },
    {
        key: 3,
        heading: '',
        getValue: ({ type }) => (
            <ButtonRow alignment="right">
                <StatusBadge
                    color={logTypes[type].color}
                    icon={logTypes[type].icon}
                >{`${logTypes[type].text}`}</StatusBadge>
            </ButtonRow>
        ),
    },
];

export default LeadOrganisationsLogTable;
