import DotMailerContactsTable from './DotMailerContactsTable';
import Description from '@components/typography/Description';
import Helmet from '@components/generic/helmet/Helmet';
import PageTitle from '@components/typography/PageTitle';

const DotMailerContacts: React.FC = () => (
    <div className="contacts-wrapper">
        <Helmet title="DotDigital" />
        <PageTitle>DotDigital</PageTitle>
        <Description>
            Here is a list of all the contacts currently in the system, use the search below to find
            a specific contact.
        </Description>
        <DotMailerContactsTable />
    </div>
);

export default DotMailerContacts;
