import { Link } from 'react-router-dom';

const LinkButton: React.FC<LinkButtonProps> = ({ color = 'green', href = '', icon, children }) => (
    <Link to={href} className={`button ${color}`}>
        {icon && <i className={`icon far fa-fw fa-${icon}`}></i>}
        <span className="text">{children}</span>
    </Link>
);

interface LinkButtonProps {
    color?: string;
    href?: string;
    icon?: string;
}

export default LinkButton;
