import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

export const postPendingLeadEntryResolveRequest = createAction(
    'postPendingLeadEntryResolveRequest',
);
export const postPendingLeadEntryResolveSuccess = createAction<number>(
    'postPendingLeadEntryResolveSuccess',
);
export const postPendingLeadEntryResolveFailure = createAction(
    'postPendingLeadEntryResolveFailure',
);

export const postPendingLeadEntryResolve = (id: number) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(postPendingLeadEntryResolveRequest());
    try {
        await api.post(`leadOrganisations/entry/${id}/resolve`, {});
        dispatch(postPendingLeadEntryResolveSuccess(id));
    } catch (e) {
        handleApiErrors(dispatch, postPendingLeadEntryResolveFailure, e);
    }
};
