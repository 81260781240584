import axios, { AxiosResponse } from 'axios';
import { Action, ActionCreator, Dispatch } from 'redux';
import { setFieldErrors } from '../redux/actions/fieldErrors';
import { addFormError } from '../redux/actions/fieldErrors';
import { setRedirectUrl } from '../redux/actions/redirects';
import { APIError } from '../types/APIError';
import { HTTPStatusCode } from '@constants/enums/statusCodes';

const { BadRequest, TooManyRequests, Unauthorized } = HTTPStatusCode;

const getConfig = () => ({
    headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
});

let API_URL = '';
export const initApi = (apiUrl: string) => {
    API_URL = apiUrl;
};
const get = <TResponseBody>(url: string): Promise<AxiosResponse<TResponseBody>> => {
    return axios.get<TResponseBody>(`${API_URL}/${url}`, getConfig());
};
const post = <TRequestBody, TResponseBody>(url: string, data: TRequestBody) => {
    return axios.post<TResponseBody>(`${API_URL}/${url}`, data, getConfig());
};
const put = <TRequestBody, TResponseBody>(url: string, data: TRequestBody) => {
    return axios.put<TResponseBody>(`${API_URL}/${url}`, data, getConfig());
};
const patch = <TRequestBody, TResponseBody>(url: string, data: TRequestBody) => {
    return axios.patch<TResponseBody>(`${API_URL}/${url}`, data, getConfig());
};
const del = <TResponseBody>(url: string) => {
    return axios.delete<TResponseBody>(`${API_URL}/${url}`, getConfig());
};
export const api = {
    get,
    post,
    put,
    patch,
    delete: del,
};

export const handleApiErrors = <A extends Action>(
    dispatch: Dispatch,
    failureAction: ActionCreator<A>,
    err: APIError,
) => {
    const { response, message } = err;

    if (response && response.status === BadRequest) {
        typeof response.data === 'string'
            ? dispatch(addFormError(response.data))
            : dispatch(setFieldErrors(response.data.errors));

        return dispatch(failureAction(null));
    }
    if (response && response.status === Unauthorized) {
        dispatch(failureAction('Unauthorized'));
        localStorage.removeItem('token');
        return dispatch(setRedirectUrl('/auth/login'));
    }
    if (response && response.status === TooManyRequests) {
        return dispatch(failureAction('Rate limit exceeded. Please try again later.'));
    }

    return dispatch(failureAction(message));
};
