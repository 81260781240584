import Tooltip from '@components/modal/Tooltip';
import { syncStatusValues } from '@constants/enums/syncEnums';
import { useState } from 'react';

const StatusBadge: React.FC<StatusBadgeProps> = ({
    status,
    error,
    children,
    color = 'green',
    icon,
}) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const showError = status === syncStatusValues.errored && !!error;
    let displayError = error;
    if (error?.length && error.length > 203) {
        displayError = error.substring(0, 200) + '...';
    }
    return (
        <span
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
            className={`status-badge static ${color}`}
        >
            <Tooltip title="Error" visible={showTooltip && showError} text={displayError ?? ''} />
            {icon && <i className={`icon far fa-fw fa-${icon}`}></i>}
            <span className="text">{children}</span>
        </span>
    );
};

interface StatusBadgeProps {
    color?: string;
    icon?: string;
    status?: number | null;
    error?: string | null;
}

export default StatusBadge;
