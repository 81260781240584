import React, { useState, useRef } from 'react';
import Tooltip from '@components/modal/Tooltip';

const SingleFlowNodeField: React.FC<FieldProps> = ({ fieldName = '--', valueToShow = '--' }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [copiedData, setCopiedData] = useState('');
    const valueRef = useRef(document.createElement('span'));

    const handleMouseEnter = () => {
        setShowTooltip(true);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    const handleCopyContents = () => {
        try {
            const el = document.createElement('textarea');
            el.value = valueRef.current.innerText;
            document.body.appendChild(el);
            el.select();
            el.setSelectionRange(0, 99999);
            document.execCommand('copy');
            document.body.removeChild(el);
            setCopiedData(valueRef.current.innerText);
            setTimeout(() => {
                setCopiedData('');
            }, 3000);
        } catch (e) {
            console.warn(e);
        }
    };

    const tooltipStyles = {
        bottom: '20px',
        right: '100px',
    };
    return (
        <div
            className="flow-node-field flex-row justify-between"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleCopyContents}
        >
            <span className="field-name">{fieldName}:</span>
            <span ref={valueRef} className="field-value">
                {valueToShow}
            </span>
            <Tooltip
                visible={showTooltip}
                text={valueToShow}
                customStyles={tooltipStyles}
                flipVertical
                title={copiedData === valueToShow ? '[Copied]' : '[Click to copy...]'}
            />
        </div>
    );
};

interface FieldProps {
    fieldName: string;
    valueToShow: string;
}

export default SingleFlowNodeField;
