export enum externalAPIValues {
    workbooks = 1,
    immerse = 2,
    cvent = 3,
    beauhurst = 4,
    companiesHouse = 5,
    dotDigital = 6,
    local = 7,
}

export const externalAPINames: Record<number, string> = {
    [externalAPIValues.workbooks]: 'Workbooks',
    [externalAPIValues.immerse]: 'Immerse',
    [externalAPIValues.cvent]: 'Cvent',
    [externalAPIValues.beauhurst]: 'Beauhurst',
    [externalAPIValues.companiesHouse]: 'Companies House',
    [externalAPIValues.dotDigital]: 'DotDigital',
    [externalAPIValues.local]: 'Local',
};
