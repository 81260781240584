import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { SyncPeriodicRate } from '@customTypes/shared/Sync';
import { periodicSummaryTypeValues } from '@constants/enums/syncEnums';

export const fetchSyncsPeriodicRatesRequest = createAction('fetchSyncsPeriodicRatesRequest');
export const fetchSyncsPeriodicRatesSuccess = createAction<SyncPeriodicRate>(
    'fetchSyncsPeriodicRatesSuccess',
);
export const fetchSyncsPeriodicRatesFailure = createAction('fetchSyncsPeriodicRatesFailure');

export const fetchSyncsPeriodicRates = (
    externalAPI: number,
    type: periodicSummaryTypeValues,
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchSyncsPeriodicRatesRequest());
    try {
        const { data }: Response = await api.get(`syncs/${externalAPI}/summary?type=${type}`);
        dispatch(fetchSyncsPeriodicRatesSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSyncsPeriodicRatesFailure, e);
    }
};

interface Response {
    data: SyncPeriodicRate;
}
