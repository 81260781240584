import CompaniesTable from './CompaniesTable';
import Description from '@components/typography/Description';
import Helmet from '@components/generic/helmet/Helmet';
import PageTitle from '@components/typography/PageTitle';

const Companies: React.FC = () => (
    <div className="contacts-wrapper">
        <Helmet title="Organisation Records" />
        <PageTitle>Organisation Records</PageTitle>
        <Description>
            Here is a list of all the organisation records currently in the system, use the search
            below to find a specific company.
        </Description>
        <CompaniesTable />
    </div>
);

export default Companies;
