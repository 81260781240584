import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { updateSearchTerm } from '@actions/searchTerms';

import { SearchTerm } from '@customTypes/shared/SearchTerm';

const initialState: Record<string, string> = {};
interface SearchTermsState {
    [key: string]: string;
}

export default createReducer(initialState, {
    [updateSearchTerm.type]: handleUpdateSearchTerm,
});

function handleUpdateSearchTerm(state: SearchTermsState, action: PayloadAction<SearchTerm>) {
    state[action.payload.type] = action.payload.searchTerm;
}
