import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { Sync } from '@customTypes/shared/Sync';

export const fetchSingleSyncRequest = createAction('fetchSingleSyncRequest');
export const fetchSingleSyncSuccess = createAction<Sync>('fetchSingleSyncSuccess');
export const fetchSingleSyncFailure = createAction('fetchSingleSyncFailure');

export const fetchSingleSync = (id: number) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchSingleSyncRequest());
    try {
        const { data }: Response = await api.get(`Syncs/${id}`);
        dispatch(fetchSingleSyncSuccess({ ...data, id: data.sync.id }));
    } catch (e) {
        handleApiErrors(dispatch, fetchSingleSyncFailure, e);
    }
};

interface Response {
    data: Sync;
}
