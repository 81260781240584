import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { RootState } from '@reducers/index';

import {
    getImmerseUKContact,
    getImmerseUKContactConflicts,
    getImmerseUKContactLogs,
    getImmerseUKContactsFetchError,
    getImmerseUKContactsIsFetching,
    getImmerseUKContactsSyncError,
} from '@selectors/immerseUKContacts';

import {
    conflictManagementImmerseUKSingleBreadcrumb,
    immerseUKSingleBreadcrumb,
} from '@constants/breadcrumbItems';
import { isEmpty } from '@utils/generic';

import useSingleImmerseUKContactFetch from './_hooks/useSingleImmerseUKContactFetch';
import usePrevious from '@hooks/usePrevious';

import Description from '@components/typography/Description';
import Subtitle from '@components/typography/Subtitle';
import ImmerseUKContactSingleDetails from './ImmerseUKContactSingleDetails';
import DataCheck from '@components/generic/dataCheck/DataCheck';
import ImmerseUKContactSingleLogsTable from './ImmerseUKContactSingleLogsTable';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import ImmerseUKContactSingleSync from './ImmerseUKContactSingleSync';
import ImmerseUKContactSingleConflictsTable from './ImmerseUKContactSingleConflictsTable';
import ErrorModal from '@components/modal/ErrorModal';
import Breadcrumb from '@components/breadcrumb/Breadcrumb';
import PageTitle from '@components/typography/PageTitle';

const ImmerseUKContactSingle: React.FC = () => {
    const { id } = useParams<ParamsType>();
    const { pathname } = useLocation();
    const [showError, setShowError] = useState(false);
    useSingleImmerseUKContactFetch(id);
    const contact = useSelector((state: RootState) => getImmerseUKContact(state, +id));
    const conflicts = useSelector(getImmerseUKContactConflicts);
    const logs = useSelector(getImmerseUKContactLogs);
    const isFetching = useSelector(getImmerseUKContactsIsFetching);
    const error = useSelector(getImmerseUKContactsFetchError);
    const syncError = useSelector(getImmerseUKContactsSyncError);
    const prevProps = usePrevious({ syncError });

    useEffect(() => {
        if (syncError && !prevProps.syncError) {
            setShowError(true);
        }
    }, [syncError, prevProps.syncError]);

    return (
        <div className="contacts-wrapper">
            <Breadcrumb
                items={
                    pathname.includes('conflict-management')
                        ? conflictManagementImmerseUKSingleBreadcrumb
                        : immerseUKSingleBreadcrumb
                }
            />

            <CreateHeader>
                <PageTitle>Immerse UK contact details</PageTitle>
                {!isEmpty(contact) && <ImmerseUKContactSingleSync />}
            </CreateHeader>
            <Description>View all the details of this Immerse UK contact.</Description>
            <DataCheck
                isEmpty={isEmpty(contact)}
                isFetching={isFetching}
                error={error}
                customWrapperClass="content-block"
            >
                <div className="single-section">
                    <ImmerseUKContactSingleDetails contact={contact} />
                </div>

                {!isEmpty(conflicts) && (
                    <div className="single-section">
                        <Subtitle>Conflicts</Subtitle>
                        <ImmerseUKContactSingleConflictsTable
                            conflicts={conflicts}
                            isFetching={isFetching}
                        />
                    </div>
                )}

                <div className="single-section">
                    <Subtitle>Activity Logs</Subtitle>
                    <ImmerseUKContactSingleLogsTable logs={logs} isFetching={isFetching} />
                </div>
            </DataCheck>

            {showError && (
                <ErrorModal
                    description={syncError || undefined}
                    closeModal={() => setShowError(false)}
                />
            )}
        </div>
    );
};

type ParamsType = { id: string };

export default ImmerseUKContactSingle;
