import FormRow from '@components/form/FormRow';
import { useDispatch, useSelector } from 'react-redux';

import ActionButton from '@components/button/ActionButton';
import ButtonRow from '@components/button/ButtonRow';
import MiniSelect from '@components/form/MiniSelect';
import TextInput from '@components/form/TextInput';
import useForm from '@hooks/useForm';
import { fetchSyncsSearch, SearchFormData } from '@actions/syncs';
import { dataSearchOptions, syncTypeOptions, isSuccessOptions } from '@constants/enums/syncEnums';
import { getSyncsIsFetching } from '@selectors/syncs';
import { getFormError } from '@selectors/fieldErrors';
import { useCallback, useEffect } from 'react';
import { clearFormError } from '@actions/fieldErrors';
import Radio from '@components/form/Radio';

const DataSearchBox: React.FC = () => {
    const dispatch = useDispatch();
    const isFetching = useSelector(getSyncsIsFetching);
    const formError = useSelector(getFormError);

    const [formData, handleChange] = useForm<SearchFormData>({
        searchTerm: '',
        nodeTitle: null,
        sync: null,
        isSuccess: 'true',
    });

    const clearError = useCallback(() => {
        dispatch(clearFormError());
    }, [dispatch]);

    useEffect(() => {
        return () => clearError();
    }, [dispatch, clearError]);

    return (
        <div className="table-search no-flex content-block">
            <form className="data-search-form flex-row" onSubmit={_handleSubmit}>
                <FormRow>
                    <MiniSelect
                        name="nodeTitle"
                        options={dataSearchOptions}
                        value={formData.nodeTitle ?? null}
                        onChange={handleChange}
                    />
                    <MiniSelect
                        name="sync"
                        options={syncTypeOptions}
                        value={formData.sync ?? null}
                        onChange={handleChange}
                    />
                </FormRow>
                <FormRow>
                    <Radio
                        name="isSuccess"
                        value={formData.isSuccess ?? null}
                        options={isSuccessOptions}
                        onChange={handleChange}
                    />
                    <TextInput
                        name="searchTerm"
                        value={formData.searchTerm ?? ''}
                        onChange={handleChange}
                        placeholder="Search..."
                    />

                    <ButtonRow alignment="right">
                        <ActionButton isPosting={isFetching}>Find changes</ActionButton>
                    </ButtonRow>
                </FormRow>
            </form>

            {!!formError && (
                <p className="form-generic-error" style={{ marginTop: '10px' }}>
                    {formError}
                </p>
            )}
        </div>
    );

    function _handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        clearError();
        dispatch(fetchSyncsSearch(formData));
    }
};

export default DataSearchBox;
