import Description from '@components/typography/Description';
import CventContactsTable from './CventContactsTable';
import Helmet from '@components/generic/helmet/Helmet';
import PageTitle from '@components/typography/PageTitle';

const CventContacts: React.FC = () => (
    <div className="contacts-wrapper">
        <Helmet title="Cvent Contacts" />
        <PageTitle>Cvent Contacts</PageTitle>
        <Description>
            Here is a list of all the Cvent contacts currently in the system, use the search below
            to find a specific contact.
        </Description>
        <CventContactsTable />
    </div>
);

export default CventContacts;
