import { useDispatch, useSelector } from 'react-redux';

import { syncDotMailerContact } from '@actions/dotMailerContacts';
import { getDotMailerContactsIsSyncing } from '@selectors/dotMailerContacts';
import { StringNumberType } from '@customTypes/shared/Generics';

const useSingleDotMailerContactSync = (id: StringNumberType): ReturnProps => {
    const dispatch = useDispatch();
    const isSyncing = useSelector(getDotMailerContactsIsSyncing);

    const handleSync = () => {
        dispatch(syncDotMailerContact(id));
    };

    return { isSyncing, handleSync };
};

type HandleSync = () => void;

interface ReturnProps {
    isSyncing: boolean;
    handleSync: HandleSync;
}

export default useSingleDotMailerContactSync;
