import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Description from '@components/typography/Description';

import { Company } from '@customTypes/shared/Company';
import { Column } from '@customTypes/table';

import { fetchPendingCompanies } from '@actions/companies';
import {
    getPendingCompanies,
    getCompaniesCurrentPage,
    getCompaniesIsFetching,
    getCompaniesPageSize,
    getCompaniesTotalCount,
    getCompaniesTotalPages,
} from '@selectors/companies';

import LinkButton from '@components/button/LinkButton';
import TableWithFetch from '@components/tableWithFetch/TableWithFetch';

import ButtonRow from '@components/button/ButtonRow';

const ConflictManagementCompaniesTable: React.FC = () => {
    const dispatch = useDispatch();
    const isFetching = useSelector(getCompaniesIsFetching);
    const companies = useSelector(getPendingCompanies);
    const currentPage = useSelector(getCompaniesCurrentPage);
    const pageSize = useSelector(getCompaniesPageSize);
    const totalPages = useSelector(getCompaniesTotalPages);
    const totalCount = useSelector(getCompaniesTotalCount);

    const fetchData = useCallback(
        (page: number, pageSize: number, searchTerm: string) => {
            dispatch(fetchPendingCompanies(page, pageSize, searchTerm));
        },
        [dispatch],
    );

    return (
        <>
            <Description>Here is a list of all the pending companies.</Description>
            <TableWithFetch
                columns={columns}
                rows={Object.values(companies)}
                isLoading={isFetching}
                fetchData={fetchData}
                currentPage={currentPage}
                pageSize={pageSize}
                totalPages={totalPages}
                totalCount={totalCount}
                searchType="cmCompanies"
            />
        </>
    );
};

const columns: Column<Company>[] = [
    {
        key: 1,
        heading: 'Name',
        getValue: row => `${row.name ? row.name : ''}`,
        searchable: true,
    },
    {
        key: 2,
        heading: 'Workbooks ID',
        getValue: row => `${row.workbooksID ? row.workbooksID : ''}`,
        searchable: true,
    },
    {
        key: 3,
        heading: 'Company ID',
        getValue: row => (row.companyNumber ? row.companyNumber : ''),
        searchable: true,
    },
    {
        key: 4,
        heading: '',
        getValue: row => (
            <ButtonRow alignment="right">
                <LinkButton href={`/conflict-management/companies/${row.id}`}>
                    View details
                </LinkButton>
            </ButtonRow>
        ),
        searchable: false,
    },
];

export default ConflictManagementCompaniesTable;
