import React, { useState } from 'react';

import { PendingChLead } from '@customTypes/shared/LeadOrganisation';
import { Column } from '@customTypes/table';

import usePendingChLeadResolve from './hooks/usePendingChLeadResolve';

import Table from '@components/table/Table';
import ActionButton from '@components/button/ActionButton';
import ButtonRow from '@components/button/ButtonRow';

import LeadOrganisationPendingUpdateModal from './LeadOrganisationPendingUpdateModal';
import LeadingOrganisationPendingIgnoreModal from './LeadOrganisationPendingIgnoreModal';

const LeadOrganisationsPendingChLeadTable: React.FC<LeadTableProps> = ({ entries }) => {
    const { resolvePendingChLead, deletePendingChLead } = usePendingChLeadResolve();

    const columns: Column<PendingChLead>[] = [
        {
            key: 1,
            heading: 'Match Name',
            getValue: ({ companyName }) => companyName,
        },
        {
            key: 2,
            heading: 'Will be updated:',
            getValue: ({ companyNumber }) => (
                <div>
                    <strong>Company Number: </strong> {`${companyNumber || '--'}`}
                </div>
            ),
        },
        {
            key: 3,
            heading: '',
            getValue: row => {
                return (
                    <RenderConflictButtons
                        chooseConflicting={resolvePendingChLead}
                        ignore={deletePendingChLead}
                        id={row.id}
                    />
                );
            },
        },
    ];

    return <Table columns={columns} rows={Object.values(entries)} excludePagination />;
};

interface LeadTableProps {
    entries: Record<number, PendingChLead>;
}

interface ConflictButtonsProps {
    chooseConflicting: (id: number) => void;
    ignore: (id: number) => void;
    id: number;
}

const RenderConflictButtons: React.FC<ConflictButtonsProps> = ({
    chooseConflicting = () => {},
    ignore = () => {},
    id,
}) => {
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showIgnoreModal, setShowIgnoreModal] = useState(false);
    return (
        <>
            <ButtonRow alignment="right">
                <ActionButton onClick={() => setShowUpdateModal(true)} icon="check">
                    Update to Workbooks
                </ActionButton>
                <span style={{ width: '5px' }}></span>
                <ActionButton onClick={() => setShowIgnoreModal(true)} color="red" icon="times">
                    Ignore
                </ActionButton>
            </ButtonRow>

            <LeadOrganisationPendingUpdateModal
                visible={showUpdateModal}
                handleClose={() => setShowUpdateModal(false)}
                handleSubmit={() => chooseConflicting(id)}
            />

            <LeadingOrganisationPendingIgnoreModal
                visible={showIgnoreModal}
                handleClose={() => setShowIgnoreModal(false)}
                handleSubmit={() => ignore(id)}
            />
        </>
    );
};

export default LeadOrganisationsPendingChLeadTable;
