import React, { useState } from 'react';

import {
    syncRateFrequencyValues,
    syncRateFrequencyNonPluralNames,
} from '@constants/enums/syncEnums';
import { SyncRate } from '@customTypes/shared/Sync';
import Table from '@components/table/Table';
import { Column } from '@customTypes/table';
import { externalAPINames } from '@constants/enums/apiEnums';
import Tooltip from '@components/modal/Tooltip';

const DashboardRateLimitsTable: React.FC<RateLimitsTableProps> = ({
    rates,
    selected,
    setSelected,
}) => (
    <Table
        columns={columns}
        rows={rates}
        excludePagination
        selectedIndex={selected}
        setSelectedIndex={setSelected}
    />
);

const LimitDisplay: React.FC<LimitDisplayProps> = ({
    limit,
    isLimitCalculated,
    frequency,
    frequencyValue,
    callsPer,
}) => {
    const [showTooltip, setShowTooltip] = useState(false);

    return (
        <>
            {limit}
            {isLimitCalculated && (
                <i
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                    className="tooltip far fa-fw fa-info-circle"
                >
                    <Tooltip
                        visible={showTooltip}
                        title="Limit calculated"
                        text={`This is an hourly limit calculation, based on a limit of ${callsPer} calls per ${frequencyValue} ${syncRateFrequencyNonPluralNames[
                            frequency
                        ].toLowerCase()}(s).`}
                        customStyles={tooltipStyles}
                        flipVertical
                    />
                </i>
            )}
        </>
    );
};

const tooltipStyles = {
    bottom: '20px',
    right: '8px',
};

const columns: Column<SyncRateExtension>[] = [
    {
        key: 1,
        heading: 'API',
        getValue: row => externalAPINames[row.api],
    },
    {
        key: 2,
        heading: 'Hourly limit',
        getValue: row => {
            if (!row.hourlyLimit) return 'N/A';
            return (
                <LimitDisplay
                    limit={row.hourlyLimit}
                    isLimitCalculated={row.isHourlyLimitCalculated}
                    frequency={row.frequency}
                    frequencyValue={row.frequencyValue}
                    callsPer={row.callsPer}
                />
            );
        },
    },
    {
        key: 3,
        heading: 'Calls this hour',
        getValue: row => row.hourlyRequests,
    },
];

interface SyncRateExtension extends SyncRate {
    id?: number;
}

interface RateLimitsTableProps {
    rates: SyncRate[];
    selected: number;
    setSelected: (index: number) => void;
}

interface LimitDisplayProps {
    limit: number;
    isLimitCalculated: boolean;
    frequency: syncRateFrequencyValues;
    frequencyValue: number;
    callsPer: number | null;
}

export default DashboardRateLimitsTable;
