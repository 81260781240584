import jwtDecode from 'jwt-decode';

interface JWT {
    nbf: number;
    exp: number;
}

export function validateJWT(token: string): boolean {
    try {
        if (!token) return false;

        const now = new Date().valueOf() / 1000;
        const { exp: expiry } = jwtDecode<JWT>(token);
        const isValid = expiry > now;
        return isValid;
    } catch {
        return false;
    }
}

export const getJwtToken = (): string | null => {
    const token = localStorage.getItem('token');
    return token;
};

export const clearJwtToken = (): void => {
    localStorage.removeItem('token');
};

export const getDecodedJwtToken = (): JWT | null => {
    const token = getJwtToken();
    if (token === null) return null;
    const isValid = validateJWT(token);
    if (!isValid) {
        clearJwtToken();
        return null;
    }

    const decoded = jwtDecode<JWT>(token);
    return decoded;
};
