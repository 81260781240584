import React from 'react';

const DataCheck: React.FC<DataCheckProps> = ({
    children,
    isEmpty,
    isFetching,
    error,
    noDataMessage = 'There is no data to show',
    errorMessage = 'There was an error fetching the information',
    customWrapperClass = '',
}) => {
    if (error) {
        return (
            <div className={`data-block ${customWrapperClass}`}>
                <p>{errorMessage}</p>
            </div>
        );
    }

    if (isFetching && isEmpty) {
        return (
            <div className={`data-block ${customWrapperClass}`}>
                <i className="fal fa-spinner fa-spin"></i>
            </div>
        );
    }

    if (isEmpty) {
        return (
            <div className={`data-block ${customWrapperClass}`}>
                <p>{noDataMessage}</p>
            </div>
        );
    } else return <>{children}</>;
};

interface DataCheckProps {
    isEmpty?: boolean;
    isFetching?: boolean;
    error?: string | null;
    noDataMessage?: string;
    errorMessage?: string;
    customWrapperClass?: string;
}

export default DataCheck;
