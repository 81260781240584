import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { CompaniesResponse } from '@customTypes/shared/Company';

export const fetchCompaniesRequest = createAction('fetchCompaniesRequest');
export const fetchCompaniesSuccess = createAction<CompaniesResponse>('fetchCompaniesSuccess');
export const fetchCompaniesFailure = createAction('fetchCompaniesFailure');

export const fetchCompanies = (page: number, pageSize: number, searchTerm: string) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(fetchCompaniesRequest());
    try {
        const { data }: Response = await api.get(
            `Companies?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}`,
        );
        dispatch(fetchCompaniesSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchCompaniesFailure, e);
    }
};

interface Response {
    data: CompaniesResponse;
}
