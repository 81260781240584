import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchLeadOrganisation } from '@actions/leadOrganisations/fetchLeadOrganisation';
import { StringNumberType } from '@customTypes/shared/Generics';

const useLeagueOrganisationFetch = (id: StringNumberType): void => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchLeadOrganisation(id));
    }, [dispatch, id]);
};

export default useLeagueOrganisationFetch;
