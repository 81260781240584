import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { UpdatePasswordPost } from '@customTypes/shared/AdminUser';

export const updateUserPasswordRequest = createAction('updateUserPasswordRequest');
export const updateUserPasswordSuccess = createAction('updateUserPasswordSuccess');
export const updateUserPasswordFailure = createAction('updateUserPasswordFailure');

export const updateUserPassword = (id: number, postbody: UpdatePasswordPost) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(updateUserPasswordRequest());

    try {
        await api.patch(`Users/${id}/updatePassword`, postbody);

        dispatch(updateUserPasswordSuccess());
    } catch (e) {
        handleApiErrors(dispatch, updateUserPasswordFailure, e);
    }
};
