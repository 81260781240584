import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { ContactUpdateConflictPostBody } from '@customTypes/shared/Contact';

export const updateImmerseUKConflictRequest = createAction('updateImmerseUKConflictRequest');
export const updateImmerseUKConflictSuccess = createAction<number>(
    'updateImmerseUKConflictSuccess',
);
export const updateImmerseUKConflictFailure = createAction('updateImmerseUKConflictFailure');

export const updateImmerseUKConflict = (postBody: ContactUpdateConflictPostBody) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(updateImmerseUKConflictRequest());
    try {
        await api.post('immerseContacts/resolve', postBody);
        dispatch(updateImmerseUKConflictSuccess(postBody.id));
    } catch (e) {
        handleApiErrors(dispatch, updateImmerseUKConflictFailure, e);
    }
};
