import React, { useState } from 'react';

import { CventContactConflict } from '@customTypes/shared/CventContact';
import { Column } from '@customTypes/table';

import { contactFieldOptionNames, contactProgramTypeValues } from '@constants/enums/contactEnums';

import Table from '@components/table/Table';
import ActionButton from '@components/button/ActionButton';
import CventContactUpdateConflictModal from './CventContactUpdateConflictModal';

const CventContactSingleConflictsTable: React.FC<TableProps> = ({ conflicts, isFetching }) => {
    return (
        <>
            <Table
                columns={columns}
                rows={[...Object.values(conflicts)].sort(
                    (a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime(),
                )}
                isLoading={isFetching}
                noDataMessage="There are no conflicts to display"
            />
        </>
    );
};

const RenderSelection: React.FC<RenderSelectionProps> = ({ id, programType, text, buttonText }) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <div className="table-selection">
                <p>{text}</p>
                <ActionButton color="blue" onClick={() => setShowModal(true)}>
                    {buttonText}
                </ActionButton>
            </div>

            <CventContactUpdateConflictModal
                visible={showModal}
                handleClose={() => setShowModal(false)}
                postBody={{ id, programType }}
            />
        </>
    );
};

const columns: Column<CventContactConflict>[] = [
    {
        key: 1,
        heading: 'Field',
        getValue: row => contactFieldOptionNames[row.field],
    },
    {
        key: 2,
        heading: 'Cvent Entry',
        getValue: row => (
            <RenderSelection
                id={row.id}
                programType={contactProgramTypeValues.cvent}
                text={row.localValue}
                buttonText="Use Cvent value"
            />
        ),
    },
    {
        key: 3,
        heading: 'Workbooks Entry',
        getValue: row => (
            <RenderSelection
                id={row.id}
                programType={contactProgramTypeValues.workBooks}
                text={row.wbValue}
                buttonText="Use Workbooks value"
            />
        ),
    },
];

interface RenderSelectionProps {
    id: number;
    programType: contactProgramTypeValues;
    text: string;
    buttonText: string;
}
interface TableProps {
    conflicts: Record<number, CventContactConflict>;
    isFetching: boolean;
}

export default CventContactSingleConflictsTable;
