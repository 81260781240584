import React from 'react';

import { CventContact } from '@customTypes/shared/CventContact';

import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import Helmet from '@components/generic/helmet/Helmet';

const CventContactSingleDetails: React.FC<ContactProps> = ({
    contact: {
        firstName,
        lastName,
        phone,
        twitter,
        userEmail,
        streetAddress,
        town,
        postcode,
        jobTitle,
        cventID,
    },
}) => (
    <ContentBlock>
        <Helmet title={`${firstName ? firstName : ''} ${lastName ? lastName : ''}`} />
        <ContentRow>
            <ContentItem label="Name">
                <p>{!firstName && !lastName ? '--' : firstName + ' ' + lastName}</p>
            </ContentItem>
            <ContentItem label="Phone">
                <p>{!phone ? '--' : phone}</p>
            </ContentItem>
            <ContentItem label="Twitter">
                <p>{!twitter ? '--' : twitter}</p>
            </ContentItem>
        </ContentRow>

        <ContentRow>
            <ContentItem label="Email address">
                <p>{!userEmail ? '--' : userEmail}</p>
            </ContentItem>
            <ContentItem label="Address">
                <p>
                    {!streetAddress && !town && !postcode ? (
                        '--'
                    ) : (
                        <>
                            {!streetAddress ? '' : streetAddress}
                            <br />
                            {!town ? '' : town}
                            <br />
                            {!postcode ? '' : postcode}
                        </>
                    )}
                </p>
            </ContentItem>
            <ContentItem label="Job title">
                <p>{!jobTitle ? '--' : jobTitle}</p>
            </ContentItem>
            <ContentItem label="Cvent ID">
                <p>{!cventID ? '--' : cventID}</p>
            </ContentItem>
        </ContentRow>
    </ContentBlock>
);

interface ContactProps {
    contact: CventContact;
}

export default CventContactSingleDetails;
