import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { UpdatePasswordPost } from '@customTypes/shared/AdminUser';

export const updateAdminUserPasswordRequest = createAction('updateAdminUserPasswordRequest');
export const updateAdminUserPasswordSuccess = createAction('updateAdminUserPasswordSuccess');
export const updateAdminUserPasswordFailure = createAction('updateAdminUserPasswordFailure');

export const updateAdminUserPassword = (id: number, postbody: UpdatePasswordPost) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(updateAdminUserPasswordRequest());
    try {
        await api.patch(`admins/${id}/password`, postbody);

        dispatch(updateAdminUserPasswordSuccess());
    } catch (e) {
        handleApiErrors(dispatch, updateAdminUserPasswordFailure, e);
    }
};
