import Tooltip from '@components/modal/Tooltip';
import { useState } from 'react';

const InfoHover: React.FC<InfoHoverProps> = ({ title, description, children, color = 'green' }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    return (
        <>
            <span
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
                className={`status-badge static ${color}`}
            >
                <i className="icon far fa-fw fa-info"></i>
                <span className="text">{children}</span>
            </span>
            <Tooltip
                customStyles={{ left: '50%', alignItems: 'flex-start' }}
                title={title}
                visible={showTooltip}
                text={description}
            />
        </>
    );
};

interface InfoHoverProps {
    color?: string;
    icon?: string;
    title: string;
    description: string;
}

export default InfoHover;
