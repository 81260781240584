import { RootState } from '@reducers/index';
import { Company, CompanyLog, PendingEntry } from '@customTypes/shared/Company';

export const getCompanies = (state: RootState): Record<number, Company> =>
    state.companiesReducer.companies;
export const getPendingCompanies = (state: RootState): Record<number, Company> =>
    state.companiesReducer.pendingCompanies;
export const getCompany = (state: RootState, id: number): Company =>
    state.companiesReducer.companies[id];
export const getCompaniesIsFetching = (state: RootState): boolean =>
    state.companiesReducer.isFetching;
export const getCompaniesFetchError = (state: RootState): string | null =>
    state.companiesReducer.fetchError;
export const getCompaniesIsDeleting = (state: RootState): boolean =>
    state.companiesReducer.isDeleting;
export const getCompaniesDeleteError = (state: RootState): string | null =>
    state.companiesReducer.deleteError;
export const getCompaniesIsPosting = (state: RootState): boolean =>
    state.companiesReducer.isPosting;
export const getCompaniesPostError = (state: RootState): string | null =>
    state.companiesReducer.postError;
export const getCompaniesPostSuccess = (state: RootState): boolean =>
    state.companiesReducer.postSuccess;
export const getCompaniesIsSyncing = (state: RootState): boolean =>
    state.companiesReducer.isSyncing;
export const getCompaniesSyncError = (state: RootState): string | null =>
    state.companiesReducer.syncError;

export const getCompaniesCurrentPage = (state: RootState): number =>
    state.companiesReducer.currentPage;
export const getCompaniesPageSize = (state: RootState): number => state.companiesReducer.pageSize;
export const getCompaniesTotalPages = (state: RootState): number | null =>
    state.companiesReducer.totalPages;
export const getCompaniesTotalCount = (state: RootState): number | null =>
    state.companiesReducer.totalCount;

export const getCompanyLogs = (state: RootState): Record<number, CompanyLog> =>
    state.companiesReducer.latestCompanyLogs;
export const getPendingEntries = (state: RootState): Record<number, PendingEntry> =>
    state.companiesReducer.pendingEntries;
