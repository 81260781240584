import { Column } from '@customTypes/table';
import TablePagination from './TablePagination';
import TableSearch from './TableSearch';
import useTable from './useTable';

const Table = <TRow extends { key?: number; id?: number }>({
    columns = [],
    rows = [],
    isLoading = false,
    pageSizes = [10, 25, 100],
    noDataMessage = 'There is no data to display',
    defaultSortKey = null,
    defaultSortAsc = true,
    excludePagination = false,
    selectedIndex,
    setSelectedIndex,
}: TableProps<TRow>): JSX.Element => {
    const {
        paginatedRows,
        handleSortClick,
        sortColumn,
        sortAsc,
        page,
        setPage,
        maxPage,
        pageSizeOpts,
        pageSize,
        setPageSize,
        paginationDescription,
        searchTerm,
        setSearchTerm,
        canSearch,
    } = useTable<TRow>({
        columns,
        rows,
        pageSizes,
        defaultSortKey,
        defaultSortAsc,
        excludePagination,
        isFetching: isLoading,
    });

    const isEmpty = !rows.length;

    return (
        <>
            <div className="table-top-filter flex-row-reverse justify-between align-center">
                {!isEmpty && !excludePagination && (
                    <TablePagination
                        page={page}
                        setPage={setPage}
                        maxPage={maxPage}
                        pageSizeOpts={pageSizeOpts}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        description={paginationDescription}
                    />
                )}

                {canSearch && <TableSearch value={searchTerm} onChange={setSearchTerm} />}
            </div>

            <table>
                <thead>
                    <tr>{columns.map(renderHeading)}</tr>
                </thead>
                <tbody>
                    {isEmpty && !isLoading && (
                        <tr className="no-data-row">
                            <td colSpan={columns.length}>{noDataMessage}</td>
                        </tr>
                    )}
                    {paginatedRows.map((row, index) => (
                        <tr
                            key={row.key || row.id || index}
                            className={`${setSelectedIndex ? 'selectable' : ''} ${
                                index === selectedIndex ? 'selected' : ''
                            }`}
                            onClick={setSelectedIndex ? () => setSelectedIndex(index) : () => {}}
                        >
                            {columns.map(col => (
                                <td key={col.key}>{col.getValue(row)}</td>
                            ))}
                        </tr>
                    ))}
                    {isLoading && (
                        <tr className="table-loading">
                            <td className="overlay"></td>
                            <td className="content">
                                <i className="fal fa-spinner fa-spin"></i>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            {!isEmpty && !excludePagination && (
                <TablePagination
                    page={page}
                    setPage={setPage}
                    maxPage={maxPage}
                    pageSizeOpts={pageSizeOpts}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    description={paginationDescription}
                />
            )}
        </>
    );

    function renderHeading(col: Column<TRow>) {
        const { key, heading, getSort } = col;
        const canSort = getSort !== undefined;
        const isSorting = sortColumn?.key === key;

        return (
            <th
                key={key}
                className={canSort ? 'sortable' : ''}
                onClick={e => handleSortClick(e, col)}
            >
                {isSorting && sortAsc && <i className="fad fa-sort-up" />}
                {isSorting && !sortAsc && <i className="fad fa-sort-down" />}
                {heading}
            </th>
        );
    }
};

interface TableProps<T> {
    columns: Column<T>[];
    rows: T[];
    isLoading?: boolean;
    pageSizes?: number[];
    noDataMessage?: string;
    defaultSortKey?: number | null;
    defaultSortAsc?: boolean;
    excludePagination?: boolean;
    selectedIndex?: number | null;
    setSelectedIndex?: (index: number) => void;
}

export default Table;
