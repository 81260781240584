import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import DataSearch from './DataSearch';
import SingleFlowModal from './SingleFlowModal';
import { getFlow, getFlowsFetchError, getFlowsIsFetching } from '@selectors/flows';
import { fetchSingleFlow } from '@actions/flows';
import ErrorModal from '@components/modal/ErrorModal';

const DataSearchContainer: React.FC = () => {
    const dispatch = useDispatch();
    const { id } = useParams<{ id: string }>();
    const flow = useSelector(getFlow);
    const flowsIsFetching = useSelector(getFlowsIsFetching);
    const flowsFetchError = useSelector(getFlowsFetchError);

    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (!!id) {
            // console.log(id); // DEBUG
            dispatch(fetchSingleFlow(+id));
        }
    }, [id, dispatch]);

    useEffect(() => {
        if (flowsFetchError) {
            setShowError(true);
        }
    }, [flowsFetchError]);

    return (
        <>
            <DataSearch />
            {!!id && !flowsIsFetching && <SingleFlowModal flow={flow} />}
            {showError && (
                <ErrorModal
                    title="Error"
                    description="There was an error fetching the data flow."
                    closeModal={() => setShowError(false)}
                />
            )}
        </>
    );
};

export default DataSearchContainer;
