import React, { useState } from 'react';

import { PendingLeadEntry } from '@customTypes/shared/LeadOrganisation';
import { Column } from '@customTypes/table';

import usePendingLeadEntryResolve from './hooks/usePendingLeadEntryResolve';

import Table from '@components/table/Table';
import ActionButton from '@components/button/ActionButton';
import ButtonRow from '@components/button/ButtonRow';

import LeadOrganisationPendingUpdateModal from './LeadOrganisationPendingUpdateModal';
import LeadingOrganisationPendingIgnoreModal from './LeadOrganisationPendingIgnoreModal';

const LeadOrganisationsPendingLeadEntryTable: React.FC<LeadTableProps> = ({ entries }) => {
    const { resolvePendingLeadEntry, deletePendingLeadEntry } = usePendingLeadEntryResolve();

    const columns: Column<PendingLeadEntry>[] = [
        {
            key: 1,
            heading: 'Match Name',
            getValue: ({ fieldType }) => fieldTypes[fieldType],
        },
        {
            key: 2,
            heading: 'Conflicting Fields',
            getValue: ({ wbValue, conflictingValue }) => (
                <RenderConflicts wbValue={wbValue} conflictingValue={conflictingValue} />
            ),
        },
        {
            key: 3,
            heading: '',
            getValue: row => {
                return (
                    <RenderConflictButtons
                        chooseConflicting={resolvePendingLeadEntry}
                        ignore={deletePendingLeadEntry}
                        id={row.id}
                    />
                );
            },
        },
    ];

    return <Table columns={columns} rows={Object.values(entries)} />;
};

interface LeadTableProps {
    entries: Record<number, PendingLeadEntry>;
}

const fieldTypes: Record<number, string> = {
    10: 'SicCodes',
};

interface RenderConflictsProps {
    wbValue: string;
    conflictingValue: string;
}

interface ConflictButtonsProps {
    chooseConflicting: (id: number) => void;
    ignore: (id: number) => void;
    id: number;
}

const RenderConflicts: React.FC<RenderConflictsProps> = ({ wbValue, conflictingValue }) => {
    return (
        <div>
            <div>
                <strong>Workbooks value:</strong> {wbValue || '--'}
            </div>
            <div>
                <strong>Conflicting value:</strong> {conflictingValue || '--'}
            </div>
        </div>
    );
};

const RenderConflictButtons: React.FC<ConflictButtonsProps> = ({
    chooseConflicting = () => {},
    ignore = () => {},
    id,
}) => {
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showIgnoreModal, setShowIgnoreModal] = useState(false);
    return (
        <>
            <ButtonRow alignment="right">
                <ActionButton onClick={() => setShowUpdateModal(true)} icon="check">
                    Choose conflicting
                </ActionButton>
                <span style={{ width: '5px' }}></span>
                <ActionButton onClick={() => setShowIgnoreModal(true)} color="red" icon="times">
                    Ignore
                </ActionButton>
            </ButtonRow>

            <LeadOrganisationPendingUpdateModal
                visible={showUpdateModal}
                handleClose={() => setShowUpdateModal(false)}
                handleSubmit={() => chooseConflicting(id)}
            />

            <LeadingOrganisationPendingIgnoreModal
                visible={showIgnoreModal}
                handleClose={() => setShowIgnoreModal(false)}
                handleSubmit={() => ignore(id)}
            />
        </>
    );
};

export default LeadOrganisationsPendingLeadEntryTable;
