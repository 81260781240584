import { useDispatch, useSelector } from 'react-redux';
import { postPendingEntryResolve, deletePendingEntryResolve } from '@actions/companies';
import { getPendingEntries } from '@selectors/companies';
import { PendingEntry } from '@customTypes/shared/Company';

const usePendingEntryResolve = (): ReturnProps => {
    const dispatch = useDispatch();
    const pendingEntries = useSelector(getPendingEntries);

    const resolvePendingEntry = (leadId: number) => {
        dispatch(postPendingEntryResolve(leadId));
    };

    const deletePendingEntry = (leadId: number) => {
        dispatch(deletePendingEntryResolve(leadId));
    };

    return { pendingEntries, resolvePendingEntry, deletePendingEntry };
};

type ReturnFunction = (leadId: number) => void;

interface ReturnProps {
    pendingEntries: Record<number, PendingEntry>;
    resolvePendingEntry: ReturnFunction;
    deletePendingEntry: ReturnFunction;
}

export default usePendingEntryResolve;
