import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from '@utils/generic';

import {
    fetchImmerseUKContactsRequest,
    fetchImmerseUKContactsSuccess,
    fetchImmerseUKContactsFailure,
    fetchPendingImmerseUKContactsRequest,
    fetchPendingImmerseUKContactsSuccess,
    fetchPendingImmerseUKContactsFailure,
    fetchSingleImmerseUKContactRequest,
    fetchSingleImmerseUKContactSuccess,
    fetchSingleImmerseUKContactFailure,
    syncImmerseUKContactRequest,
    syncImmerseUKContactSuccess,
    syncImmerseUKContactFailure,
    updateImmerseUKConflictRequest,
    updateImmerseUKConflictSuccess,
    updateImmerseUKConflictFailure,
} from '@actions/immerseUKContacts';

import {
    ImmerseUKContact,
    ImmerseUKContactConflict,
    ImmerseUKContactLog,
    ImmerseUKContactsResponse,
    ImmerseUKSingleContactResponse,
} from '@customTypes/shared/ImmerseUKContact';
import { PaginationProps } from '@customTypes/shared/Pagination';

interface ImmerseUKContactsState extends PaginationProps {
    isFetching: boolean;
    isSyncing: boolean;
    isPosting: boolean;
    fetchError: string | null;
    syncError: string | null;
    postError: string | null;
    postSuccess: boolean;
    contacts: Record<number, ImmerseUKContact>;
    pendingContacts: Record<number, ImmerseUKContact>;
    logs: Record<number, ImmerseUKContactLog>;
    conflicts: Record<number, ImmerseUKContactConflict>;
}

const initialState: ImmerseUKContactsState = {
    isFetching: false,
    isSyncing: false,
    isPosting: false,
    fetchError: null,
    syncError: null,
    postError: null,
    postSuccess: false,
    contacts: {},
    pendingContacts: {},
    logs: {},
    conflicts: {},
    currentPage: 1,
    pageSize: 10,
    totalCount: null,
    totalPages: null,
};

export default createReducer(initialState, {
    [fetchImmerseUKContactsRequest.type]: handleFetchRequest,
    [fetchImmerseUKContactsFailure.type]: handleFetchFailure,
    [fetchImmerseUKContactsSuccess.type]: handleFetchAllSuccess,
    [fetchPendingImmerseUKContactsRequest.type]: handleFetchRequest,
    [fetchPendingImmerseUKContactsFailure.type]: handleFetchFailure,
    [fetchPendingImmerseUKContactsSuccess.type]: handleFetchPendingSuccess,
    [fetchSingleImmerseUKContactRequest.type]: handleFetchRequest,
    [fetchSingleImmerseUKContactFailure.type]: handleFetchFailure,
    [fetchSingleImmerseUKContactSuccess.type]: handleFetchSingleSuccess,
    [syncImmerseUKContactRequest.type]: handleSyncRequest,
    [syncImmerseUKContactFailure.type]: handleSyncFailure,
    [syncImmerseUKContactSuccess.type]: handleSyncSuccess,
    [updateImmerseUKConflictRequest.type]: handlePostRequest,
    [updateImmerseUKConflictFailure.type]: handlePostFailure,
    [updateImmerseUKConflictSuccess.type]: handleUpdateConflictSuccess,
});

function handleFetchRequest(state: ImmerseUKContactsState, action: PayloadAction<boolean>) {
    state.isFetching = true;
    state.fetchError = null;
    if (!action.payload) {
        state.isSyncing = false;
        state.logs = {};
        state.conflicts = {};
    }
}

function handleFetchFailure(state: ImmerseUKContactsState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.fetchError = action.payload;
}

function handleFetchAllSuccess(
    state: ImmerseUKContactsState,
    action: PayloadAction<ImmerseUKContactsResponse>,
) {
    const { contacts, currentPage, pageSize, totalCount, totalPages } = action.payload;

    state.isFetching = false;
    state.contacts = convertArrToObj(contacts);
    state.currentPage = currentPage;
    state.pageSize = pageSize;
    state.totalCount = totalCount;
    state.totalPages = totalPages;
}

function handleFetchPendingSuccess(
    state: ImmerseUKContactsState,
    action: PayloadAction<ImmerseUKContactsResponse>,
) {
    const { contacts, currentPage, pageSize, totalCount, totalPages } = action.payload;

    state.isFetching = false;
    state.pendingContacts = convertArrToObj(contacts);
    state.currentPage = currentPage;
    state.pageSize = pageSize;
    state.totalCount = totalCount;
    state.totalPages = totalPages;
}

function handleFetchSingleSuccess(
    state: ImmerseUKContactsState,
    action: PayloadAction<ImmerseUKSingleContactResponse>,
) {
    const { contact, conflicts, logs } = action.payload;

    state.isFetching = false;
    state.contacts[contact.id] = contact;
    state.conflicts = convertArrToObj(conflicts);
    state.logs = convertArrToObj(logs);
}

function handleSyncRequest(state: ImmerseUKContactsState) {
    state.isSyncing = true;
    state.syncError = null;
}

function handleSyncFailure(state: ImmerseUKContactsState, action: PayloadAction<string>) {
    state.isSyncing = false;
    state.syncError = action.payload;
}

function handleSyncSuccess(
    state: ImmerseUKContactsState,
    action: PayloadAction<ImmerseUKSingleContactResponse>,
) {
    const { contact, conflicts, logs } = action.payload;

    state.isSyncing = false;
    state.contacts[contact.id] = contact;
    state.conflicts = convertArrToObj(conflicts);
    state.logs = convertArrToObj(logs);
}

function handlePostRequest(state: ImmerseUKContactsState) {
    state.isPosting = true;
    state.postError = null;
    state.postSuccess = false;
}

function handlePostFailure(state: ImmerseUKContactsState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.postError = action.payload;
}

function handleUpdateConflictSuccess(state: ImmerseUKContactsState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.conflicts[action.payload];
}
