import { RootState } from '@reducers/index';
import { Sync, SyncRate, SyncSearchResult, SyncPeriodicRate } from '@customTypes/shared/Sync';

export const getSyncs = (state: RootState): Record<number, Sync> => state.syncsReducer.syncs;
export const getSync = (state: RootState, id: number): Sync => state.syncsReducer.syncs[id];
export const getSyncsRates = (state: RootState): SyncRate[] => state.syncsReducer.syncRates;
export const getSyncsPeriodicRates = (state: RootState): SyncPeriodicRate =>
    state.syncsReducer.syncPeriodicRates;
export const getSyncsRunning = (state: RootState): Record<number, Sync> =>
    state.syncsReducer.syncsRunning;
export const getSyncSearchResults = (state: RootState): SyncSearchResult[] =>
    state.syncsReducer.searchResults;
export const getSyncsIsFetching = (state: RootState): boolean => state.syncsReducer.isFetching;
export const getSyncsRatesIsFetching = (state: RootState): boolean =>
    state.syncsReducer.isFetchingRates;
export const getSyncsPeriodicRatesIsFetching = (state: RootState): boolean =>
    state.syncsReducer.isFetchingPeriodicRates;
export const getSyncsRunningIsFetching = (state: RootState): boolean =>
    state.syncsReducer.isFetchingRunning;
export const getSyncsFetchError = (state: RootState): string | null =>
    state.syncsReducer.fetchError;
export const getSyncsRatesFetchError = (state: RootState): string | null =>
    state.syncsReducer.fetchRatesError;
export const getSyncsPeriodicRatesFetchError = (state: RootState): string | null =>
    state.syncsReducer.fetchPeriodicRatesError;
export const getSyncsRunningFetchError = (state: RootState): string | null =>
    state.syncsReducer.fetchRunningError;
export const getSyncsUserDidSearch = (state: RootState): boolean =>
    state.syncsReducer.userDidSearch;
