import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { LeadOrganisationResponse } from '@customTypes/shared/LeadOrganisation';

export const fetchLeadOrganisationRequest = createAction('fetchLeadOrganisationRequest');
export const fetchLeadOrganisationSuccess = createAction<LeadOrganisationResponse>(
    'fetchLeadOrganisationSuccess',
);
export const fetchLeadOrganisationFailure = createAction('fetchLeadOrganisationFailure');

export const fetchLeadOrganisation = (id: number | string) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(fetchLeadOrganisationRequest());
    try {
        const { data }: Response = await api.get(`LeadOrganisations/${id}`);
        dispatch(fetchLeadOrganisationSuccess(data));
    } catch (e) {
        console.log(e);
        handleApiErrors(dispatch, fetchLeadOrganisationFailure, e);
    }
};

interface Response {
    data: LeadOrganisationResponse;
}
