import { useDispatch, useSelector } from 'react-redux';
import {
    postPendingLeadEntryResolve,
    deletePendingLeadEntryResolve,
} from '@actions/leadOrganisations';
import { getLeadOrganisationPendingLeadEntries } from '@selectors/leadOrganisations';
import { PendingLeadEntry } from '@customTypes/shared/LeadOrganisation';

const usePendingLeadEntryResolve = (): ReturnProps => {
    const dispatch = useDispatch();
    const pendingLeadEntries = useSelector(getLeadOrganisationPendingLeadEntries);

    const resolvePendingLeadEntry = (leadId: number) => {
        dispatch(postPendingLeadEntryResolve(leadId));
    };

    const deletePendingLeadEntry = (leadId: number) => {
        dispatch(deletePendingLeadEntryResolve(leadId));
    };

    return { pendingLeadEntries, resolvePendingLeadEntry, deletePendingLeadEntry };
};

type ReturnFunction = (leadId: number) => void;

interface ReturnProps {
    pendingLeadEntries: Record<number, PendingLeadEntry>;
    resolvePendingLeadEntry: ReturnFunction;
    deletePendingLeadEntry: ReturnFunction;
}

export default usePendingLeadEntryResolve;
