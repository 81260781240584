import React from 'react';
import { StringNumberType } from '@customTypes/shared/Generics';

const PathCurve: React.FC<PathCurveProps> = ({
    startXY = [0, 0],
    endXY = [50, 50],
    centerXY = [25, 25],
    curveXY = [0, 15],
    strokeWidth = 3,
    strokeColor = '#222222',
    customStyles = {},
}) => {
    return (
        <path
            style={customStyles}
            strokeWidth={strokeWidth}
            d={`M ${startXY[0]} ${startXY[1]} Q ${curveXY[0]} ${curveXY[1]}, ${centerXY[0]} ${centerXY[1]} T ${endXY[0]} ${endXY[1]}`}
            stroke={strokeColor}
            fill="none"
        />
    );
};

interface PathCurveProps {
    startXY: number[];
    endXY: number[];
    centerXY: number[];
    curveXY?: number[];
    strokeWidth?: number;
    strokeColor?: string;
    customStyles?: Record<string, StringNumberType>;
}

export default PathCurve;
