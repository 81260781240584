import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { CventContactsResponse } from '@customTypes/shared/CventContact';

export const fetchPendingCventContactsRequest = createAction('fetchPendingCventContactsRequest');
export const fetchPendingCventContactsSuccess = createAction<CventContactsResponse>(
    'fetchPendingCventContactsSuccess',
);
export const fetchPendingCventContactsFailure = createAction('fetchPendingCventContactsFailure');

export const fetchPendingCventContacts = (
    page: number,
    pageSize: number,
    searchTerm: string,
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchPendingCventContactsRequest());
    try {
        const { data }: Response = await api.get(
            `cventContacts/pending?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}`,
        );

        dispatch(fetchPendingCventContactsSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchPendingCventContactsFailure, e);
    }
};

interface Response {
    data: CventContactsResponse;
}
