import { useRef, useState, useLayoutEffect } from 'react';
import SVGContainer from './SVGContainer';
import PathCurve from './PathCurve';
import { useSelector } from 'react-redux';
import { getDarkMode } from '@selectors/darkMode';

const SVGBracket: React.FC<SVGBracketProps> = ({ flipHorizontal = false }) => {
    const containerRef = useRef<HTMLDivElement>(document.createElement('div'));
    const darkMode = useSelector(getDarkMode);

    const [clientHeight, setClientHeight] = useState(0);
    const [clientWidth, setClientWidth] = useState(0);

    useLayoutEffect(() => {
        if (containerRef.current) {
            setClientWidth(containerRef.current.clientWidth);
            setClientHeight(containerRef.current.clientHeight);
        }
    }, [containerRef]);

    const svgProps = {
        width: clientWidth,
        height: clientHeight,
        customStyles: { flex: 1 },
    };

    const sharedCurveProps = {
        strokeWidth: 2,
        strokeColor: darkMode ? 'lightgray' : '#222222',
        customStyles: {},
    };

    const topCurveProps = {
        ...sharedCurveProps,
        startXY: [flipHorizontal ? clientWidth : 0, 2],
        endXY: [flipHorizontal ? 0 : clientWidth, clientHeight / 2],
        centerXY: [clientWidth / 2, clientHeight * 0.25],
        curveXY: [flipHorizontal ? (clientWidth / 2) * 1 : clientWidth / 2, 0],
    };

    const bottomCurveProps = {
        ...sharedCurveProps,
        startXY: [flipHorizontal ? 0 : clientWidth, clientHeight / 2],
        endXY: [flipHorizontal ? clientWidth : 0, clientHeight - 2],
        centerXY: [clientWidth / 2, clientHeight * 0.75],
        curveXY: [flipHorizontal ? clientWidth / 2 : (clientWidth / 2) * 1, clientHeight / 2],
    };

    const containerStyle = {
        width: '100%',
        height: '100%',
    };

    return (
        <div ref={containerRef} className="bracket-container" style={containerStyle}>
            <SVGContainer {...svgProps}>
                <PathCurve {...topCurveProps} />
                <PathCurve {...bottomCurveProps} />
            </SVGContainer>
        </div>
    );
};

interface SVGBracketProps {
    flipHorizontal?: boolean;
}

export default SVGBracket;
