import React from 'react';

import useDarkMode from '@hooks/useDarkMode';

import ToggleSwitch from '@components/form/ToggleSwitch';

const Footer: React.FC = () => {
    const [darkMode, setDarkMode] = useDarkMode();

    return (
        <footer className="footer flex-row justify-between align-center">
            <p>&copy; Copyright KTN 2020</p>
            <div className="dark-mode-switch">
                <ToggleSwitch
                    label="Dark mode"
                    name="darkmode"
                    onChange={() => setDarkMode(!darkMode)}
                    value={!!darkMode}
                />
            </div>
        </footer>
    );
};

export default Footer;
