import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { StringNumberType } from '@customTypes/shared/Generics';
import { LeadOrganisationResponse } from '@customTypes/shared/LeadOrganisation';

export const postLeadOrganisationSyncRequest = createAction('postLeadOrganisationSyncRequest');
export const postLeadOrganisationSyncSuccess = createAction<LeadOrganisationResponse>(
    'postLeadOrganisationSyncSuccess',
);
export const postLeadOrganisationSyncFailure = createAction('postLeadOrganisationSyncFailure');

export const postLeadOrganisationSync = (id: StringNumberType) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(postLeadOrganisationSyncRequest());
    try {
        const { data }: Response = await api.post(`leadOrganisations/${id}`, {});
        dispatch(postLeadOrganisationSyncSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, postLeadOrganisationSyncFailure, e);
    }
};

interface Response {
    data: LeadOrganisationResponse;
}
