import React, { useCallback } from 'react';
import Description from '@components/typography/Description';

import { PendingChLead } from '@customTypes/shared/LeadOrganisation';
import { Column } from '@customTypes/table';

import LinkButton from '@components/button/LinkButton';
import TableWithFetch from '@components/tableWithFetch/TableWithFetch';
import { useDispatch, useSelector } from 'react-redux';
import {
    getLeadOrganisationPendingChLeads,
    getLeadOrganisationsCurrentPage,
    getLeadOrganisationsIsFetching,
    getLeadOrganisationsPageSize,
    getLeadOrganisationsTotalCount,
    getLeadOrganisationsTotalPages,
} from '@selectors/leadOrganisations';
import { fetchPendingLeadOrganisations } from '@actions/leadOrganisations';
import ButtonRow from '@components/button/ButtonRow';

const ConflictManagementLeadOrganisationsTable: React.FC = () => {
    const dispatch = useDispatch();
    const isFetching = useSelector(getLeadOrganisationsIsFetching);
    const pendingEntries = useSelector(getLeadOrganisationPendingChLeads);
    const currentPage = useSelector(getLeadOrganisationsCurrentPage);
    const pageSize = useSelector(getLeadOrganisationsPageSize);
    const totalPages = useSelector(getLeadOrganisationsTotalPages);
    const totalCount = useSelector(getLeadOrganisationsTotalCount);

    const fetchData = useCallback(
        (page: number, pageSize: number, searchTerm: string) => {
            dispatch(fetchPendingLeadOrganisations(page, pageSize, searchTerm));
        },
        [dispatch],
    );

    return (
        <>
            <Description>Here is a list of all the pending lead records.</Description>
            <TableWithFetch
                columns={columns}
                rows={Object.values(pendingEntries)}
                isLoading={isFetching}
                fetchData={fetchData}
                currentPage={currentPage}
                pageSize={pageSize}
                totalPages={totalPages}
                totalCount={totalCount}
                searchType="cmLeadOrganisations"
            />
        </>
    );
};

export default ConflictManagementLeadOrganisationsTable;

const columns: Column<PendingChLead>[] = [
    {
        key: 1,
        heading: 'Lead name',
        getValue: row => row.companyName,
        searchable: true,
    },
    {
        key: 2,
        heading: 'Workbooks ID',
        getValue: row => `${row.leadOrgID}`,
        searchable: true,
    },
    {
        key: 3,
        heading: 'Companies House Number',
        getValue: row => `${row.companyNumber}`,
        searchable: true,
    },
    {
        key: 4,
        heading: '',
        getValue: row => (
            <ButtonRow alignment="right">
                <LinkButton href={`/conflict-management/lead-organisations/${row.leadOrgID}`}>
                    View details
                </LinkButton>
            </ButtonRow>
        ),
    },
];
