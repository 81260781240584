import { useCallback, useEffect, useMemo, useState } from 'react';
import { DropdownOption } from '@customTypes/shared/DropdownOption';
import { Column } from '@customTypes/table';
import usePrevious from '@hooks/usePrevious';

export default function useTable<T>({
    columns = [],
    rows = [],
    pageSizes = [],
    defaultSortKey = null,
    defaultSortAsc = true,
    excludePagination,
    isFetching = false,
}: useTableProps<T>) {
    const [sortColumn, setSortColumn] = useState(
        columns.find(col => {
            if (defaultSortKey) {
                if (col.key === defaultSortKey && col.getSort) {
                    return true;
                } else {
                    return false;
                }
            }

            return col.getSort;
        }),
    );
    const [sortAsc, setSortAsc] = useState(defaultSortAsc);
    const [searchTerm, setSearchTerm] = useState('');

    const filteredRows = useMemo(() => {
        const formatedTerm = searchTerm.replace(/\s/g, '').toLowerCase();
        if (!formatedTerm) return rows;

        return rows.filter(row =>
            columns
                .filter(col => col.searchable)
                .map(col => col.getValue(row))
                .join('')
                .replace(/\s/g, '')
                .toLowerCase()
                .includes(formatedTerm),
        );
    }, [searchTerm, rows, columns]);

    const sortedRows = useMemo(() => {
        if (!sortColumn) return rows;
        if (sortAsc) return [...filteredRows].sort(sortColumn.getSort);
        return [...filteredRows].sort((a, b) => {
            if (!sortColumn.getSort) {
                return 0;
            }
            return sortColumn.getSort(a, b) * -1;
        });
    }, [sortColumn, filteredRows, sortAsc, rows]);

    const [pageSize, setPageSize] = useState(pageSizes[0]);
    const [page, setPage] = useState(1);
    const prevProps = usePrevious({ pageSize });

    const maxPage = useMemo(() => Math.ceil(filteredRows.length / pageSize), [
        filteredRows,
        pageSize,
    ]);

    useEffect(() => {
        if (pageSize === prevProps.pageSize) setPageSize(pageSizes[0]);
    }, [setPageSize, setPage, pageSizes, pageSize, prevProps.pageSize]);

    const paginatedRows = useMemo(() => {
        const startIndex = (page - 1) * pageSize;
        if (excludePagination) return sortedRows;
        return sortedRows.slice(startIndex, startIndex + pageSize);
    }, [sortedRows, page, pageSize, excludePagination]);

    const handleSortClick = useCallback(
        (e, col) => {
            e.preventDefault();

            const isSortable = col.getSort !== undefined;
            if (!isSortable) return;

            setPage(1);

            if (sortColumn?.key === col.key) {
                setSortAsc(prev => !prev);
            } else {
                setSortColumn(col);
                setSortAsc(true);
            }
        },
        [sortColumn],
    );

    const pageSizeOpts: DropdownOption<number>[] = useMemo(
        () => pageSizes.map<DropdownOption<number>>(ps => ({ label: ps.toString(), value: ps })),
        [pageSizes],
    );

    const canSearch = useMemo(() => columns.some(col => col.searchable), [columns]);

    const paginationDescription = useMemo(() => {
        // const rowCount = filteredRows.length;
        // const startRow = rowCount ? (page - 1) * pageSize + 1 : 0;
        // const endRow = startRow + pageSize > rowCount ? rowCount : startRow + pageSize - 1;

        // return `${startRow}-${endRow} of ${rowCount}`;

        //changed to page counter as client
        return `${page} of ${maxPage}`;
    }, [page, maxPage]);

    const handleSetPage = useCallback(size => {
        setPageSize(size);
        setPage(1);
    }, []);

    useEffect(() => {
        if (isFetching) {
            setPage(1);
        }
    }, [isFetching, setPage]);

    return {
        paginatedRows,
        handleSortClick,
        sortColumn,
        sortAsc,
        setPage,
        page,
        pageSizeOpts,
        pageSize,
        setPageSize: handleSetPage,
        paginationDescription,
        maxPage,
        canSearch,
        searchTerm,
        setSearchTerm,
    };
}

interface useTableProps<T> {
    pageSizes: number[];
    rows: T[];
    columns: Column<T>[];
    defaultSortKey: number | null;
    defaultSortAsc: boolean;
    excludePagination: boolean;
    isFetching?: boolean;
}
