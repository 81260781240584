import { StringNumberType } from '@customTypes/shared/Generics';
import React from 'react';

const TabNavItem: React.FC<TabProps> = ({ text, setCurrentTab, currentTab, id }) => {
    const handleChangeTab = () => {
        setCurrentTab(id);
    };

    return (
        <div
            className={`tabs-navitem ${currentTab === id ? 'active' : ''}`}
            onClick={handleChangeTab}
        >
            {text}
        </div>
    );
};

interface TabProps {
    id: string;
    setCurrentTab: (id: string) => void;
    text: string;
    currentTab: StringNumberType;
}

export default TabNavItem;
