import { Switch, Route, useRouteMatch } from 'react-router-dom';

import CventContacts from '@pages/contacts/cventContacts/CventContacts';
import CventContactSingle from '@pages/contacts/cventContacts/CventContactSingle';

const CventContactsRoutes: React.FC = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <CventContacts />
            </Route>
            <Route exact path={`${path}/:id`}>
                <CventContactSingle />
            </Route>
        </Switch>
    );
};

export default CventContactsRoutes;
