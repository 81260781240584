import { useRef, useState, useLayoutEffect } from 'react';
import SVGContainer from './SVGContainer';
import PathLine from './PathLine';
import PolygonTriangle from './PolygonTriangle';
import { calcAngle } from '@utils/generic';
import { useSelector } from 'react-redux';
import { getDarkMode } from '@selectors/darkMode';

const SVGStraightArrow: React.FC<SVGStraightArrowProps> = ({
    pointerLength = 20,
    pointerWidth = 15,
    horizontalOnly = false,
    verticalOnly = false,
    up = false,
    left = false,
}) => {
    const containerRef = useRef<HTMLDivElement>(document.createElement('div'));
    const [clientHeight, setClientHeight] = useState(0);
    const [clientWidth, setClientWidth] = useState(0);

    const darkMode = useSelector(getDarkMode);

    useLayoutEffect(() => {
        if (containerRef.current) {
            setClientWidth(containerRef.current.clientWidth);
            setClientHeight(containerRef.current.clientHeight);
        }
    }, [containerRef]);

    const svgProps = {
        width: clientWidth,
        height: clientHeight,
        customStyles: { flex: 1 },
    };

    const lineProps = {
        startXY: [left ? clientWidth : 0, up ? clientHeight : 0],
        endXY: [
            left ? pointerLength : clientWidth - pointerLength,
            up ? pointerLength : clientHeight - pointerLength,
        ],
        strokeWidth: 2,
        strokeColor: darkMode ? 'lightgray' : '#222222',
        fill: 'transparent',
        customStyles: {},
    };

    if (!horizontalOnly)
        lineProps.endXY[0] = left ? pointerLength * 0.66 : clientWidth - pointerLength * 0.66;
    if (!verticalOnly)
        lineProps.endXY[1] = up ? pointerLength * 0.8 : clientHeight - pointerLength * 0.8;

    if (horizontalOnly) {
        lineProps.startXY[1] = clientHeight / 2;
        lineProps.endXY[1] = clientHeight / 2;
    }
    if (verticalOnly) {
        lineProps.startXY[0] = clientWidth / 2;
        lineProps.endXY[0] = clientWidth / 2;
    }

    const triangleProps = {
        startXY: [lineProps.endXY[0], lineProps.endXY[1]],
        width: pointerLength,
        height: pointerWidth,
        angle: calcAngle(
            lineProps.startXY[0],
            lineProps.startXY[1],
            lineProps.endXY[0],
            lineProps.endXY[1],
        ),
        color: darkMode ? 'lightgray' : '#222222',
    };

    const containerStyle = {
        width: '100%',
        height: '100%',
    };

    return (
        <div ref={containerRef} className="arrow-container" style={containerStyle}>
            <SVGContainer {...svgProps}>
                <PathLine {...lineProps} />
                <PolygonTriangle {...triangleProps} />
            </SVGContainer>
        </div>
    );
};

interface SVGStraightArrowProps {
    pointerLength?: number;
    pointerWidth?: number;
    horizontalOnly?: boolean;
    verticalOnly?: boolean;
    up?: boolean;
    left?: boolean;
}

export default SVGStraightArrow;
