import { RootState } from '@reducers/index';
import {
    ImmerseUKContact,
    ImmerseUKContactConflict,
    ImmerseUKContactLog,
} from '@customTypes/shared/ImmerseUKContact';

export const getImmerseUKContacts = (state: RootState): Record<number, ImmerseUKContact> =>
    state.immerseUKContactsReducer.contacts;
export const getPendingImmerseUKContacts = (state: RootState): Record<number, ImmerseUKContact> =>
    state.immerseUKContactsReducer.pendingContacts;
export const getImmerseUKContact = (state: RootState, id: number): ImmerseUKContact =>
    state.immerseUKContactsReducer.contacts[id];
export const getImmerseUKContactsIsFetching = (state: RootState): boolean =>
    state.immerseUKContactsReducer.isFetching;
export const getImmerseUKContactsFetchError = (state: RootState): string | null =>
    state.immerseUKContactsReducer.fetchError;
export const getImmerseUKContactsIsSyncing = (state: RootState): boolean =>
    state.immerseUKContactsReducer.isSyncing;
export const getImmerseUKContactsSyncError = (state: RootState): string | null =>
    state.immerseUKContactsReducer.syncError;
export const getImmerseUKContactsIsPosting = (state: RootState): boolean =>
    state.immerseUKContactsReducer.isPosting;
export const getImmerseUKContactsPostError = (state: RootState): string | null =>
    state.immerseUKContactsReducer.postError;
export const getImmerseUKContactsPostSuccess = (state: RootState): boolean =>
    state.immerseUKContactsReducer.postSuccess;

export const getImmerseUKContactsCurrentPage = (state: RootState): number =>
    state.immerseUKContactsReducer.currentPage;
export const getImmerseUKContactsPageSize = (state: RootState): number =>
    state.immerseUKContactsReducer.pageSize;
export const getImmerseUKContactsTotalPages = (state: RootState): number | null =>
    state.immerseUKContactsReducer.totalPages;
export const getImmerseUKContactsTotalCount = (state: RootState): number | null =>
    state.immerseUKContactsReducer.totalCount;

export const getImmerseUKContactConflicts = (
    state: RootState,
): Record<number, ImmerseUKContactConflict> => state.immerseUKContactsReducer.conflicts;
export const getImmerseUKContactLogs = (state: RootState): Record<number, ImmerseUKContactLog> =>
    state.immerseUKContactsReducer.logs;
