import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { ImmerseUKSingleContactResponse } from '@customTypes/shared/ImmerseUKContact';
import { StringNumberType } from '@customTypes/shared/Generics';

export const fetchSingleImmerseUKContactRequest = createAction<boolean>(
    'fetchSingleImmerseUKContactRequest',
);
export const fetchSingleImmerseUKContactSuccess = createAction<ImmerseUKSingleContactResponse>(
    'fetchSingleImmerseUKContactSuccess',
);
export const fetchSingleImmerseUKContactFailure = createAction(
    'fetchSingleImmerseUKContactFailure',
);

export const fetchSingleImmerseUKContact = (id: StringNumberType, isRefetch = false) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(fetchSingleImmerseUKContactRequest(isRefetch));
    try {
        const { data }: Response = await api.get(`immerseContacts/${id}`);

        dispatch(fetchSingleImmerseUKContactSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSingleImmerseUKContactFailure, e);
    }
};

interface Response {
    data: ImmerseUKSingleContactResponse;
}
