import React from 'react';
import { useParams } from 'react-router';

import ActionButton from '@components/button/ActionButton';
import useSingleDotMailerContactSync from './_hooks/useSingleDotMailerContactSync';

const DotMailerContactSingleSync: React.FC = () => {
    const { id } = useParams<ParamType>();
    const { isSyncing, handleSync } = useSingleDotMailerContactSync(id);

    return (
        <ActionButton
            color="blue"
            icon={`sync ${isSyncing ? 'fa-spin' : ''}`}
            isPosting={isSyncing}
            onClick={handleSync}
        >
            Sync
        </ActionButton>
    );
};

type ParamType = { id: string };

export default DotMailerContactSingleSync;
