import { combineReducers } from 'redux';

import adminUsersReducer from './adminUsers';
import authReducer from './auth';
import companiesReducer from './companies';
import conflictManagementReducer from './conflictManagement';
import cventContactsReducer from './cventContacts';
import darkModeReducer from '@reducers/darkMode';
import dotMailerContactsReducer from './dotMailerContacts';
import fieldErrorsReducer from '@reducers/fieldErrors';
import flowsReducer from './flows';
import immerseUKContactsReducer from './immerseUKContacts';
import leadOrganisationsReducer from './leadOrganisations';
import mobileSettingsReducer from './mobileSettings';
import redirectsReducer from '@reducers/redirects';
import searchTermsReducer from './searchTerms';
import syncsReducer from './syncs';
import usersReducer from './users';

const rootReducer = combineReducers({
    adminUsersReducer,
    authReducer,
    companiesReducer,
    conflictManagementReducer,
    cventContactsReducer,
    darkModeReducer,
    dotMailerContactsReducer,
    fieldErrorsReducer,
    flowsReducer,
    immerseUKContactsReducer,
    leadOrganisationsReducer,
    mobileSettingsReducer,
    redirectsReducer,
    searchTermsReducer,
    syncsReducer,
    usersReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
