// alignment options are: left, center, right
const Subtitle: React.FC<SubtitleProps> = ({
    children,
    alignment = '',
    className = '',
    ...props
}) => {
    return (
        <h2 className={`page-subtitle ${alignment} ${className}`} {...props}>
            {children}
        </h2>
    );
};

interface SubtitleProps {
    alignment?: string;
    className?: string;
}

export default Subtitle;
