import { StringNumberType } from '@customTypes/shared/Generics';
import { Link } from 'react-router-dom';

const CloseModalButton: React.FC<CloseModalButtonProps> = ({
    color = 'green',
    icon,
    href = '#',
    customStyles = {},
}) => (
    <Link to={href} className={`close-modal-button ${color}`} style={customStyles}>
        {icon ? <i className={`icon far fa-fw fa-${icon}`}></i> : <span className="text">X</span>}
    </Link>
);

interface CloseModalButtonProps {
    color?: string;
    icon?: string;
    href: string;
    disabled?: boolean;
    customStyles?: Record<string, StringNumberType>;
}

export default CloseModalButton;
