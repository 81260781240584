import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchSyncsPeriodicRates } from '@actions/syncs';
import {
    getSyncsPeriodicRates,
    getSyncsPeriodicRatesIsFetching,
    getSyncsPeriodicRatesFetchError,
} from '@selectors/syncs';

import { SyncPeriodicRate } from '@customTypes/shared/Sync';
import { periodicSummaryTypeValues } from '@constants/enums/syncEnums';

const useDashboardPeriodicRatesFetch = (
    externalAPI: number,
    type: periodicSummaryTypeValues,
): ReturnProps => {
    const dispatch = useDispatch();
    const periodicRates = useSelector(getSyncsPeriodicRates);
    const isFetching = useSelector(getSyncsPeriodicRatesIsFetching);
    const error = useSelector(getSyncsPeriodicRatesFetchError);

    useEffect(() => {
        dispatch(fetchSyncsPeriodicRates(externalAPI, type));
    }, [dispatch, externalAPI, type]);

    return { isFetching, error, periodicRates };
};

interface ReturnProps {
    isFetching: boolean;
    error: string | null;
    periodicRates: SyncPeriodicRate;
}

export default useDashboardPeriodicRatesFetch;
