import { useDispatch, useSelector } from 'react-redux';

import { clearFormError, showFieldErrors } from '@actions/fieldErrors';
import { getFieldErrors, getFormError } from '@selectors/fieldErrors';
import ActionButton from '@components/button/ActionButton';
import ButtonRow from '@components/button/ButtonRow';
import { isEmpty } from '@utils/generic';

const Form: React.FC<FormProps> = ({
    children,
    onSubmit,
    onCancel = () => {},
    omitButtons = false,
    omitCancel = false,
    isPosting = false,
    submitButtonColor = 'green',
}) => {
    const dispatch = useDispatch();
    const fieldErrors = useSelector(getFieldErrors);
    const formError = useSelector(getFormError);

    return (
        <form onSubmit={_handleSubmit}>
            {children}
            {!!formError && <p className="form-generic-error">{formError}</p>}
            {!omitButtons && (
                <ButtonRow alignment="right">
                    {!omitCancel && (
                        <ActionButton
                            color="grey"
                            type="button"
                            onClick={_handleCancel}
                            disabled={isPosting}
                        >
                            Cancel
                        </ActionButton>
                    )}

                    <ActionButton color={submitButtonColor} isPosting={isPosting}>
                        Submit
                    </ActionButton>
                </ButtonRow>
            )}
        </form>
    );

    function _handleCancel(e: React.MouseEvent) {
        e.preventDefault();

        if (!isPosting) onCancel();
    }

    function _handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        if (!isEmpty(fieldErrors)) {
            dispatch(showFieldErrors());
        } else if (!isPosting) {
            dispatch(clearFormError());
            onSubmit();
        }
    }
};

interface FormProps {
    isPosting?: boolean;
    omitButtons?: boolean;
    omitCancel?: boolean;
    onSubmit: () => void;
    onCancel?: () => void;
    submitButtonColor?: string;
}

export default Form;
