import React from 'react';
import { useSelector } from 'react-redux';

import { getFlowsIsFetching, getFlowsFetchError } from '@selectors/flows';

import { syncTypeNames, externalAPIMethodNames } from '@constants/enums/syncEnums';

import Modal from '@components/modal/Modal';
import SingleFlow from './SingleFlow';
import CloseModalButton from '@components/modal/CloseModalButton';
import dayjs from 'dayjs';
import { Flow } from '@customTypes/shared/Flow';

const SingleFlowModal: React.FC<DataSearchModalProps> = ({ flow }) => {
    const isFetching = useSelector(getFlowsIsFetching);
    const fetchError = useSelector(getFlowsFetchError);
    const { apiMethod, dataFlowID, recordUpdated, isSuccess, sync } = flow;

    return (
        <Modal
            size="large"
            title={`Data Flow #${dataFlowID} - ${syncTypeNames[sync]} (${
                externalAPIMethodNames[apiMethod]
            }) - ${dayjs(recordUpdated).format('DD/MM/YYYY - hh:mm')} - ${
                isSuccess ? 'Successful' : 'Errored'
            }`}
            closeModal={() => {}}
        >
            <SingleFlow flow={flow} isFetching={isFetching} fetchError={fetchError} />
            <CloseModalButton icon="times" href="/data-search">
                Close
            </CloseModalButton>
        </Modal>
    );
};

interface DataSearchModalProps {
    flow: Flow;
}

export default SingleFlowModal;
