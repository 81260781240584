import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { PendingChLeadsResponse } from '@customTypes/shared/LeadOrganisation';

export const fetchPendingLeadOrganisationsRequest = createAction(
    'fetchPendingLeadOrganisationsRequest',
);
export const fetchPendingLeadOrganisationsSuccess = createAction<PendingChLeadsResponse>(
    'fetchPendingLeadOrganisationsSuccess',
);
export const fetchPendingLeadOrganisationsFailure = createAction(
    'fetchPendingLeadOrganisationsFailure',
);

export const fetchPendingLeadOrganisations = (
    page: number,
    pageSize: number,
    searchTerm: string,
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchPendingLeadOrganisationsRequest());
    try {
        const { data }: Response = await api.get(
            `LeadOrganisations/chLead?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}`,
        );
        dispatch(fetchPendingLeadOrganisationsSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchPendingLeadOrganisationsFailure, e);
    }
};

interface Response {
    data: PendingChLeadsResponse;
}
