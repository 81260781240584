import React from 'react';

import { isEmpty } from '@utils/generic';

import useDashboardSyncsFetch from './hooks/useDashboardSyncsFetch';

import Title from '@components/typography/Title';
import DashboardRateLimits from './DashboardRateLimits';
import DashboardRunningSyncs from './DashboardRunningSyncs';
import DashboardSyncDetails from './DashboardSyncDetails';
import DataCheck from '@components/generic/dataCheck/DataCheck';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import Subtitle from '@components/typography/Subtitle';
import Helmet from '@components/generic/helmet/Helmet';
import PageTitle from '@components/typography/PageTitle';

const Dashboard: React.FC = () => {
    const {
        isFetchingSyncs,
        isFetchingRates,
        isFetchingRunning,
        syncsError,
        ratesError,
        runningError,
        syncs,
        rates,
        running,
    } = useDashboardSyncsFetch();

    return (
        <>
            <div className="single-section">
                <Helmet title="Dashboard" />
                <PageTitle>Sync Overview</PageTitle>

                <div className="dashboard-row flex-row flex-wrap">
                    <ContentBlock extraClasses="flex-7 flex-12-ld">
                        <Subtitle>API Rate Limits</Subtitle>
                        <DataCheck
                            isEmpty={isEmpty(rates)}
                            isFetching={isFetchingRates}
                            error={ratesError}
                        >
                            <DashboardRateLimits rates={rates} />
                        </DataCheck>
                    </ContentBlock>

                    <ContentBlock extraClasses="dashboard-running-syncs flex">
                        <Subtitle>Currently Running Syncs</Subtitle>
                        <DataCheck
                            isEmpty={isEmpty(running)}
                            isFetching={isFetchingRunning}
                            error={runningError}
                            noDataMessage="There are currently no running syncs"
                        >
                            <DashboardRunningSyncs running={running} />
                        </DataCheck>
                    </ContentBlock>
                </div>
            </div>

            <div className="single-section">
                <Title>Sync Details</Title>
                <DataCheck
                    isEmpty={isEmpty(syncs)}
                    isFetching={isFetchingSyncs}
                    error={syncsError}
                    customWrapperClass="content-block"
                >
                    <DashboardSyncDetails syncs={syncs} />
                </DataCheck>
            </div>
        </>
    );
};

export default Dashboard;
