import Description from '@components/typography/Description';
import ImmerseUKContactsTable from './ImmerseUKContactsTable';
import Helmet from '@components/generic/helmet/Helmet';
import PageTitle from '@components/typography/PageTitle';

const ImmerseUKContacts: React.FC = () => (
    <div className="contacts-wrapper">
        <Helmet title="Immerse UK Contacts" />
        <PageTitle>Immerse UK Contacts</PageTitle>
        <Description>
            Here is a list of all the Immerse UK contacts currently in the system, use the search
            below to find a specific contact.
        </Description>
        <ImmerseUKContactsTable />
    </div>
);

export default ImmerseUKContacts;
