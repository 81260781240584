import { Switch, Route, useRouteMatch } from 'react-router-dom';

import LeadOrganisations from '@pages/leadOrganisations/LeadOrganisations';
import LeadOrganisationSingle from '@pages/leadOrganisations/LeadOrganisationSingle';

const LeadOrganisationsRoutes: React.FC = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <LeadOrganisations />
            </Route>
            <Route path={`${path}/:id`}>
                <LeadOrganisationSingle />
            </Route>
        </Switch>
    );
};

export default LeadOrganisationsRoutes;
