import React from 'react';

import TabNavItem from './TabNavItem';

const Tabs: React.FC<TabsProps> = ({ currentTab, setCurrentTab, tabs }) => {
    return (
        <div className="tabs-nav">
            {Object.values(tabs).map(({ id, text }) => (
                <TabNavItem
                    key={id}
                    id={id}
                    text={text}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                />
            ))}
        </div>
    );
};

interface TabType {
    id: string;
    text: string;
    Component: React.FC;
}

interface TabsProps {
    [key: string]: any;
    tabs: Record<string, TabType>;
    currentTab: string;
    setCurrentTab: (id: string) => void;
}

export default Tabs;
