import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { RootState } from '@reducers/index';

import { getSync, getSyncsFetchError, getSyncsIsFetching } from '@selectors/syncs';

import { syncsSingleBreadcrumb } from '@constants/breadcrumbItems';
import { isEmpty, splitOnUpperCase } from '@utils/generic';

import useSingleSyncFetch from './_hooks/useSingleSyncFetch';

import Description from '@components/typography/Description';
import DataCheck from '@components/generic/dataCheck/DataCheck';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import Breadcrumb from '@components/breadcrumb/Breadcrumb';
import SyncSingleTable from './SyncSingleTable';
import PageTitle from '@components/typography/PageTitle';

const SyncSingle: React.FC = () => {
    const { id } = useParams<ParamsType>();
    useSingleSyncFetch(+id);
    const singleSync = useSelector((state: RootState) => getSync(state, +id)) || { items: [] };
    const isFetching = useSelector(getSyncsIsFetching);
    const fetchError = useSelector(getSyncsFetchError);

    return (
        <div className="syncs-wrapper">
            <Breadcrumb items={syncsSingleBreadcrumb} />

            <CreateHeader>
                <PageTitle>
                    {singleSync.sync ? splitOnUpperCase(singleSync.sync.name) : 'Sync details'}
                </PageTitle>
            </CreateHeader>
            <Description>View all the details of this sync.</Description>
            <DataCheck isEmpty={isEmpty(singleSync)} isFetching={isFetching} error={fetchError}>
                <div className="single-section">
                    <SyncSingleTable items={singleSync.items} isFetching={isFetching} />
                </div>
            </DataCheck>
        </div>
    );
};

type ParamsType = { id: string };

export default SyncSingle;
