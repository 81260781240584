import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { ImmerseUKContactsResponse } from '@customTypes/shared/ImmerseUKContact';

export const fetchImmerseUKContactsRequest = createAction('fetchImmerseUKContactsRequest');
export const fetchImmerseUKContactsSuccess = createAction<ImmerseUKContactsResponse>(
    'fetchImmerseUKContactsSuccess',
);
export const fetchImmerseUKContactsFailure = createAction('fetchImmerseUKContactsFailure');

export const fetchImmerseUKContacts = (
    page: number,
    pageSize: number,
    searchTerm: string,
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchImmerseUKContactsRequest());
    try {
        const { data }: Response = await api.get(
            `immerseContacts?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}`,
        );

        dispatch(fetchImmerseUKContactsSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchImmerseUKContactsFailure, e);
    }
};

interface Response {
    data: ImmerseUKContactsResponse;
}
