const Tooltip: React.FC<TooltipProps> = ({
    visible = false,
    text = '',
    htmlText = '',
    customStyles,
    flipVertical = false,
    title = '',
}) => {
    return (
        <div
            style={customStyles}
            className={`tooltip-container ${visible ? '' : 'hidden'} ${
                flipVertical ? 'flex-column justify-end' : 'flex-column-reverse justify-end'
            }`}
        >
            <div className="tooltip-content">
                {title && (
                    <p className="title">
                        <strong>{title}</strong>
                    </p>
                )}

                {htmlText ? (
                    <div dangerouslySetInnerHTML={{ __html: htmlText }} />
                ) : (
                    <p style={{ wordWrap: 'break-word' }}>{text}</p>
                )}
            </div>
            <div className={`tooltip-pointer ${flipVertical ? 'down' : 'up'}`} />
        </div>
    );
};

interface TooltipProps {
    visible: boolean;
    text?: string;
    htmlText?: string;
    customStyles?: Record<string, unknown>;
    flipVertical?: boolean;
    title?: string;
}

export default Tooltip;
