import React from 'react';
import { Link } from 'react-router-dom';

import { BreadcrumbItem } from '@customTypes/shared/Breadcrumb';

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
    const itemsLength = items.length;

    return (
        <div className="breadcrumb">
            {items.map(({ name, link }, index) => {
                const isLast = index === itemsLength - 1;

                if (link)
                    return (
                        <span key={name}>
                            <Link to={link}>{name}</Link>
                            {!isLast ? ' / ' : ''}
                        </span>
                    );
                return (
                    <span key={name} className={isLast ? 'active' : ''}>{`${name}${
                        !isLast ? ' / ' : ''
                    }`}</span>
                );
            })}
        </div>
    );
};

interface BreadcrumbProps {
    items: BreadcrumbItem[];
}

export default Breadcrumb;
