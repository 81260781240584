import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from '@utils/generic';
import {
    fetchLeadOrganisationsFailure,
    fetchLeadOrganisationsRequest,
    fetchLeadOrganisationsSuccess,
    fetchPendingLeadOrganisationsFailure,
    fetchPendingLeadOrganisationsRequest,
    fetchPendingLeadOrganisationsSuccess,
    fetchLeadOrganisationFailure,
    fetchLeadOrganisationRequest,
    fetchLeadOrganisationSuccess,
    postLeadOrganisationSyncFailure,
    postLeadOrganisationSyncRequest,
    postLeadOrganisationSyncSuccess,
    postPendingLeadEntryResolveFailure,
    postPendingLeadEntryResolveRequest,
    postPendingLeadEntryResolveSuccess,
    deletePendingLeadEntryFailure,
    deletePendingLeadEntryRequest,
    deletePendingLeadEntrySuccess,
    postPendingChLeadResolveFailure,
    postPendingChLeadResolveRequest,
    postPendingChLeadResolveSuccess,
    deletePendingChLeadFailure,
    deletePendingChLeadRequest,
    deletePendingChLeadSuccess,
} from '@actions/leadOrganisations';
import {
    LeadOrganisation,
    LeadOrganisationsResponse,
    PendingChLead,
    PendingLeadEntry,
    LogModel,
    LeadOrganisationResponse,
    PendingChLeadsResponse,
} from '@customTypes/shared/LeadOrganisation';
import { PaginationProps } from '@customTypes/shared/Pagination';

interface LeadOrganisationsState extends PaginationProps {
    isFetching: boolean;
    fetchError: string | null;
    isPosting: boolean;
    postError: string | null;
    postSuccess: boolean;
    isDeleting: boolean;
    deleteError: string | null;
    isSyncing: boolean;
    syncError: string | null;
    leadOrganisations: Record<number, LeadOrganisation>;
    pendingChLeads: Record<number, PendingChLead>;
    pendingLeadEntries: Record<number, PendingLeadEntry>;
    logModels: Record<number, LogModel>;
}

const initialState: LeadOrganisationsState = {
    isFetching: false,
    fetchError: null,
    isPosting: false,
    postError: null,
    postSuccess: false,
    isDeleting: false,
    deleteError: null,
    isSyncing: false,
    syncError: null,
    leadOrganisations: {},
    currentPage: 1,
    pageSize: 10,
    totalCount: null,
    totalPages: null,
    pendingChLeads: {},
    pendingLeadEntries: {},
    logModels: {},
};

export default createReducer(initialState, {
    [fetchLeadOrganisationsRequest.type]: handleFetchRequest,
    [fetchLeadOrganisationsSuccess.type]: handleFetchAllSuccess,
    [fetchLeadOrganisationsFailure.type]: handleFetchError,
    [fetchPendingLeadOrganisationsRequest.type]: handleFetchRequest,
    [fetchPendingLeadOrganisationsSuccess.type]: handleFetchPendingSuccess,
    [fetchPendingLeadOrganisationsFailure.type]: handleFetchError,
    [fetchLeadOrganisationRequest.type]: handleFetchRequest,
    [fetchLeadOrganisationSuccess.type]: handleFetchSingleSuccess,
    [fetchLeadOrganisationFailure.type]: handleFetchError,
    [postLeadOrganisationSyncRequest.type]: handleSyncRequest,
    [postLeadOrganisationSyncSuccess.type]: handlePostSyncSuccess,
    [postLeadOrganisationSyncFailure.type]: handleSyncError,
    [postPendingLeadEntryResolveRequest.type]: handlePostRequest,
    [postPendingLeadEntryResolveSuccess.type]: handlePostLeadResolveSuccess,
    [postPendingLeadEntryResolveFailure.type]: handlePostError,
    [deletePendingLeadEntryRequest.type]: handleDeleteRequest,
    [deletePendingLeadEntrySuccess.type]: handleDeletePendingLeadSuccess,
    [deletePendingLeadEntryFailure.type]: handleDeleteError,
    [postPendingChLeadResolveRequest.type]: handlePostRequest,
    [postPendingChLeadResolveSuccess.type]: handlePostChLeadResolveSuccess,
    [postPendingChLeadResolveFailure.type]: handlePostError,
    [deletePendingChLeadRequest.type]: handleDeleteRequest,
    [deletePendingChLeadSuccess.type]: handleDeletePendingChLeadSuccess,
    [deletePendingChLeadFailure.type]: handleDeleteError,
});

function handleFetchRequest(state: LeadOrganisationsState) {
    state.isFetching = true;
    state.fetchError = null;
}

function handleFetchError(state: LeadOrganisationsState, action: PayloadAction<string>) {
    state.fetchError = action.payload;
    state.isFetching = false;
}

function handlePostRequest(state: LeadOrganisationsState) {
    state.isPosting = true;
    state.postError = null;
    state.postSuccess = false;
}

function handlePostError(state: LeadOrganisationsState, action: PayloadAction<string>) {
    state.postError = action.payload;
    state.isPosting = false;
}

function handleDeleteRequest(state: LeadOrganisationsState) {
    state.isDeleting = true;
    state.deleteError = null;
}

function handleDeleteError(state: LeadOrganisationsState, action: PayloadAction<string>) {
    state.deleteError = action.payload;
    state.isDeleting = false;
}

function handleSyncRequest(state: LeadOrganisationsState) {
    state.isSyncing = true;
    state.syncError = null;
}

function handleSyncError(state: LeadOrganisationsState, action: PayloadAction<string>) {
    state.syncError = action.payload;
    state.isSyncing = false;
}

function handleFetchAllSuccess(
    state: LeadOrganisationsState,
    action: PayloadAction<LeadOrganisationsResponse>,
) {
    const { currentPage, pageSize, totalCount, totalPages, leadOrganisations } = action.payload;

    state.leadOrganisations = convertArrToObj(leadOrganisations);
    state.isFetching = false;
    state.currentPage = currentPage;
    state.pageSize = pageSize;
    state.totalCount = totalCount;
    state.totalPages = totalPages;
}

function handleFetchPendingSuccess(
    state: LeadOrganisationsState,
    action: PayloadAction<PendingChLeadsResponse>,
) {
    const { pendingChLeads, currentPage, pageSize, totalCount, totalPages } = action.payload;

    state.pendingChLeads = convertArrToObj(pendingChLeads);
    state.isFetching = false;
    state.currentPage = currentPage;
    state.pageSize = pageSize;
    state.totalCount = totalCount;
    state.totalPages = totalPages;
}

function handleFetchSingleSuccess(
    state: LeadOrganisationsState,
    action: PayloadAction<LeadOrganisationResponse>,
) {
    const { leadOrganisation, logModels, pendingChLeads, pendingLeadEntries } = action.payload;

    state.leadOrganisations[leadOrganisation.id] = leadOrganisation;
    state.logModels = convertArrToObj(logModels);
    state.pendingChLeads = convertArrToObj(pendingChLeads);
    state.pendingLeadEntries = convertArrToObj(pendingLeadEntries);
    state.isFetching = false;
}
function handlePostSyncSuccess(
    state: LeadOrganisationsState,
    action: PayloadAction<LeadOrganisationResponse>,
) {
    const { leadOrganisation, logModels, pendingChLeads, pendingLeadEntries } = action.payload;
    state.isSyncing = false;
    state.leadOrganisations[leadOrganisation.id] = leadOrganisation;
    state.logModels = convertArrToObj(logModels);
    state.pendingChLeads = convertArrToObj(pendingChLeads);
    state.pendingLeadEntries = convertArrToObj(pendingLeadEntries);
    state.postSuccess = true;
}
function handlePostLeadResolveSuccess(
    state: LeadOrganisationsState,
    action: PayloadAction<number>,
) {
    delete state.pendingLeadEntries[action.payload];
    state.isPosting = false;
    state.postSuccess = true;
}

function handleDeletePendingLeadSuccess(
    state: LeadOrganisationsState,
    action: PayloadAction<number>,
) {
    delete state.pendingLeadEntries[action.payload];
    state.isDeleting = false;
}

function handlePostChLeadResolveSuccess(
    state: LeadOrganisationsState,
    action: PayloadAction<number>,
) {
    delete state.pendingChLeads[action.payload];
    state.isPosting = false;
    state.postSuccess = true;
}

function handleDeletePendingChLeadSuccess(
    state: LeadOrganisationsState,
    action: PayloadAction<number>,
) {
    delete state.pendingChLeads[action.payload];
    state.isDeleting = false;
}
