import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { Flow } from '@customTypes/shared/Flow';

export const fetchSingleFlowRequest = createAction('fetchSingleFlowRequest');
export const fetchSingleFlowSuccess = createAction<Flow[]>('fetchSingleFlowSuccess');
export const fetchSingleFlowFailure = createAction('fetchSingleFlowFailure');

export const fetchSingleFlow = (id: number) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchSingleFlowRequest());
    try {
        const { data }: Response = await api.get(`flows/${id}`);
        // console.log(data);
        dispatch(fetchSingleFlowSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSingleFlowFailure, e);
    }
};

interface Response {
    data: Flow[];
}
