import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { fetchSingleCompany } from '@actions/companies';

import { RootState } from '@reducers/index';

import {
    getCompany,
    getCompanyLogs,
    getPendingEntries,
    getCompaniesFetchError,
    getCompaniesPostError,
    getCompaniesDeleteError,
    getCompaniesSyncError,
    getCompaniesIsFetching,
    getCompaniesIsDeleting,
    getCompaniesIsSyncing,
    getCompaniesIsPosting,
    getCompaniesPostSuccess,
} from '@selectors/companies';

import {
    companiesSingleBreadcrumb,
    conflictManagementCompaniesSingleBreadcrumb,
} from '@constants/breadcrumbItems';
import { isEmpty } from '@utils/generic';

import useSingleCompanyFetch from './_hooks/useSingleCompanyFetch';
import usePrevious from '@hooks/usePrevious';

import Description from '@components/typography/Description';
import Title from '@components/typography/Title';
import Subtitle from '@components/typography/Subtitle';
import CompanySingleDetails from './CompanySingleDetails';
import DataCheck from '@components/generic/dataCheck/DataCheck';
import CompanySingleLogsTable from './CompanySingleLogsTable';
import CompanySinglePendingTable from './CompanySinglePendingTable';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import CompanySingleSync from './CompanySingleSync';
import ErrorModal from '@components/modal/ErrorModal';
import Breadcrumb from '@components/breadcrumb/Breadcrumb';
import PageTitle from '@components/typography/PageTitle';

const CompanySingle: React.FC = () => {
    const dispatch = useDispatch();
    const { id } = useParams<ParamsType>();
    const { pathname } = useLocation();
    useSingleCompanyFetch(id);
    const company = useSelector((state: RootState) => getCompany(state, +id));
    const pendingEntries = useSelector(getPendingEntries);
    const logs = useSelector(getCompanyLogs);
    const isFetching = useSelector(getCompaniesIsFetching);
    const isDeleting = useSelector(getCompaniesIsDeleting);
    const isPosting = useSelector(getCompaniesIsPosting);
    const postSuccess = useSelector(getCompaniesPostSuccess);
    const isSyncing = useSelector(getCompaniesIsSyncing);
    const fetchError = useSelector(getCompaniesFetchError);
    const postError = useSelector(getCompaniesPostError);
    const syncError = useSelector(getCompaniesSyncError);
    const deleteError = useSelector(getCompaniesDeleteError);
    const prevProps = usePrevious({ postError, postSuccess, syncError, deleteError });

    const [showPostError, setShowPostError] = useState(false);
    const [showSyncError, setShowSyncError] = useState(false);
    const [showDeleteError, setShowDeleteError] = useState(false);

    useEffect(() => {
        if (postError && !prevProps.postError) setShowPostError(true);
        if (syncError && !prevProps.syncError) setShowSyncError(true);
        if (deleteError && !prevProps.deleteError) setShowDeleteError(true);

        if (postSuccess && !prevProps.postSuccess) {
            dispatch(fetchSingleCompany(id));
        }
    }, [
        dispatch,
        id,
        postError,
        prevProps.postError,
        syncError,
        prevProps.syncError,
        postSuccess,
        prevProps.postSuccess,
        deleteError,
        prevProps.deleteError,
    ]);

    useEffect(() => {
        if (!isDeleting && !isPosting && !isSyncing) dispatch(fetchSingleCompany(id));
    }, [dispatch, id, isDeleting, isPosting, isSyncing]);

    return (
        <div className="companies-wrapper">
            <Breadcrumb
                items={
                    pathname.includes('conflict-management')
                        ? conflictManagementCompaniesSingleBreadcrumb
                        : companiesSingleBreadcrumb
                }
            />

            <CreateHeader>
                <PageTitle>Company details</PageTitle>
                {!isEmpty(company) && <CompanySingleSync />}
            </CreateHeader>
            <Description>View all the details of this company.</Description>
            <DataCheck isEmpty={isEmpty(company)} isFetching={isFetching} error={fetchError}>
                <div className="single-section">
                    <CompanySingleDetails company={company} />
                </div>
                {!isEmpty(pendingEntries) && (
                    <div className="single-section">
                        <Title>Pending</Title>
                        <Description>
                            This company name does not exist in companies house, see the closest
                            matches below.
                            <br />
                            Please note: The name will not be updated.
                        </Description>
                        <CompanySinglePendingTable pendingEntries={pendingEntries} />
                    </div>
                )}

                <div className="single-section">
                    <Subtitle>Activity Logs</Subtitle>
                    <CompanySingleLogsTable logs={logs} isFetching={isFetching} />
                </div>
            </DataCheck>
            {showPostError && (
                <ErrorModal
                    description={postError || undefined}
                    closeModal={() => setShowPostError(false)}
                />
            )}
            {showSyncError && (
                <ErrorModal
                    description={syncError || undefined}
                    closeModal={() => setShowSyncError(false)}
                />
            )}
            {showDeleteError && (
                <ErrorModal
                    description={deleteError || undefined}
                    closeModal={() => setShowDeleteError(false)}
                />
            )}
        </div>
    );
};

type ParamsType = { id: string };

export default CompanySingle;
