import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { RootState } from '@reducers/index';

import {
    getCventContact,
    getCventContactConflicts,
    getCventContactLogs,
    getCventContactsFetchError,
    getCventContactsIsFetching,
    getCventContactsPostError,
    getCventContactsIsPosting,
    getCventContactsPostSuccess,
} from '@selectors/cventContacts';

import {
    conflictManagementCventSingleBreadcrumb,
    cventSingleBreadcrumb,
} from '@constants/breadcrumbItems';
import { isEmpty } from '@utils/generic';
import usePrevious from '@hooks/usePrevious';

import useSingleCventContactFetch from './_hooks/useSingleCventContactFetch';

import Description from '@components/typography/Description';
import Subtitle from '@components/typography/Subtitle';
import CventContactSingleDetails from './CventContactSingleDetails';
import DataCheck from '@components/generic/dataCheck/DataCheck';
import CventContactSingleLogsTable from './CventContactSingleLogsTable';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import CventContactSingleConflictsTable from './CventContactSingleConflictsTable';
import Breadcrumb from '@components/breadcrumb/Breadcrumb';
import ErrorModal from '@components/modal/ErrorModal';
import { fetchSingleCventContact } from '@actions/cventContacts';
import PageTitle from '@components/typography/PageTitle';

const CventContactSingle: React.FC = () => {
    const dispatch = useDispatch();
    const { id } = useParams<ParamsType>();
    const { pathname } = useLocation();
    useSingleCventContactFetch(id);
    const contact = useSelector((state: RootState) => getCventContact(state, +id));
    const conflicts = useSelector(getCventContactConflicts);
    const logs = useSelector(getCventContactLogs);
    const isFetching = useSelector(getCventContactsIsFetching);
    const fetchError = useSelector(getCventContactsFetchError);
    const isPosting = useSelector(getCventContactsIsPosting);
    const postError = useSelector(getCventContactsPostError);
    const postSuccess = useSelector(getCventContactsPostSuccess);
    const prevProps = usePrevious({ postError, postSuccess, fetchError });

    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (postError && !prevProps.postError) {
            setShowError(true);
        }

        if (postSuccess && !prevProps.postSuccess) {
            dispatch(fetchSingleCventContact(id));
        }
    }, [
        dispatch,
        id,
        postError,
        prevProps.postError,
        postSuccess,
        prevProps.postSuccess,
        fetchError,
        prevProps.fetchError,
    ]);

    useEffect(() => {
        if (!isPosting) dispatch(fetchSingleCventContact(id));
    }, [dispatch, id, isPosting]);

    return (
        <div className="contacts-wrapper">
            <Breadcrumb
                items={
                    pathname.includes('conflict-management')
                        ? conflictManagementCventSingleBreadcrumb
                        : cventSingleBreadcrumb
                }
            />

            <CreateHeader>
                <PageTitle>Cvent contact details</PageTitle>
            </CreateHeader>
            <Description>View all the details of this Cvent contact.</Description>
            <DataCheck
                isEmpty={isEmpty(contact)}
                isFetching={isFetching}
                error={fetchError}
                customWrapperClass="content-block"
            >
                <div className="single-section">
                    <CventContactSingleDetails contact={contact} />
                </div>

                {!isEmpty(conflicts) && (
                    <div className="single-section">
                        <Subtitle>Conflicts</Subtitle>
                        <CventContactSingleConflictsTable
                            conflicts={conflicts}
                            isFetching={isFetching}
                        />
                    </div>
                )}

                <div className="single-section">
                    <Subtitle>Activity Logs</Subtitle>
                    <CventContactSingleLogsTable logs={logs} isFetching={isFetching} />
                </div>
            </DataCheck>
            {showError && (
                <ErrorModal
                    description={postError || undefined}
                    closeModal={() => setShowError(false)}
                />
            )}
        </div>
    );
};

type ParamsType = { id: string };

export default CventContactSingle;
