import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from '@utils/generic';

import {
    fetchSyncsRequest,
    fetchSyncsSuccess,
    fetchSyncsFailure,
    fetchSyncsRunningRequest,
    fetchSyncsRunningSuccess,
    fetchSyncsRunningFailure,
    fetchSyncsSearchRequest,
    fetchSyncsSearchSuccess,
    fetchSyncsSearchFailure,
    fetchSingleSyncRequest,
    fetchSingleSyncSuccess,
    fetchSingleSyncFailure,
    fetchSyncsRatesRequest,
    fetchSyncsRatesSuccess,
    fetchSyncsRatesFailure,
    fetchSyncsPeriodicRatesRequest,
    fetchSyncsPeriodicRatesSuccess,
    fetchSyncsPeriodicRatesFailure,
} from '@actions/syncs';

import { Sync, SyncRate, SyncSearchResult, SyncPeriodicRate } from '@customTypes/shared/Sync';

interface SyncsState {
    isFetching: boolean;
    isFetchingRates: boolean;
    isFetchingRunning: boolean;
    isFetchingPeriodicRates: boolean;
    fetchError: string | null;
    fetchRatesError: string | null;
    fetchRunningError: string | null;
    fetchPeriodicRatesError: string | null;
    syncs: Record<number, Sync>;
    syncsRunning: Record<number, Sync>;
    syncRates: SyncRate[];
    syncPeriodicRates: SyncPeriodicRate;
    searchResults: SyncSearchResult[];
    userDidSearch: boolean;
}

const initialState: SyncsState = {
    isFetching: false,
    isFetchingRates: false,
    isFetchingRunning: false,
    isFetchingPeriodicRates: false,
    fetchError: null,
    fetchRatesError: null,
    fetchRunningError: null,
    fetchPeriodicRatesError: null,
    syncs: {},
    syncsRunning: {},
    syncRates: [],
    syncPeriodicRates: { api: null, callsByDate: {} },
    searchResults: [],
    userDidSearch: false,
};

export default createReducer(initialState, {
    [fetchSyncsRequest.type]: handleFetchRequest,
    [fetchSyncsFailure.type]: handleFetchFailure,
    [fetchSyncsSuccess.type]: handleFetchAllSuccess,
    [fetchSyncsRunningRequest.type]: handleFetchRunningRequest,
    [fetchSyncsRunningFailure.type]: handleFetchRunningFailure,
    [fetchSyncsRunningSuccess.type]: handleFetchRunningSuccess,
    [fetchSyncsSearchRequest.type]: handleFetchSearchRequest,
    [fetchSyncsSearchFailure.type]: handleFetchFailure,
    [fetchSyncsSearchSuccess.type]: handleFetchSearchSuccess,
    [fetchSingleSyncRequest.type]: handleFetchRequest,
    [fetchSingleSyncFailure.type]: handleFetchFailure,
    [fetchSingleSyncSuccess.type]: handleFetchSingleSuccess,
    [fetchSyncsRatesRequest.type]: handleFetchRatesRequest,
    [fetchSyncsRatesFailure.type]: handleFetchRatesFailure,
    [fetchSyncsRatesSuccess.type]: handleFetchRatesSuccess,
    [fetchSyncsPeriodicRatesRequest.type]: handleFetchPeriodicRatesRequest,
    [fetchSyncsPeriodicRatesFailure.type]: handleFetchPeriodicRatesFailure,
    [fetchSyncsPeriodicRatesSuccess.type]: handleFetchPeriodicRatesSuccess,
});

function handleFetchRequest(state: SyncsState) {
    state.isFetching = true;
    state.fetchError = null;
}

function handleFetchSearchRequest(state: SyncsState) {
    state.isFetching = true;
    state.fetchError = null;
    state.userDidSearch = true;
}

function handleFetchRatesRequest(state: SyncsState) {
    state.isFetchingRates = true;
    state.fetchRatesError = null;
}

function handleFetchPeriodicRatesRequest(state: SyncsState) {
    state.isFetchingPeriodicRates = true;
    state.fetchPeriodicRatesError = null;
    state.syncPeriodicRates = initialState.syncPeriodicRates;
}

function handleFetchRunningRequest(state: SyncsState) {
    state.isFetchingRunning = true;
    state.fetchRunningError = null;
}

function handleFetchFailure(state: SyncsState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.fetchError = action.payload;
}

function handleFetchRatesFailure(state: SyncsState, action: PayloadAction<string>) {
    state.isFetchingRates = false;
    state.fetchRatesError = action.payload;
}

function handleFetchPeriodicRatesFailure(state: SyncsState, action: PayloadAction<string>) {
    state.isFetchingPeriodicRates = false;
    state.fetchPeriodicRatesError = action.payload;
}

function handleFetchRunningFailure(state: SyncsState, action: PayloadAction<string>) {
    state.isFetchingRunning = false;
    state.fetchRunningError = action.payload;
}

function handleFetchAllSuccess(state: SyncsState, action: PayloadAction<Sync[]>) {
    state.isFetching = false;
    state.syncs = convertArrToObj(action.payload);
}

function handleFetchRunningSuccess(state: SyncsState, action: PayloadAction<Sync[]>) {
    state.isFetchingRunning = false;
    state.syncsRunning = convertArrToObj(action.payload);
}

function handleFetchSingleSuccess(state: SyncsState, action: PayloadAction<Sync>) {
    const { sync } = action.payload;
    state.isFetching = false;
    state.syncs[sync.id] = action.payload;
}

function handleFetchSearchSuccess(state: SyncsState, action: PayloadAction<SyncSearchResult[]>) {
    state.isFetching = false;
    state.searchResults = action.payload;
}

function handleFetchRatesSuccess(state: SyncsState, action: PayloadAction<SyncRate[]>) {
    state.isFetchingRates = false;
    state.syncRates = action.payload;
}

function handleFetchPeriodicRatesSuccess(
    state: SyncsState,
    action: PayloadAction<SyncPeriodicRate>,
) {
    state.isFetchingPeriodicRates = false;
    state.syncPeriodicRates = action.payload;
}
