import { useEffect } from 'react';

export const useEventListener = (
    target: Window | Document | HTMLElement,
    event: keyof DocumentEventMap,
    listener: EventListener,
) => {
    useEffect(() => {
        target.addEventListener(event, listener);
        return () => target.removeEventListener(event, listener);
    }, [event, listener, target]);
};
