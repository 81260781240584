import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { DotMailerContactsResponse } from '@customTypes/shared/DotMailerContact';

export const fetchPendingDotMailerContactsRequest = createAction(
    'fetchPendingDotMailerContactsRequest',
);
export const fetchPendingDotMailerContactsSuccess = createAction<DotMailerContactsResponse>(
    'fetchPendingDotMailerContactsSuccess',
);
export const fetchPendingDotMailerContactsFailure = createAction(
    'fetchPendingDotMailerContactsFailure',
);

export const fetchPendingDotMailerContacts = (
    page: number,
    pageSize: number,
    searchTerm: string,
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchPendingDotMailerContactsRequest());
    try {
        const { data }: Response = await api.get(
            `dotmailerContacts/pending?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}`,
        );

        dispatch(fetchPendingDotMailerContactsSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchPendingDotMailerContactsFailure, e);
    }
};

interface Response {
    data: DotMailerContactsResponse;
}
