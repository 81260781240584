import { useDispatch, useSelector } from 'react-redux';

import { syncImmerseUKContact } from '@actions/immerseUKContacts';
import { getImmerseUKContactsIsSyncing } from '@selectors/immerseUKContacts';
import { StringNumberType } from '@customTypes/shared/Generics';

const useSingleImmerseUKContactSync = (id: StringNumberType): ReturnProps => {
    const dispatch = useDispatch();
    const isSyncing = useSelector(getImmerseUKContactsIsSyncing);

    const handleSync = () => {
        dispatch(syncImmerseUKContact(id));
    };

    return { isSyncing, handleSync };
};

type HandleSync = () => void;

interface ReturnProps {
    isSyncing: boolean;
    handleSync: HandleSync;
}

export default useSingleImmerseUKContactSync;
