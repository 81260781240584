import { RootState } from '@reducers/index';
import {
    CventContact,
    CventContactConflict,
    CventContactLog,
} from '@customTypes/shared/CventContact';

export const getCventContacts = (state: RootState): Record<number, CventContact> =>
    state.cventContactsReducer.contacts;
export const getCventPendingContacts = (state: RootState): Record<number, CventContact> =>
    state.cventContactsReducer.pendingContacts;
export const getCventContact = (state: RootState, id: number): CventContact =>
    state.cventContactsReducer.contacts[id];
export const getCventContactsIsFetching = (state: RootState): boolean =>
    state.cventContactsReducer.isFetching;
export const getCventContactsFetchError = (state: RootState): string | null =>
    state.cventContactsReducer.fetchError;
export const getCventContactsIsPosting = (state: RootState): boolean =>
    state.cventContactsReducer.isPosting;
export const getCventContactsPostError = (state: RootState): string | null =>
    state.cventContactsReducer.postError;
export const getCventContactsPostSuccess = (state: RootState): boolean =>
    state.cventContactsReducer.postSuccess;

export const getCventContactsCurrentPage = (state: RootState): number =>
    state.cventContactsReducer.currentPage;
export const getCventContactsPageSize = (state: RootState): number =>
    state.cventContactsReducer.pageSize;
export const getCventContactsTotalPages = (state: RootState): number | null =>
    state.cventContactsReducer.totalPages;
export const getCventContactsTotalCount = (state: RootState): number | null =>
    state.cventContactsReducer.totalCount;

export const getCventContactLogs = (state: RootState): Record<number, CventContactLog> =>
    state.cventContactsReducer.logs;

export const getCventContactConflicts = (state: RootState): Record<number, CventContactConflict> =>
    state.cventContactsReducer.conflicts;
