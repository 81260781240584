import React from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { SyncSearchResult } from '@customTypes/shared/Sync';
import { Column } from '@customTypes/table';

import { getSyncSearchResults, getSyncsIsFetching, getSyncsUserDidSearch } from '@selectors/syncs';

import { syncTypeNames, externalAPIMethodNames } from '@constants/enums/syncEnums';

import Table from '@components/table/Table';
import ButtonRow from '@components/button/ButtonRow';
import LinkButton from '@components/button/LinkButton';
import StatusBadge from '@components/table/StatusBadge';

const SyncListTable: React.FC = () => {
    const syncSearchResults = useSelector(getSyncSearchResults);
    const isFetching = useSelector(getSyncsIsFetching);
    const userDidSearch = useSelector(getSyncsUserDidSearch);

    const columns: Column<SyncSearchResult>[] = [
        {
            key: 1,
            heading: 'Record Updated',
            getValue: row => `${dayjs(row.recordUpdated).format('DD/MM/YYYY hh:mm')}`,
            searchable: false,
        },
        {
            key: 2,
            heading: 'Sync Responsible',
            getValue: ({ sync }) => `${syncTypeNames[sync]}`,
            searchable: false,
        },
        {
            key: 3,
            heading: 'Type',
            getValue: ({ apiMethod }) => `${externalAPIMethodNames[apiMethod] || apiMethod}`,
            searchable: false,
        },
        {
            key: 4,
            heading: 'Status',
            getValue: ({ isSuccess }) => (
                <StatusBadge
                    color={isSuccess ? 'green' : 'red'}
                    icon={isSuccess ? 'check' : 'times'}
                >
                    {isSuccess ? 'Successful' : 'Errored'}
                </StatusBadge>
            ),
            searchable: false,
        },
        {
            key: 5,
            heading: '',
            getValue: row => (
                <ButtonRow alignment="right">
                    <LinkButton color="grey" href={`/data-search/${row.dataFlowID}`}>
                        View
                    </LinkButton>
                </ButtonRow>
            ),
            searchable: false,
        },
    ];

    return (
        <Table
            columns={columns}
            rows={syncSearchResults}
            isLoading={isFetching}
            noDataMessage={
                !userDidSearch ? 'Use Search form above to display data' : 'No results found'
            }
        />
    );
};

export default SyncListTable;
