import React, { useState } from 'react';
import dayjs from 'dayjs';

import { SyncRate } from '@customTypes/shared/Sync';
import { convertEnumToSelectOptions, convertUKDateToDateFormat, isEmpty } from '@utils/generic';

import useDashboardWeeklyRatesFetch from './hooks/useDashboardWeeklyRatesFetch';

import DashboardRateLimitsTable from './DashboardRateLimitsTable';
import DashboardRateLimitsChart from './DashboardRateLimitsChart';
import DataCheck from '@components/generic/dataCheck/DataCheck';
import useWindowDimensions from '@hooks/useWindowDimensions';
import mediaQueries from '@constants/mediaQueries';
import useForm from '@hooks/useForm';
import { periodicSummaryTypeNames, periodicSummaryTypeValues } from '@constants/enums/syncEnums';
import MiniSelect from '@components/form/MiniSelect';

const DashboardRateLimits: React.FC<RateLimitProps> = ({ rates }) => {
    const [form, handleChange] = useForm({
        type: periodicSummaryTypeValues.weekly,
    });

    const [selectedAPIRate, setSelectedAPIRate] = useState<number>(0);
    const selectedAPI = rates[selectedAPIRate].api;
    const { isFetching, error, periodicRates } = useDashboardWeeklyRatesFetch(
        selectedAPI,
        form.type,
    );
    const { width } = useWindowDimensions();
    const isTablet = width && width <= mediaQueries.lsTablet;

    return (
        <div className="flex-row">
            <div style={{ width: isTablet ? '350px' : '400px' }}>
                <div className="chart-heading flex-row justify-between align-center">
                    <p>{getTitle()}</p>
                    <MiniSelect
                        name="type"
                        options={convertEnumToSelectOptions(periodicSummaryTypeNames)}
                        value={form.type}
                        onChange={handleChange}
                    />
                </div>

                <DataCheck
                    isEmpty={isEmpty(periodicRates.api)}
                    isFetching={isFetching}
                    error={error}
                >
                    <DashboardRateLimitsChart
                        values={
                            form.type === periodicSummaryTypeValues.daily ? getTimes() : getDates()
                        }
                    />
                </DataCheck>
            </div>
            <div className="flex">
                <DashboardRateLimitsTable
                    rates={rates}
                    selected={selectedAPIRate}
                    setSelected={isFetching ? () => {} : setSelectedAPIRate}
                />
            </div>
        </div>
    );

    function getDates(): PeriodicDatesReturnProps[] {
        const dateKeys = Object.keys(periodicRates.callsByDate).sort(
            (a, b) =>
                convertUKDateToDateFormat(a).getTime() - convertUKDateToDateFormat(b).getTime(),
        );

        const formatted = dateKeys.map(key => {
            return {
                date: dayjs(convertUKDateToDateFormat(key)).format(
                    form.type === periodicSummaryTypeValues.monthly ? 'DD MMM' : 'ddd',
                ),
                value: periodicRates.callsByDate[key],
            };
        });

        return formatted;
    }

    function getTimes(): PeriodicDatesReturnProps[] {
        const timeKeys = Object.keys(periodicRates.callsByDate).sort((a, b) => (b > a ? -1 : 1));

        const formatted = timeKeys.map(key => {
            return {
                date: key,
                value: periodicRates.callsByDate[key],
            };
        });

        return formatted;
    }

    function getTitle(): string {
        let title = '';

        switch (form.type) {
            case periodicSummaryTypeValues.daily:
                title = 'Past day';
                break;
            case periodicSummaryTypeValues.weekly:
                title = 'Past week';
                break;
            case periodicSummaryTypeValues.monthly:
                title = 'Past month';
                break;
            default:
                title = '';
        }

        return title;
    }
};

interface RateLimitProps {
    rates: SyncRate[];
}

export interface PeriodicDatesReturnProps {
    date: string;
    value: number;
}

export default DashboardRateLimits;
