import { Switch, Route, useRouteMatch } from 'react-router-dom';

import AdminContainer from '@pages/admins/admin/AdminContainer';
import Admins from '@pages/admins/admins/Admins';

const AdminsRoutes: React.FC = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <Admins />
            </Route>
            <Route exact path={`${path}/create`}>
                <Admins showCreateModal />
            </Route>
            <Route exact path={`${path}/:id`}>
                <AdminContainer />
            </Route>
            <Route exact path={`${path}/:id/:action`}>
                <AdminContainer />
            </Route>
        </Switch>
    );
};

export default AdminsRoutes;
