import { fetchSingleDotMailerContact } from '@actions/dotMailerContacts';
import { StringNumberType } from '@customTypes/shared/Generics';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useSingleDotMailerContactFetch = (id: StringNumberType): void => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchSingleDotMailerContact(id));
    }, [dispatch, id]);
};

export default useSingleDotMailerContactFetch;
