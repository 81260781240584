import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

export const deletePendingChLeadRequest = createAction('deletePendingChLeadRequest');
export const deletePendingChLeadSuccess = createAction<number>('deletePendingChLeadSuccess');
export const deletePendingChLeadFailure = createAction('deletePendingChLeadFailure');

export const deletePendingChLeadResolve = (id: number) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(deletePendingChLeadRequest());
    try {
        await api.delete(`leadOrganisations/chLead/${id}`);
        dispatch(deletePendingChLeadSuccess(id));
    } catch (e) {
        handleApiErrors(dispatch, deletePendingChLeadFailure, e);
    }
};
