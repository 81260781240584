import { useLocation } from 'react-router';

import useIsLoggedIn from './useIsLoggedIn';

const useHideOnAuth = (): boolean => {
    const location = useLocation();
    const path = location.pathname.toLowerCase();
    const isLoggedIn = useIsLoggedIn();

    return path.startsWith('/auth') || !isLoggedIn;
};

export default useHideOnAuth;
