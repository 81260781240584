import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { ContactUpdateConflictPostBody } from '@customTypes/shared/Contact';

export const updateCventConflictRequest = createAction('updateCventConflictRequest');
export const updateCventConflictSuccess = createAction<number>('updateCventConflictSuccess');
export const updateCventConflictFailure = createAction('updateCventConflictFailure');

export const updateCventConflict = (postBody: ContactUpdateConflictPostBody) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(updateCventConflictRequest());
    try {
        await api.post('cventContacts/resolve', postBody);
        dispatch(updateCventConflictSuccess(postBody.id));
    } catch (e) {
        handleApiErrors(dispatch, updateCventConflictFailure, e);
    }
};
