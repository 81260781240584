import React from 'react';
import dayjs from 'dayjs';

import { Sync } from '@customTypes/shared/Sync';
import { Column } from '@customTypes/table';

import Table from '@components/table/Table';
import StatusBadge from '@components/table/StatusBadge';
import { syncStatusColors, syncStatusIcons, syncStatusNames } from '@constants/enums/syncEnums';
import { splitOnUpperCase } from '@utils/generic';
declare module 'dayjs' {
    interface Dayjs {
        fromNow(withoutSuffix?: boolean): string;
    }
}

const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);

const DashboardRunningSyncs: React.FC<RunningSyncsProps> = ({ running }) => {
    return <Table columns={columns} rows={Object.values(running)} excludePagination />;
};

const columns: Column<SyncExtension>[] = [
    {
        key: 1,
        heading: 'Type',
        getValue: row => splitOnUpperCase(row.sync.name),
    },
    {
        key: 2,
        heading: 'Started',
        getValue: row => {
            const lastRun = row.stats.lastRun;

            if (lastRun) {
                return dayjs(lastRun).fromNow();
            }

            return '--';
        },
    },
    {
        key: 3,
        heading: 'Status',
        getValue: row => {
            const status = row.stats.status;

            if (status || status === 0) {
                return (
                    <StatusBadge color={syncStatusColors[status]} icon={syncStatusIcons[status]}>
                        {syncStatusNames[status]}
                    </StatusBadge>
                );
            }

            return '';
        },
    },
];

interface SyncExtension extends Sync {
    id?: number;
}

interface RunningSyncsProps {
    running: Record<number, Sync>;
}

export default DashboardRunningSyncs;
