import React, { useState } from 'react';
import SVGBracket from '@components/arrows/SVGBracket';
import SVGStraightArrow from '@components/arrows/SVGStraightArrow';
import { externalAPINames } from '@constants/enums/apiEnums';
import { Node } from '@customTypes/shared/Flow';
import Tooltip from '@components/modal/Tooltip';

const SingleFlowAPISource: React.FC<Props> = ({ node }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const tooltipStyles = {
        bottom: '30px',
        right: '37px',
    };
    return (
        <div className="flow-api-source flex-row align-center justify-end">
            <div className="flow-api-text-wrapper flex-column justify-center align-center">
                <div className="flow-api-text up">{externalAPINames[node.api]}</div>
            </div>
            <div className="api-bracket-container">
                <SVGBracket />
                <i
                    onMouseEnter={() => !node.isAPISuccess && setShowTooltip(true)}
                    onMouseLeave={() => !node.isAPISuccess && setShowTooltip(false)}
                    style={{ bottom: '-10px', right: '-20px' }}
                    className={`flow-node-icon icon far fa-fw fa-${
                        node.isAPISuccess ? 'check' : 'times'
                    }`}
                >
                    <Tooltip
                        visible={showTooltip}
                        text={node.apiErrorMessage || ''}
                        customStyles={tooltipStyles}
                        flipVertical
                        title="An error occurred while fetching from the API"
                    />
                </i>
            </div>
            <div className="api-arrow-container">
                <SVGStraightArrow horizontalOnly pointerLength={20} pointerWidth={15} />
            </div>
        </div>
    );
};

interface Props {
    node: Node;
}
export default SingleFlowAPISource;
