import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Companies from '@pages/companies/Companies';
import CompanySingle from '@pages/companies/CompanySingle';

const CompaniesRoutes: React.FC = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <Companies />
            </Route>
            <Route exact path={`${path}/:id`}>
                <CompanySingle />
            </Route>
        </Switch>
    );
};

export default CompaniesRoutes;
