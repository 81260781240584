import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { toggleMenuOpen } from '@actions/mobileSettings';

const initialState = {
    menuOpen: null,
};

interface DarkModeState {
    menuOpen: boolean | null;
}

export default createReducer(initialState, {
    [toggleMenuOpen.type]: handleSetMenuOpen,
});

function handleSetMenuOpen(state: DarkModeState, action: PayloadAction<boolean>) {
    state.menuOpen = action.payload;
}
