import { fetchSingleCompany } from '@actions/companies';
import { StringNumberType } from '@customTypes/shared/Generics';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useSingleCompanyFetch = (id: StringNumberType): void => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchSingleCompany(id));
    }, [dispatch, id]);
};

export default useSingleCompanyFetch;
