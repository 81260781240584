import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import {
    fetchSingleFlowRequest,
    fetchSingleFlowSuccess,
    fetchSingleFlowFailure,
} from '@actions/flows';

import { Flow } from '@customTypes/shared/Flow';

interface FlowsState {
    isFetching: boolean;
    fetchError: string | null;
    flow: Flow;
}

const initialState: FlowsState = {
    isFetching: false,
    fetchError: null,
    flow: {
        nodes: [],
        startTime: '',
        endTime: '',
        dataFlowID: 0,
        recordUpdated: '',
        sync: 0,
        apiMethod: 0,
        isSuccess: false,
    },
};

export default createReducer(initialState, {
    [fetchSingleFlowRequest.type]: handleFetchRequest,
    [fetchSingleFlowFailure.type]: handleFetchFailure,
    [fetchSingleFlowSuccess.type]: handleFetchSingleSuccess,
});

function handleFetchRequest(state: FlowsState) {
    state.isFetching = true;
    state.fetchError = null;
}

function handleFetchFailure(state: FlowsState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.fetchError = action.payload;
}

function handleFetchSingleSuccess(state: FlowsState, action: PayloadAction<Flow>) {
    state.isFetching = false;
    state.flow = action.payload;
}
