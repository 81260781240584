import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

export const deletePendingEntryRequest = createAction('deletePendingEntryRequest');
export const deletePendingEntrySuccess = createAction<number>('deletePendingEntrySuccess');
export const deletePendingEntryFailure = createAction('deletePendingEntryFailure');

export const deletePendingEntryResolve = (id: number) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(deletePendingEntryRequest());
    try {
        await api.delete(`companies/pending/${id}`);
        dispatch(deletePendingEntrySuccess(id));
    } catch (e) {
        handleApiErrors(dispatch, deletePendingEntryFailure, e);
    }
};
