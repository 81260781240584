import React from 'react';

import {
    DotMailerConflictFormProps,
    DotMailerContact,
    DotMailerContactConflict,
} from '@customTypes/shared/DotMailerContact';

import useForm from '@hooks/useForm';
import useSingleDotMailerUpdateConflict from './_hooks/useSingleDotMailerUpdateConflict';
import useSingleDotMailerSetConflictOptions from './_hooks/useSingleDotMailerSetConflictOptions';

import FormRow from '@components/form/FormRow';
import Radio from '@components/form/Radio';
import Form from '@components/form/Form';

const DotMailerContactSingleConflict: React.FC<ConflictProps> = ({
    contact,
    conflict,
    conflict: { dmFirstname, wbFirstname, dmLastname, wbLastname },
}) => {
    const [form, handleChange] = useForm<DotMailerConflictFormProps>({
        firstName: dmFirstname ? dmFirstname : wbFirstname ? wbFirstname : contact.firstName,
        lastName: dmLastname ? dmLastname : wbLastname ? wbLastname : contact.lastName,
    });

    const { firstNameOptions, lastNameOptions } = useSingleDotMailerSetConflictOptions(conflict);
    const { isPosting, handleSubmit } = useSingleDotMailerUpdateConflict(
        conflict.id,
        conflict.contactID,
        form,
    );

    return (
        <Form onSubmit={handleSubmit} isPosting={isPosting} omitCancel>
            <FormRow>
                {dmFirstname || wbFirstname ? (
                    <Radio
                        label="First Name"
                        name="firstName"
                        value={form.firstName}
                        options={firstNameOptions}
                        onChange={handleChange}
                    />
                ) : (
                    <></>
                )}

                {dmLastname || wbLastname ? (
                    <Radio
                        label="Last Name"
                        name="lastName"
                        value={form.lastName}
                        options={lastNameOptions}
                        onChange={handleChange}
                    />
                ) : (
                    <></>
                )}
            </FormRow>
        </Form>
    );
};
interface ConflictProps {
    contact: DotMailerContact;
    conflict: DotMailerContactConflict;
}

export default DotMailerContactSingleConflict;
