import { DotMailerContactConflict } from '@customTypes/shared/DotMailerContact';
import { useEffect, useState } from 'react';

const useSingleDotMailerSetConflictOptions = (conflict: DotMailerContactConflict): ReturnProps => {
    const [firstNameOptions, setFirstNameOptions] = useState<RadioOptionsProps[]>([]);
    const [lastNameOptions, setLastNameOptions] = useState<RadioOptionsProps[]>([]);

    useEffect(() => {
        setFirstNameOptions([
            {
                label: conflict.dmFirstname,
                value: conflict.dmFirstname,
            },
            {
                label: conflict.wbFirstname,
                value: conflict.wbFirstname,
            },
        ]);

        setLastNameOptions([
            {
                label: conflict.dmLastname,
                value: conflict.dmLastname,
            },
            {
                label: conflict.wbLastname,
                value: conflict.wbLastname,
            },
        ]);
    }, [conflict.dmFirstname, conflict.dmLastname, conflict.wbFirstname, conflict.wbLastname]);

    return { firstNameOptions, lastNameOptions };
};

interface RadioOptionsProps {
    label: string;
    value: string;
}

interface ReturnProps {
    firstNameOptions: RadioOptionsProps[];
    lastNameOptions: RadioOptionsProps[];
}

export default useSingleDotMailerSetConflictOptions;
