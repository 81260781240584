import { RootState } from '@reducers/index';
import {
    LeadOrganisation,
    PendingChLead,
    LogModel,
    PendingLeadEntry,
} from '@customTypes/shared/LeadOrganisation';

export const getLeadOrganisationsIsFetching = (state: RootState): boolean =>
    state.leadOrganisationsReducer.isFetching;
export const getLeadOrganisationsFetchError = (state: RootState): string | null =>
    state.leadOrganisationsReducer.fetchError;
export const getLeadOrganisationsIsPosting = (state: RootState): boolean =>
    state.leadOrganisationsReducer.isPosting;
export const getLeadOrganisationsPostError = (state: RootState): string | null =>
    state.leadOrganisationsReducer.postError;
export const getLeadOrganisationsPostSuccess = (state: RootState): boolean =>
    state.leadOrganisationsReducer.postSuccess;
export const getLeadOrganisationsIsDeleting = (state: RootState): boolean =>
    state.leadOrganisationsReducer.isDeleting;
export const getLeadOrganisationsDeleteError = (state: RootState): string | null =>
    state.leadOrganisationsReducer.deleteError;
export const getLeadOrganisationsIsSyncing = (state: RootState): boolean =>
    state.leadOrganisationsReducer.isSyncing;
export const getLeadOrganisationsSyncError = (state: RootState): string | null =>
    state.leadOrganisationsReducer.syncError;

export const getLeadOrganisations = (state: RootState): Record<number, LeadOrganisation> =>
    state.leadOrganisationsReducer.leadOrganisations;
export const getLeadOrganisationSingle = (state: RootState, id: number): LeadOrganisation =>
    state.leadOrganisationsReducer.leadOrganisations[id];
export const getLeadOrganisationPendingChLeads = (
    state: RootState,
): Record<number, PendingChLead> => state.leadOrganisationsReducer.pendingChLeads;
export const getLeadOrganisationPendingLeadEntries = (
    state: RootState,
): Record<number, PendingLeadEntry> => state.leadOrganisationsReducer.pendingLeadEntries;
export const getLeadOrganisationLogModels = (state: RootState): Record<number, LogModel> =>
    state.leadOrganisationsReducer.logModels;
export const getLeadOrganisationsCurrentPage = (state: RootState): number =>
    state.leadOrganisationsReducer.currentPage;
export const getLeadOrganisationsPageSize = (state: RootState): number =>
    state.leadOrganisationsReducer.pageSize;
export const getLeadOrganisationsTotalPages = (state: RootState): number | null =>
    state.leadOrganisationsReducer.totalPages;
export const getLeadOrganisationsTotalCount = (state: RootState): number | null =>
    state.leadOrganisationsReducer.totalCount;
