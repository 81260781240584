import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

export const deletePendingLeadEntryRequest = createAction('deletePendingLeadEntryRequest');
export const deletePendingLeadEntrySuccess = createAction<number>('deletePendingLeadEntrySuccess');
export const deletePendingLeadEntryFailure = createAction('deletePendingLeadEntryFailure');

export const deletePendingLeadEntryResolve = (id: number) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(deletePendingLeadEntryRequest());
    try {
        await api.delete(`leadOrganisations/entry/${id}`);
        dispatch(deletePendingLeadEntrySuccess(id));
    } catch (e) {
        handleApiErrors(dispatch, deletePendingLeadEntryFailure, e);
    }
};
