import { useDispatch, useSelector } from 'react-redux';

import { updateDotMailerConflict } from '@actions/dotMailerContacts';
import { getDotMailerContactsIsPosting } from '@selectors/dotMailerContacts';
import { DotMailerConflictFormProps } from '@customTypes/shared/DotMailerContact';

const useSingleDotMailerUpdateConflict = (
    id: number,
    contactID: number,
    postBody: DotMailerConflictFormProps,
): ReturnProps => {
    const dispatch = useDispatch();
    const isPosting = useSelector(getDotMailerContactsIsPosting);

    const handleSubmit = () => {
        dispatch(updateDotMailerConflict(id, contactID, postBody));
    };

    return { isPosting, handleSubmit };
};

interface ReturnProps {
    isPosting: boolean;
    handleSubmit: () => void;
}

export default useSingleDotMailerUpdateConflict;
