import SVGStraightArrow from '@components/arrows/SVGStraightArrow';
import React from 'react';

const Arrows: React.FC<ArrowProps> = ({ nFrom, nTo }) => {
    if (!nFrom || !nTo) return <></>;
    const n = [];
    for (let i = 0; i < (nFrom > nTo ? nFrom : nTo); i++) n.push(null);
    if (nFrom === nTo) {
        return (
            <>
                {' '}
                <div className="node-arrow-container" />
                {n.map((_, i) => (
                    <div key={i} className="node-arrow-container">
                        <SVGStraightArrow horizontalOnly />
                    </div>
                ))}
                <div className="node-arrow-container" />
            </>
        );
    } else if (nFrom > nTo) {
        const isOdd = !!(nFrom % 2);
        return (
            <>
                <div className="node-arrow-container" />
                {n.map((_, i) => {
                    const props: SingleArrowProps = {};
                    if (isOdd && i === Math.floor(nFrom / 2)) props.horizontalOnly = true;
                    if (i > nFrom / 2 - 1) props.up = true;
                    return (
                        <div key={i} className="node-arrow-container">
                            <SVGStraightArrow {...props} />
                        </div>
                    );
                })}
                <div className="node-arrow-container" />
            </>
        );
    } else if (nTo > nFrom) {
        const isOdd = !!(nTo % 2);
        return (
            <>
                <div className="node-arrow-container" />
                {n.map((_, i) => {
                    const props: SingleArrowProps = {};
                    if (isOdd && i === Math.floor(nFrom / 2)) props.horizontalOnly = true;
                    if (i < nTo / 2 - 1) props.up = true;
                    return (
                        <div key={i} className="node-arrow-container">
                            <SVGStraightArrow {...props} />
                        </div>
                    );
                })}
                <div className="node-arrow-container" />
            </>
        );
    } else return <></>;
};

interface ArrowProps {
    nFrom: number;
    nTo: number;
}

interface SingleArrowProps {
    horizontalOnly?: boolean;
    verticalOnly?: boolean;
    up?: boolean;
    left?: boolean;
}

export default Arrows;
