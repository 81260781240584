import React from 'react';
import { useSelector } from 'react-redux';
import { Line, LineChart, Tooltip, XAxis } from 'recharts';

import { getDarkMode } from '@selectors/darkMode';
import { PeriodicDatesReturnProps } from './DashboardRateLimits';
import useWindowDimensions from '@hooks/useWindowDimensions';
import mediaQueries from '@constants/mediaQueries';

const DashboardRateLimitsChart: React.FC<RateLimitsChartProps> = ({ values }) => {
    const isDarkMode = useSelector(getDarkMode);
    const { width } = useWindowDimensions();
    const isTablet = width && width <= mediaQueries.lsTablet;

    return (
        <LineChart
            width={isTablet ? 325 : 375}
            height={325}
            data={values}
            margin={{ top: 5, left: 15, right: 15 }}
        >
            <XAxis dataKey="date" stroke={isDarkMode ? '#ffffff' : '#666'} />
            <Tooltip separator=": " formatter={(value: number) => [value, 'Calls']} />
            <Line type="monotone" dataKey="value" stroke="#2775FF" strokeWidth={2} />
        </LineChart>
    );
};
interface RateLimitsChartProps {
    values: PeriodicDatesReturnProps[];
}

export default DashboardRateLimitsChart;
