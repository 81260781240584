import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StringNumberType } from '@customTypes/shared/Generics';

import { fetchSingleCventContact } from '@actions/cventContacts';
import { getCventContactsPostSuccess } from '@selectors/cventContacts';

import usePrevious from '@hooks/usePrevious';

const useSingleCventContactFetch = (id: StringNumberType): void => {
    const dispatch = useDispatch();
    const postSuccess = useSelector(getCventContactsPostSuccess);
    const prevProps = usePrevious({ postSuccess });

    useEffect(() => {
        dispatch(fetchSingleCventContact(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (postSuccess && !prevProps.postSuccess) {
            dispatch(fetchSingleCventContact(id, true));
        }
    }, [dispatch, id, postSuccess, prevProps.postSuccess]);
};

export default useSingleCventContactFetch;
