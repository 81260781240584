import { fetchSingleSync } from '@actions/syncs';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useSingleSyncFetch = (id: number): void => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchSingleSync(id));
    }, [dispatch, id]);
};

export default useSingleSyncFetch;
