import React from 'react';
import { useDispatch } from 'react-redux';

import { setMenuOpen } from '@actions/mobileSettings';

import mediaQueries from '@constants/mediaQueries';

import useWindowDimensions from '@hooks/useWindowDimensions';

import Title from './Title';

const PageTitle: React.FC<TitleProps> = ({
    children,
    alignment = '',
    className = '',
    ...props
}) => {
    const dispatch = useDispatch();
    const { width } = useWindowDimensions();
    const showIcon = width && width <= mediaQueries.smDesktop;

    return (
        <div className="page-title-container flex-row align-start">
            {showIcon && (
                <button className="menu-icon" onClick={() => dispatch(setMenuOpen(true))}>
                    <i className="fal fa-bars fa-fw"></i>
                </button>
            )}

            <div className="flex">
                <Title alignment={alignment} {...props}>
                    {children}
                </Title>
            </div>
        </div>
    );
};

interface TitleProps {
    alignment?: string;
    className?: string;
}

export default PageTitle;
