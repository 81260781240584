import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { SyncRate } from '@customTypes/shared/Sync';

export const fetchSyncsRatesRequest = createAction('fetchSyncsRatesRequest');
export const fetchSyncsRatesSuccess = createAction<SyncRate[]>('fetchSyncsRatesSuccess');
export const fetchSyncsRatesFailure = createAction('fetchSyncsRatesFailure');

export const fetchSyncsRates = () => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchSyncsRatesRequest());
    try {
        const { data }: Response = await api.get(`syncs/rates`);
        dispatch(fetchSyncsRatesSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSyncsRatesFailure, e);
    }
};

interface Response {
    data: SyncRate[];
}
