import { useDispatch, useSelector } from 'react-redux';

import { updateImmerseUKConflict } from '@actions/immerseUKContacts';
import { ContactUpdateConflictPostBody } from '@customTypes/shared/Contact';
import {
    getImmerseUKContactsIsPosting,
    getImmerseUKContactsPostError,
} from '@selectors/immerseUKContacts';

const useSingleImmerseUKContactUpdateConflict = (
    postBody: ContactUpdateConflictPostBody,
): ReturnProps => {
    const dispatch = useDispatch();
    const isPosting = useSelector(getImmerseUKContactsIsPosting);
    const postError = useSelector(getImmerseUKContactsPostError);

    return { isPosting, postError, handleSubmit };

    function handleSubmit(): void {
        dispatch(updateImmerseUKConflict(postBody));
    }
};

interface ReturnProps {
    isPosting: boolean;
    postError: string | null;
    handleSubmit: () => void;
}

export default useSingleImmerseUKContactUpdateConflict;
