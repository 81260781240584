import { ImmerseUKSingleContactResponse } from '@customTypes/shared/ImmerseUKContact';
import { StringNumberType } from '@customTypes/shared/Generics';
import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

export const syncImmerseUKContactRequest = createAction('syncImmerseUKContactRequest');
export const syncImmerseUKContactSuccess = createAction<ImmerseUKSingleContactResponse>(
    'syncImmerseUKContactSuccess',
);
export const syncImmerseUKContactFailure = createAction('syncImmerseUKContactFailure');

export const syncImmerseUKContact = (id: StringNumberType) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(syncImmerseUKContactRequest());
    try {
        const { data }: Response = await api.post(`immerseContacts/${id}/sync`, null);
        dispatch(syncImmerseUKContactSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, syncImmerseUKContactFailure, e);
    }
};

interface Response {
    data: ImmerseUKSingleContactResponse;
}
