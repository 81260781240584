import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import {
    syncStatusNames,
    syncStatusIcons,
    syncStatusColors,
    syncStatusValues,
} from '@constants/enums/syncEnums';
import { Sync } from '@customTypes/shared/Sync';
import { Column } from '@customTypes/table';
import { formatTimeFromSeconds, splitOnUpperCase } from '@utils/generic';

import { fetchSyncs } from '@actions/syncs';
import { getSyncs, getSyncsIsFetching } from '@selectors/syncs';

import StatusBadge from '@components/table/StatusBadge';
import LinkButton from '@components/button/LinkButton';
import ButtonRow from '@components/button/ButtonRow';
import Table from '@components/table/Table';
import InfoHover from '@components/table/InfoHover';

const SyncListTable: React.FC = () => {
    const dispatch = useDispatch();
    const isFetching = useSelector(getSyncsIsFetching);
    const syncs = useSelector(getSyncs);

    useEffect(() => {
        dispatch(fetchSyncs());
    }, [dispatch]);

    return (
        <Table
            columns={columns}
            rows={Object.values(syncs)}
            isLoading={isFetching}
            excludePagination
        />
    );
};

const columns: Column<Sync>[] = [
    {
        key: 1,
        heading: 'Name',
        getValue: ({ sync: { name, description } }) => (
            <>
                {name ? splitOnUpperCase(name) : '--'}{' '}
                <span>
                    <InfoHover title={splitOnUpperCase(name)} description={description} />
                </span>
            </>
        ),
        searchable: false,
    },
    {
        key: 2,
        heading: 'Last Run',
        getValue: ({ stats: { lastRun } }) =>
            `${lastRun ? dayjs(lastRun).format('DD/MM/YYYY hh:mm') : '--'}`,
        searchable: false,
    },
    {
        key: 3,
        heading: 'Next Run',
        getValue: ({ stats: { nextRun } }) =>
            `${nextRun ? dayjs(nextRun).format('DD/MM/YYYY hh:mm') : '--'}`,
        searchable: false,
    },
    {
        key: 4,
        heading: 'Current Runtime',
        getValue: ({ stats: { lastRun, status } }) => {
            const { running, rateLimited, waitingToRetry } = syncStatusValues;
            const runningSyncs = [running, rateLimited, waitingToRetry];

            if (!lastRun || !status || !runningSyncs.includes(status)) return '--';

            const currentDateTime = dayjs();
            const lastRunDateTime = dayjs(lastRun);
            const seconds = currentDateTime.diff(lastRunDateTime, 'seconds');
            return formatTimeFromSeconds(seconds);
        },
        searchable: false,
    },
    {
        key: 5,
        heading: 'Average Runtime',
        getValue: ({ stats: { averageRunTimeSeconds } }) => {
            if (!averageRunTimeSeconds) return '--';
            return formatTimeFromSeconds(averageRunTimeSeconds);
        },
        searchable: false,
    },
    {
        key: 6,
        heading: 'State',
        getValue: ({ stats: { status, error } }) => {
            if (status === null) return '--';
            return (
                <StatusBadge
                    error={error}
                    status={status}
                    color={syncStatusColors[status]}
                    icon={syncStatusIcons[status]}
                >
                    {syncStatusNames[status]}
                </StatusBadge>
            );
        },
        searchable: false,
    },
    {
        key: 7,
        heading: '',
        getValue: row => (
            <ButtonRow alignment="right">
                <LinkButton color="grey" href={`/syncs/${row.sync.id}`}>
                    View History
                </LinkButton>
            </ButtonRow>
        ),
        searchable: false,
    },
];

export default SyncListTable;
