import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { User, UserPost } from '@customTypes/shared/User';

export const updateUserRequest = createAction('updateUserRequest');
export const updateUserSuccess = createAction<UserPost>('updateUserSuccess');
export const updateUserFailure = createAction('updateUserFailure');

export const updateUser = (id: number, postbody: UserPost) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(updateUserRequest());

    try {
        const { data }: UpdateUserResponse = await api.patch(`users/${id}`, postbody);

        dispatch(updateUserSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, updateUserFailure, e);
    }
};

interface UpdateUserResponse {
    data: User;
}
