import React, { useState } from 'react';

import { ImmerseUKContactConflict } from '@customTypes/shared/ImmerseUKContact';
import { Column } from '@customTypes/table';

import { contactFieldOptionNames, contactProgramTypeValues } from '@constants/enums/contactEnums';

import Table from '@components/table/Table';
import ActionButton from '@components/button/ActionButton';
import ImmerseUKContactUpdateConflictModal from './ImmerseUKContactUpdateConflictModal';

const ImmerseUKContactSingleConflictsTable: React.FC<TableProps> = ({ conflicts, isFetching }) => {
    return (
        <>
            <Table
                columns={columns}
                rows={[...Object.values(conflicts)].sort(
                    (a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime(),
                )}
                isLoading={isFetching}
                noDataMessage="There are no conflicts to display"
            />
        </>
    );
};

const RenderSelection: React.FC<RenderSelectionProps> = ({ id, programType, text, buttonText }) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <div className="table-selection">
                <p>{text}</p>
                <ActionButton color="blue" onClick={() => setShowModal(true)}>
                    {buttonText}
                </ActionButton>
            </div>

            <ImmerseUKContactUpdateConflictModal
                visible={showModal}
                handleClose={() => setShowModal(false)}
                postBody={{ id, programType }}
            />
        </>
    );
};

const columns: Column<ImmerseUKContactConflict>[] = [
    {
        key: 1,
        heading: 'Field',
        getValue: row => contactFieldOptionNames[row.field],
    },
    {
        key: 2,
        heading: 'Immerse UK Entry',
        getValue: row => (
            <RenderSelection
                id={row.id}
                programType={contactProgramTypeValues.immerse}
                text={row.localValue}
                buttonText="Use Immerse UK value"
            />
        ),
    },
    {
        key: 3,
        heading: 'Workbooks Entry',
        getValue: row => (
            <RenderSelection
                id={row.id}
                programType={contactProgramTypeValues.workBooks}
                text={row.wbValue}
                buttonText="Use Workbooks value"
            />
        ),
    },
];

interface RenderSelectionProps {
    id: number;
    programType: contactProgramTypeValues;
    text: string;
    buttonText: string;
}
interface TableProps {
    conflicts: Record<number, ImmerseUKContactConflict>;
    isFetching: boolean;
}

export default ImmerseUKContactSingleConflictsTable;
