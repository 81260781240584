import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { User } from '@customTypes/shared/User';

export const fetchAllUsersRequest = createAction('fetchAllUsersRequest');
export const fetchAllUsersSuccess = createAction<User[]>('fetchAllUsersSuccess');
export const fetchAllUsersFailure = createAction('fetchAllUsersFailure');

export const fetchAllUsers = () => async (
    dispatch: AppDispatch,
    // todo really??
): Promise<void> => {
    dispatch(fetchAllUsersRequest());
    try {
        const { data } = await api.get<User[]>('users');

        dispatch(fetchAllUsersSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchAllUsersFailure, e);
    }
};
