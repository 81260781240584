import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { AdminUser, AdminUserPost } from '@customTypes/shared/AdminUser';

export const updateAdminUserRequest = createAction('updateAdminUserRequest');
export const updateAdminUserSuccess = createAction<AdminUserPost>('updateAdminUserSuccess');
export const updateAdminUserFailure = createAction('updateAdminUserFailure');

export const updateAdminUser = (id: number, postbody: AdminUserPost) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(updateAdminUserRequest());
    try {
        const { data }: UpdateAdminResponse = await api.put(`admins/${id}`, postbody);

        dispatch(updateAdminUserSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, updateAdminUserFailure, e);
    }
};

interface UpdateAdminResponse {
    data: AdminUser;
}
