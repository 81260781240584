import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { useAppSelector } from '../../../redux/store';

import { fetchSingleAdminUser } from '@actions/adminUsers/fetchSingleAdminUser';
import { getAdminUser, getAdminUsersPostError } from '@selectors/adminUsers';

import Admin from './Admin';

import UpdateAdminPasswordModal from './UpdateAdminPasswordModal';
import UpdateAdminModal from './UpdateAdminModal';
import DeleteAdminModal from './DeleteAdminModal';
import usePrevious from '@hooks/usePrevious';
import ErrorModal from '@components/modal/ErrorModal';

const AdminContainer: React.FC = () => {
    const dispatch = useDispatch();
    const { id, action } = useParams<{ id: string; action?: string }>();
    const history = useHistory();
    const [showError, setShowError] = useState(false);
    const postError = useSelector(getAdminUsersPostError);
    const prevProps = usePrevious({ postError });

    useEffect(() => {
        dispatch(fetchSingleAdminUser(+id));
    }, [id, dispatch]);

    useEffect(() => {
        if (postError && !prevProps.postError) {
            setShowError(true);
            history.replace(`/admins/${id}`);
        }
    }, [postError, prevProps.postError, history, id]);

    const user = useAppSelector(state => getAdminUser(state, parseInt(id)));

    return (
        <>
            <Admin user={user} />
            {action === 'delete' && user && <DeleteAdminModal user={user} />}
            {!!user && action === 'edit-password' && user && (
                <UpdateAdminPasswordModal user={user} />
            )}
            {!!user && action === 'edit' && user && <UpdateAdminModal user={user} />}
            {showError && (
                <ErrorModal
                    description={postError || undefined}
                    closeModal={() => setShowError(false)}
                />
            )}
        </>
    );
};

export default AdminContainer;
