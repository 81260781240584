const ContentItem: React.FC<ContentItemProps> = ({ children, label }) => (
    <div className="content-item">
        <label>{label}</label>
        {children}
    </div>
);

interface ContentItemProps {
    label: string;
}

export default ContentItem;
