import React from 'react';

import Title from '@components/typography/Title';
import Description from '@components/typography/Description';

const PageNotFound: React.FC = () => (
    <div className="page-not-found flex-column justify-center align-center">
        <Title>404</Title>
        <Description>The page you have attempted to access does not exist</Description>
    </div>
);

export default PageNotFound;
