import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { DotMailerSingleContactResponse } from '@customTypes/shared/DotMailerContact';
import { StringNumberType } from '@customTypes/shared/Generics';

export const fetchSingleDotMailerContactRequest = createAction<boolean>(
    'fetchSingleDotMailerContactRequest',
);
export const fetchSingleDotMailerContactSuccess = createAction<DotMailerSingleContactResponse>(
    'fetchSingleDotMailerContactSuccess',
);
export const fetchSingleDotMailerContactFailure = createAction(
    'fetchSingleDotMailerContactFailure',
);

export const fetchSingleDotMailerContact = (id: StringNumberType, isRefetch = false) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(fetchSingleDotMailerContactRequest(isRefetch));
    try {
        const { data }: Response = await api.get(`dotmailerContacts/${id}`);

        dispatch(fetchSingleDotMailerContactSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSingleDotMailerContactFailure, e);
    }
};

interface Response {
    data: DotMailerSingleContactResponse;
}
