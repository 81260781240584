import KTNLogo from '@content/logos/ktn.png';
import KTNLogoWhite from '@content/logos/ktn-white.png';

import Helmet from '@components/generic/helmet/Helmet';
import LoginFormContainer from './LoginFormContainer';
import useDarkMode from '@hooks/useDarkMode';

const Login: React.FC = () => {
    const [darkMode] = useDarkMode();

    return (
        <>
            <Helmet title="Login" />
            <div className="login-form-wrapper flex-column justify-center align-center">
                <img className="auth-logo" alt="KTN" src={darkMode ? KTNLogoWhite : KTNLogo} />

                <div className="login-form">
                    <LoginFormContainer />
                </div>
            </div>
        </>
    );
};

export default Login;
