import { useDispatch, useSelector } from 'react-redux';

import { updateCventConflict } from '@actions/cventContacts';
import { ContactUpdateConflictPostBody } from '@customTypes/shared/Contact';
import { getCventContactsIsPosting, getCventContactsPostError } from '@selectors/cventContacts';

const useSingleCventContactUpdateConflict = (
    postBody: ContactUpdateConflictPostBody,
): ReturnProps => {
    const dispatch = useDispatch();
    const isPosting = useSelector(getCventContactsIsPosting);
    const postError = useSelector(getCventContactsPostError);

    return { isPosting, postError, handleSubmit };

    function handleSubmit(): void {
        dispatch(updateCventConflict(postBody));
    }
};

interface ReturnProps {
    isPosting: boolean;
    postError: string | null;
    handleSubmit: () => void;
}

export default useSingleCventContactUpdateConflict;
