import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { StringNumberType } from '@customTypes/shared/Generics';
import { SingleCompanyResponse } from '@customTypes/shared/Company';

export const postPendingEntryResolveRequest = createAction('postPendingEntryResolveRequest');
export const postPendingEntryResolveSuccess = createAction<SingleCompanyResponse>(
    'postPendingEntryResolveSuccess',
);
export const postPendingEntryResolveFailure = createAction('postPendingEntryResolveFailure');

export const postPendingEntryResolve = (id: StringNumberType) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(postPendingEntryResolveRequest());
    try {
        const { data }: Response = await api.post(`companies/pending/${id}`, {});
        dispatch(postPendingEntryResolveSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, postPendingEntryResolveFailure, e);
    }
};

interface Response {
    data: SingleCompanyResponse;
}
