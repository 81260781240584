import React from 'react';

import { Company } from '@customTypes/shared/Company';

import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import Helmet from '@components/generic/helmet/Helmet';

const CompanySingleDetails: React.FC<CompanyProps> = ({
    company: { name, workbooksID, companyNumber, postcode, sicCodes, beauhurstProfile },
}) => (
    <ContentBlock>
        <Helmet title={name ? name : 'Organisation'} />
        <ContentRow>
            <ContentItem label="Name">
                <p>{!name ? '--' : name}</p>
            </ContentItem>
            <ContentItem label="Company ID">
                <p>{!companyNumber ? '--' : companyNumber}</p>
            </ContentItem>
            <ContentItem label="Workbooks ID">
                <p>{!workbooksID ? '--' : workbooksID}</p>
            </ContentItem>
        </ContentRow>

        <ContentRow>
            <ContentItem label="Postcode">
                <p>{!postcode ? '--' : postcode}</p>
            </ContentItem>
            <ContentItem label="Sic Codes">
                <p>{!sicCodes ? '--' : sicCodes}</p>
            </ContentItem>
            <ContentItem label="Beauhurst profile">
                <p>{!beauhurstProfile ? '--' : beauhurstProfile}</p>
            </ContentItem>
        </ContentRow>
    </ContentBlock>
);

interface CompanyProps {
    company: Company;
}

export default CompanySingleDetails;
