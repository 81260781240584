import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { SingleCompanyResponse } from '@customTypes/shared/Company';
import { StringNumberType } from '@customTypes/shared/Generics';

export const fetchSingleCompanyRequest = createAction('fetchSingleCompanyRequest');
export const fetchSingleCompanySuccess = createAction<SingleCompanyResponse>(
    'fetchSingleCompanySuccess',
);
export const fetchSingleCompanyFailure = createAction('fetchSingleCompanyFailure');

export const fetchSingleCompany = (id: StringNumberType) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(fetchSingleCompanyRequest());
    try {
        const { data }: Response = await api.get(`Companies/${id}`);
        dispatch(fetchSingleCompanySuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSingleCompanyFailure, e);
    }
};

interface Response {
    data: SingleCompanyResponse;
}
