import React from 'react';

import { AdminUser } from '@customTypes/shared/AdminUser';
import { adminsSingleBreadcrumb } from '@constants/breadcrumbItems';

import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import LinkButton from '@components/button/LinkButton';
import ButtonRow from '@components/button/ButtonRow';
import Helmet from '@components/generic/helmet/Helmet';
import Breadcrumb from '@components/breadcrumb/Breadcrumb';
import PageTitle from '@components/typography/PageTitle';

const AdminComponent: React.FC<AdminProps> = ({ user }) => {
    if (!user)
        return (
            <div className="data-block content-block">
                <p>There was an error fetching the information</p>
            </div>
        );

    const { id, firstName, lastName, email } = user;
    return (
        <>
            <Helmet title={`${firstName} ${lastName}`} />

            <Breadcrumb items={adminsSingleBreadcrumb} />

            <PageTitle>
                Admin - {firstName} {lastName}
            </PageTitle>

            <ContentBlock>
                <ContentRow>
                    <ContentItem label="Name">
                        <p>{`${firstName} ${lastName}`}</p>
                    </ContentItem>
                    <ContentItem label="Email">
                        <p>
                            <a href={`mailto:${email}`}>{email}</a>
                        </p>
                    </ContentItem>
                </ContentRow>
            </ContentBlock>

            <ButtonRow alignment="left">
                <LinkButton color="yellow" href={`/admins/${id}/edit`}>
                    Edit
                </LinkButton>
                <LinkButton color="yellow" href={`/admins/${id}/edit-password`}>
                    Edit password
                </LinkButton>
                <LinkButton color="red" href={`/admins/${id}/delete`}>
                    Delete
                </LinkButton>
            </ButtonRow>
        </>
    );
};

interface AdminProps {
    user: AdminUser | undefined;
}

export default AdminComponent;
