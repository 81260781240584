import { Switch, Route, useRouteMatch } from 'react-router-dom';

import DataSearchContainer from '@pages/dataSearch/DataSearchContainer';

const DataSearchRoutes: React.FC = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <DataSearchContainer />
            </Route>
            <Route exact path={`${path}/:id`}>
                <DataSearchContainer />
            </Route>
        </Switch>
    );
};

export default DataSearchRoutes;
