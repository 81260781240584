import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ImmerseUKContact } from '@customTypes/shared/ImmerseUKContact';
import { Column } from '@customTypes/table';

import { fetchImmerseUKContacts } from '@actions/immerseUKContacts';
import {
    getImmerseUKContacts,
    getImmerseUKContactsCurrentPage,
    getImmerseUKContactsIsFetching,
    getImmerseUKContactsPageSize,
    getImmerseUKContactsTotalCount,
    getImmerseUKContactsTotalPages,
} from '@selectors/immerseUKContacts';

import LinkButton from '@components/button/LinkButton';
import TableWithFetch from '@components/tableWithFetch/TableWithFetch';

import ButtonRow from '@components/button/ButtonRow';
import dayjs from 'dayjs';

const ImmerseUKContactsTable: React.FC = () => {
    const dispatch = useDispatch();
    const isFetching = useSelector(getImmerseUKContactsIsFetching);
    const contacts = useSelector(getImmerseUKContacts);
    const currentPage = useSelector(getImmerseUKContactsCurrentPage);
    const pageSize = useSelector(getImmerseUKContactsPageSize);
    const totalPages = useSelector(getImmerseUKContactsTotalPages);
    const totalCount = useSelector(getImmerseUKContactsTotalCount);

    const fetchData = useCallback(
        (page: number, pageSize: number, searchTerm: string) => {
            dispatch(fetchImmerseUKContacts(page, pageSize, searchTerm));
        },
        [dispatch],
    );

    return (
        <TableWithFetch
            columns={columns}
            rows={Object.values(contacts)}
            isLoading={isFetching}
            fetchData={fetchData}
            currentPage={currentPage}
            pageSize={pageSize}
            totalPages={totalPages}
            totalCount={totalCount}
            searchType="immerseUKContacts"
        />
    );
};

const columns: Column<ImmerseUKContact>[] = [
    {
        key: 1,
        heading: 'Name',
        getValue: row =>
            `${row.firstName ? row.firstName : ''} ${row.lastName ? row.lastName : ''}`,
        searchable: true,
    },
    {
        key: 2,
        heading: 'Email address',
        getValue: row => `${row.userEmail}`,
        searchable: true,
    },
    {
        key: 3,
        heading: 'Phone',
        getValue: row => (row.phone ? row.phone : ''),
        searchable: true,
    },
    {
        key: 4,
        heading: 'Twitter',
        getValue: row => (row.twitter ? row.twitter : ''),
        searchable: true,
    },
    {
        key: 5,
        heading: 'Last Updated',
        getValue: row => (row.lastUpdated ? dayjs(row.lastUpdated).format('DD/MM/YYYY') : ''),
        searchable: true,
    },
    {
        key: 6,
        heading: 'Last Synced',
        getValue: row => (row.lastSynced ? dayjs(row.lastSynced).format('DD/MM/YYYY') : ''),
        searchable: true,
    },
    {
        key: 7,
        heading: '',
        getValue: row => (
            <ButtonRow alignment="right">
                <LinkButton color="grey" href={`/immerse-uk-contacts/${row.id}`}>
                    View details
                </LinkButton>
            </ButtonRow>
        ),
        searchable: false,
    },
];

export default ImmerseUKContactsTable;
