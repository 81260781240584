import { useDispatch, useSelector } from 'react-redux';

import { fetchSyncs } from '@actions/syncs';
import { getSyncsIsFetching } from '@selectors/syncs';

import SyncListTable from './SyncScheduleTable';
import Description from '@components/typography/Description';
import Helmet from '@components/generic/helmet/Helmet';
import PageTitle from '@components/typography/PageTitle';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import ActionButton from '@components/button/ActionButton';

const SyncList: React.FC = () => {
    const dispatch = useDispatch();
    const isFetching = useSelector(getSyncsIsFetching);

    return (
        <div className="contacts-wrapper">
            <Helmet title="Sync Schedule" />
            <CreateHeader>
                <PageTitle>Sync Schedule</PageTitle>
                <ActionButton
                    color="green"
                    icon="sync"
                    onClick={() => !isFetching && dispatch(fetchSyncs())}
                    isPosting={isFetching}
                >
                    Refresh
                </ActionButton>
            </CreateHeader>
            <Description>Here is a list of all recent sync logs.</Description>
            <SyncListTable />
        </div>
    );
};

export default SyncList;
