import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setConflictTab } from '@actions/conflictManagement';
import { getConflictManagementCurrentTab } from '@selectors/conflictManagement';
import Tabs from '@components/tabs/Tabs';

import { conflictManagementTabs } from '@constants/tabs';
import Helmet from '@components/generic/helmet/Helmet';
import PageTitle from '@components/typography/PageTitle';

const ConflictManagement: React.FC = () => {
    const dispatch = useDispatch();
    const currentTab = useSelector(getConflictManagementCurrentTab);

    const SpecificRoute = conflictManagementTabs[currentTab].Component;

    return (
        <div className="pending-wrapper">
            <Helmet title="Conflict Management" />
            <PageTitle>Conflict Management</PageTitle>
            <Tabs
                currentTab={currentTab}
                setCurrentTab={handleSetConflictTab}
                tabs={conflictManagementTabs}
            />
            <SpecificRoute />
        </div>
    );

    function handleSetConflictTab(id: string) {
        dispatch(setConflictTab(id));
    }
};

export default ConflictManagement;
