import React from 'react';
import dayjs from 'dayjs';

import { CventContactLog } from '@customTypes/shared/CventContact';
import { Column } from '@customTypes/table';

import Table from '@components/table/Table';
import StatusBadge from '@components/table/StatusBadge';
import ButtonRow from '@components/button/ButtonRow';
import { logTypes } from '@constants/enums/companyEnums';

const CventContactSingleLogsTable: React.FC<TableProps> = ({ logs, isFetching }) => (
    <Table
        columns={columns}
        rows={Object.values(logs)}
        isLoading={isFetching}
        defaultSortKey={2}
        defaultSortAsc={false}
        excludePagination
    />
);

const columns: Column<CventContactLog>[] = [
    {
        key: 1,
        heading: 'Description',
        getValue: row => row.body,
    },
    {
        key: 2,
        heading: 'Date of sync',
        getValue: row => dayjs(row.createdOn).format('DD/MM/YYYY'),
    },
    {
        key: 3,
        heading: '',
        getValue: row => (
            <ButtonRow alignment="right">
                <StatusBadge color={logTypes[row.type].color} icon={logTypes[row.type].icon}>
                    {logTypes[row.type].text}
                </StatusBadge>
            </ButtonRow>
        ),
    },
];

interface TableProps {
    logs: Record<number, CventContactLog>;
    isFetching: boolean;
}

export default CventContactSingleLogsTable;
