import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { Sync } from '@customTypes/shared/Sync';

export const fetchSyncsRunningRequest = createAction('fetchSyncsRunningRequest');
export const fetchSyncsRunningSuccess = createAction<Sync[]>('fetchSyncsRunningSuccess');
export const fetchSyncsRunningFailure = createAction('fetchSyncsRunningFailure');

export const fetchSyncsRunning = () => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchSyncsRunningRequest());
    try {
        const { data }: Response = await api.get(`syncs/running`);
        dispatch(
            fetchSyncsRunningSuccess(
                data.map(singleSync => {
                    return { ...singleSync, id: singleSync.sync.id };
                }),
            ),
        );
    } catch (e) {
        handleApiErrors(dispatch, fetchSyncsRunningFailure, e);
    }
};

interface Response {
    data: Sync[];
}
