const mediaQueries: MediaQueryProps = {
    lgDesktop: 1599,
    mdDesktop: 1279,
    smDesktop: 1099,
    lsTablet: 1023,
    ptTablet: 800,
    lgMobile: 767,
    mdMobile: 479,
    smMobile: 413,
};

interface MediaQueryProps {
    lgDesktop: number;
    mdDesktop: number;
    smDesktop: number;
    lsTablet: number;
    ptTablet: number;
    lgMobile: number;
    mdMobile: number;
    smMobile: number;
}

export default mediaQueries;
