import { Switch, Route, useRouteMatch } from 'react-router-dom';

import SyncList from '@pages/syncSchedule/SyncSchedule';
import SyncSingle from '@pages/syncSchedule/SyncSingle';

const SyncsRoutes: React.FC = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <SyncList />
            </Route>
            <Route exact path={`${path}/:id`}>
                <SyncSingle />
            </Route>
        </Switch>
    );
};

export default SyncsRoutes;
