import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { RootState } from '@reducers/index';

import { fetchSingleDotMailerContact } from '@actions/dotMailerContacts';
import {
    getDotMailerContact,
    getDotMailerContactConflicts,
    getDotMailerContactLogs,
    getDotMailerContactsFetchError,
    getDotMailerContactsIsFetching,
    getDotMailerContactsPostError,
    getDotMailerContactsPostSuccess,
    getDotMailerContactsSyncError,
} from '@selectors/dotMailerContacts';

import {
    conflictManagementDotmailerSingleBreadcrumb,
    dotMailerSingleBreadcrumb,
} from '@constants/breadcrumbItems';
import { isEmpty } from '@utils/generic';

import useSingleDotMailerContactFetch from './_hooks/useSingleDotMailerContactFetch';
import usePrevious from '@hooks/usePrevious';

import Description from '@components/typography/Description';
import Subtitle from '@components/typography/Subtitle';
import DotMailerContactSingleDetails from './DotMailerContactSingleDetails';
import DataCheck from '@components/generic/dataCheck/DataCheck';
import DotMailerContactSingleLogsTable from './DotMailerContactSingleLogsTable';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import DotMailerContactSingleSync from './DotMailerContactSingleSync';
import DotMailerContactSingleConflict from './DotMailerContactSingleConflict';
import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ErrorModal from '@components/modal/ErrorModal';
import Breadcrumb from '@components/breadcrumb/Breadcrumb';
import PageTitle from '@components/typography/PageTitle';

const DotMailerContactSingle: React.FC = () => {
    const dispatch = useDispatch();
    const { id } = useParams<ParamsType>();
    const { pathname } = useLocation();
    const [showPostError, setShowPostError] = useState(false);
    const [showSyncError, setShowSyncError] = useState(false);
    useSingleDotMailerContactFetch(id);
    const contact = useSelector((state: RootState) => getDotMailerContact(state, +id));
    const conflicts = useSelector(getDotMailerContactConflicts);
    const logs = useSelector(getDotMailerContactLogs);
    const isFetching = useSelector(getDotMailerContactsIsFetching);
    const error = useSelector(getDotMailerContactsFetchError);
    const postError = useSelector(getDotMailerContactsPostError);
    const postSuccess = useSelector(getDotMailerContactsPostSuccess);
    const syncError = useSelector(getDotMailerContactsSyncError);
    const prevProps = usePrevious({ postError, postSuccess, syncError });

    useEffect(() => {
        if (postError && !prevProps.postError) {
            setShowPostError(true);
        }

        if (syncError && !prevProps.syncError) {
            setShowSyncError(true);
        }

        if (postSuccess && !prevProps.postSuccess) {
            dispatch(fetchSingleDotMailerContact(id, true));
        }
    }, [
        dispatch,
        id,
        postError,
        prevProps.postError,
        syncError,
        prevProps.syncError,
        postSuccess,
        prevProps.postSuccess,
    ]);

    return (
        <div className="contacts-wrapper">
            <Breadcrumb
                items={
                    pathname.includes('conflict-management')
                        ? conflictManagementDotmailerSingleBreadcrumb
                        : dotMailerSingleBreadcrumb
                }
            />

            <CreateHeader>
                <PageTitle>Dotmailer Contact details</PageTitle>
                {!isEmpty(contact) && <DotMailerContactSingleSync />}
            </CreateHeader>
            <Description>View all the details of this Dotmailer contact.</Description>
            <DataCheck
                isEmpty={isEmpty(contact)}
                isFetching={isFetching}
                error={error}
                customWrapperClass="content-block"
            >
                <div className="single-section">
                    <DotMailerContactSingleDetails contact={contact} />
                </div>

                {!isEmpty(conflicts) && (
                    <div className="single-section">
                        <Subtitle>Conflicts</Subtitle>
                        {Object.values(conflicts).map(conflict => (
                            <ContentBlock key={conflict.id}>
                                <DotMailerContactSingleConflict
                                    contact={contact}
                                    conflict={conflict}
                                />
                            </ContentBlock>
                        ))}
                    </div>
                )}

                <div className="single-section">
                    <Subtitle>Activity Logs</Subtitle>
                    <DotMailerContactSingleLogsTable logs={logs} isFetching={isFetching} />
                </div>
            </DataCheck>

            {showPostError && (
                <ErrorModal
                    description={postError || undefined}
                    closeModal={() => setShowPostError(false)}
                />
            )}
            {showSyncError && (
                <ErrorModal
                    description={syncError || undefined}
                    closeModal={() => setShowSyncError(false)}
                />
            )}
        </div>
    );
};

type ParamsType = { id: string };

export default DotMailerContactSingle;
