import Form from '@components/form/Form';
import FormRow from '@components/form/FormRow';
import TextInput from '@components/form/TextInput';

import Modal from '@components/modal/Modal';
import useCreateAdmin from './hooks/useCreateAdmin';

const CreateAdminModal: React.FC = () => {
    const {
        isPosting,
        handleChange,
        handleSubmit,
        validateConfirmPassword,
        closeModal,
        formState: { email, firstName, lastName, password, confirmPassword },
    } = useCreateAdmin();

    return (
        <Modal closeModal={closeModal} title="Create Admin">
            <Form onSubmit={handleSubmit} isPosting={isPosting} onCancel={closeModal}>
                <FormRow>
                    <TextInput
                        type="email"
                        name="email"
                        value={email}
                        onChange={handleChange}
                        label="Email address"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="firstName"
                        value={firstName}
                        onChange={handleChange}
                        label="First name"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="lastName"
                        value={lastName}
                        onChange={handleChange}
                        label="Last Name"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="password"
                        value={password}
                        onChange={handleChange}
                        label="Password"
                        type="password"
                        required
                    />
                </FormRow>
                <FormRow>
                    <TextInput
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handleChange}
                        label="Confirm Password"
                        type="password"
                        customValidate={validateConfirmPassword}
                        required
                    />
                </FormRow>
            </Form>
        </Modal>
    );
};

export default CreateAdminModal;
