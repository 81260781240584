import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { LeadOrganisationsResponse } from '@customTypes/shared/LeadOrganisation';

export const fetchLeadOrganisationsRequest = createAction('fetchLeadOrganisationsRequest');
export const fetchLeadOrganisationsSuccess = createAction<LeadOrganisationsResponse>(
    'fetchLeadOrganisationsSuccess',
);
export const fetchLeadOrganisationsFailure = createAction('fetchLeadOrganisationsFailure');

export const fetchLeadOrganisations = (
    page: number,
    pageSize: number,
    searchTerm: string,
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchLeadOrganisationsRequest());
    try {
        const { data }: Response = await api.get(
            `LeadOrganisations?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}`,
        );
        dispatch(fetchLeadOrganisationsSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchLeadOrganisationsFailure, e);
    }
};

interface Response {
    data: LeadOrganisationsResponse;
}
