import { DotMailerSingleContactResponse } from '@customTypes/shared/DotMailerContact';
import { StringNumberType } from '@customTypes/shared/Generics';
import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

export const syncDotMailerContactRequest = createAction('syncDotMailerContactRequest');
export const syncDotMailerContactSuccess = createAction<DotMailerSingleContactResponse>(
    'syncDotMailerContactSuccess',
);
export const syncDotMailerContactFailure = createAction('syncDotMailerContactFailure');

export const syncDotMailerContact = (id: StringNumberType) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(syncDotMailerContactRequest());
    try {
        const { data }: Response = await api.post(`dotmailerContacts/${id}/sync`, null);
        dispatch(syncDotMailerContactSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, syncDotMailerContactFailure, e);
    }
};

interface Response {
    data: DotMailerSingleContactResponse;
}
