import { createAction, Dispatch } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { userRoles } from '@constants/enums/dropdownEnums';
import { User } from '@customTypes/shared/User';

export const createUserRequest = createAction('createUserRequest');
export const createUserSuccess = createAction<User>('createUserSuccess');
export const createUserFailure = createAction('createUserFailure');

export const createUser = (postbody: CreateUserRequestModel) => async (
    dispatch: Dispatch,
): Promise<void> => {
    dispatch(createUserRequest());
    try {
        const { data }: { data: User } = await api.post(`users`, postbody);

        dispatch(createUserSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, createUserFailure, e);
    }
};

interface CreateUserRequestModel {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    roles: userRoles[];
}
