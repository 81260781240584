import React from 'react';

import DataSearchTable from './DataSearchTable';
import Helmet from '@components/generic/helmet/Helmet';
import DataSearchBox from './DataSearchBox';
import PageTitle from '@components/typography/PageTitle';

const DataSearch: React.FC = () => {
    return (
        <div className="datasearch-wrapper">
            <Helmet title="Data Search" />
            <div className="flex-row justify-between">
                <div className="data-search-title">
                    <PageTitle>Data Search</PageTitle>
                </div>
                <DataSearchBox />
            </div>
            <DataSearchTable />
        </div>
    );
};

export default DataSearch;
