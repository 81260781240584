import { createReducer, PayloadAction } from '@reduxjs/toolkit';

import { setCurrentConflictTab } from '../actions/conflictManagement';

interface PendingConflictsState {
    currentTab: string;
}

const initialState: PendingConflictsState = {
    currentTab: 'conflictManagementCompanies',
};

export default createReducer(initialState, {
    [setCurrentConflictTab.type]: handleSetConflictTab,
});

function handleSetConflictTab(state: PendingConflictsState, action: PayloadAction<string>) {
    state.currentTab = action.payload;
}
