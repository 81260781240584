import ConflictManagementCompaniesTable from '@pages/conflictManagement/ConflictManagementCompaniesTable';
import ConflictManagementLeadOrgsTable from '@pages/conflictManagement/ConflictManagementLeadOrgsTable';
import ConflictManagementImmerseTable from '@pages/conflictManagement/ConflictManagementImmerseTable';
import ConflictManagementCventContactsTable from '@pages/conflictManagement/ConflictManagementCventContactsTable';
import ConflictManagementDotMailerTable from '@pages/conflictManagement/ConflictManagementDotMailerTable';

import { TabsType } from '@customTypes/tabs';

export const conflictManagementTabs: TabsType = {
    conflictManagementCompanies: {
        id: 'conflictManagementCompanies',
        text: 'Organisation Records',
        Component: ConflictManagementCompaniesTable,
    },
    conflictManagementDotMailer: {
        id: 'conflictManagementDotMailer',
        text: 'Dotdigital Contacts',
        Component: ConflictManagementDotMailerTable,
    },
    conflictManagementImmerse: {
        id: 'conflictManagementImmerse',
        text: 'Immerse UK Contacts',
        Component: ConflictManagementImmerseTable,
    },
    conflictManagementCvent: {
        id: 'conflictManagementCvent',
        text: 'Cvent Contacts',
        Component: ConflictManagementCventContactsTable,
    },
    conflictManagementLeadOrgs: {
        id: 'conflictManagementLeadOrgs',
        text: 'Lead Records',
        Component: ConflictManagementLeadOrgsTable,
    },
};
