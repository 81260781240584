import React, { useState } from 'react';

import { PendingEntry } from '@customTypes/shared/Company';
import { Column } from '@customTypes/table';

import usePendingEntryResolve from './_hooks/usePendingEntryResolve';

import Table from '@components/table/Table';
import ButtonRow from '@components/button/ButtonRow';
import ActionButton from '@components/button/ActionButton';
import CompanySinglePendingUpdateModal from './CompanySinglePendingUpdateModal';
import CompanySinglePendingIgnoreModal from './CompanySinglePendingIgnoreModal';

const CompanySingleLogsTable: React.FC<TableProps> = ({ pendingEntries }) => {
    const { resolvePendingEntry, deletePendingEntry } = usePendingEntryResolve();

    const columns: Column<PendingEntry>[] = [
        {
            key: 1,
            heading: 'Match Name',
            getValue: row => `${row.matchName}`,
        },
        {
            key: 2,
            heading: 'Will be updated:',
            getValue: row => (
                <>
                    <div>
                        <strong>Company ID:</strong> {`${row.companyID || '--'}`}
                    </div>
                    <div>
                        <strong>Sic Codes:</strong> {`${row.sicCodes || '--'}`}
                    </div>
                </>
            ),
        },
        {
            key: 3,
            heading: '',
            getValue: row => (
                <RenderConflictButtons
                    chooseConflicting={resolvePendingEntry}
                    ignore={deletePendingEntry}
                    id={row.id}
                />
            ),
        },
    ];

    return <Table columns={columns} rows={Object.values(pendingEntries)} />;
};

const RenderConflictButtons: React.FC<ConflictButtonsProps> = ({
    chooseConflicting = () => {},
    ignore = () => {},
    id,
}) => {
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showIgnoreModal, setShowIgnoreModal] = useState(false);
    return (
        <>
            <ButtonRow alignment="right">
                <ActionButton onClick={() => setShowUpdateModal(true)} icon="check">
                    Update to Workbooks
                </ActionButton>
                <span style={{ width: '5px' }}></span>
                <ActionButton onClick={() => setShowIgnoreModal(true)} color="red" icon="times">
                    Ignore
                </ActionButton>
            </ButtonRow>

            <CompanySinglePendingUpdateModal
                visible={showUpdateModal}
                handleClose={() => setShowUpdateModal(false)}
                handleSubmit={() => chooseConflicting(id)}
            />

            <CompanySinglePendingIgnoreModal
                visible={showIgnoreModal}
                handleClose={() => setShowIgnoreModal(false)}
                handleSubmit={() => ignore(id)}
            />
        </>
    );
};

interface TableProps {
    pendingEntries: Record<number, PendingEntry>;
}

interface ConflictButtonsProps {
    chooseConflicting: (id: number) => void;
    ignore: (id: number) => void;
    id: number;
}

export default CompanySingleLogsTable;
