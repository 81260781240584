import React from 'react';
import { StringNumberType } from '@customTypes/shared/Generics';

const PathLine: React.FC<PathLineProps> = ({
    startXY = [0, 0],
    endXY = [50, 50],
    strokeWidth = 3,
    strokeColor = '#222222',
    fill = 'transparent',
    customStyles = {},
}) => {
    return (
        <path
            d={`M ${startXY[0]} ${startXY[1]} T ${endXY[0]} ${endXY[1]}`}
            stroke={strokeColor}
            fill={fill}
            style={customStyles}
            strokeWidth={strokeWidth}
        />
    );
};

interface PathLineProps {
    startXY: number[];
    endXY: number[];
    fill?: string;
    strokeWidth?: number;
    strokeColor?: string;
    customStyles?: Record<string, StringNumberType>;
}

export default PathLine;
