import Routes from './routes';

import useGlobalRedirect from '@hooks/useGlobalRedirect';
import useDarkMode from '@hooks/useDarkMode';

import Nav from './components/nav/Nav';
import Footer from '@components/footer/Footer';

const App: React.FC = () => {
    useGlobalRedirect();
    const [darkMode] = useDarkMode();
    return (
        <div id="admin-body" className={`body ${darkMode ? 'dark-mode' : ''} `}>
            <div id="admin-wrapper">
                <Nav />
                <div id="admin-content" className="custom-scroll flex-column">
                    <div className="flex">
                        <Routes />
                    </div>

                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default App;
