import ButtonRow from '@components/button/ButtonRow';
import LinkButton from '@components/button/LinkButton';
import Table from '@components/table/Table';
import { Column } from '@customTypes/table';
import { AdminUser } from '@customTypes/shared/AdminUser';

const AdminsTable: React.FC<AdminsTableProps> = ({ adminUsers, isFetching }) => {
    return <Table columns={columns} rows={Object.values(adminUsers)} isLoading={isFetching} />;
};

const columns: Column<AdminUser>[] = [
    {
        key: 1,
        heading: 'Name',
        getValue: row => `${row.firstName} ${row.lastName}`,
        searchable: true,
    },
    {
        key: 2,
        heading: 'Email',
        getValue: row => row.email,
    },
    {
        key: 3,
        heading: '',
        getValue: row => (
            <ButtonRow alignment="right">
                <LinkButton href={`/admins/${row.id}`}>View details</LinkButton>
            </ButtonRow>
        ),
    },
];

interface AdminsTableProps {
    adminUsers: Record<number, AdminUser>;
    isFetching: boolean;
}

export default AdminsTable;
