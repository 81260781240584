import { useDispatch, useSelector } from 'react-redux';

import { postLeadOrganisationSync } from '@actions/leadOrganisations';
import { getLeadOrganisationsIsSyncing } from '@selectors/leadOrganisations';
import { StringNumberType } from '@customTypes/shared/Generics';

const useSingleLeadOrganisationSync = (id: StringNumberType): ReturnProps => {
    const dispatch = useDispatch();
    const isSyncing = useSelector(getLeadOrganisationsIsSyncing);

    const handleSync = () => {
        dispatch(postLeadOrganisationSync(id));
    };

    return { isSyncing, handleSync };
};

type HandleSync = () => void;

interface ReturnProps {
    isSyncing: boolean;
    handleSync: HandleSync;
}

export default useSingleLeadOrganisationSync;
