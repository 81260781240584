import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { CompaniesResponse } from '@customTypes/shared/Company';

export const fetchPendingCompaniesRequest = createAction('fetchPendingCompaniesRequest');
export const fetchPendingCompaniesSuccess = createAction<CompaniesResponse>(
    'fetchPendingCompaniesSuccess',
);
export const fetchPendingCompaniesFailure = createAction('fetchPendingCompaniesFailure');

export const fetchPendingCompanies = (page: number, pageSize: number, searchTerm: string) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(fetchPendingCompaniesRequest());
    try {
        const { data }: Response = await api.get(
            `Companies/pending?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}`,
        );
        dispatch(fetchPendingCompaniesSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchPendingCompaniesFailure, e);
    }
};

interface Response {
    data: CompaniesResponse;
}
