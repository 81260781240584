import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from '@utils/generic';

import {
    fetchCompaniesRequest,
    fetchCompaniesSuccess,
    fetchCompaniesFailure,
    fetchPendingCompaniesRequest,
    fetchPendingCompaniesSuccess,
    fetchPendingCompaniesFailure,
    fetchSingleCompanyRequest,
    fetchSingleCompanySuccess,
    fetchSingleCompanyFailure,
    syncCompanyRequest,
    syncCompanySuccess,
    syncCompanyFailure,
    deletePendingEntryRequest,
    deletePendingEntryFailure,
    deletePendingEntrySuccess,
    postPendingEntryResolveRequest,
    postPendingEntryResolveFailure,
    postPendingEntryResolveSuccess,
} from '@actions/companies';

import {
    Company,
    PendingEntry,
    CompanyLog,
    CompaniesResponse,
    SingleCompanyResponse,
} from '@customTypes/shared/Company';
import { PaginationProps } from '@customTypes/shared/Pagination';

interface CompaniesState extends PaginationProps {
    isFetching: boolean;
    isSyncing: boolean;
    fetchError: string | null;
    syncError: string | null;
    isDeleting: boolean;
    deleteError: string | null;
    isPosting: boolean;
    postError: string | null;
    postSuccess: boolean;
    companies: Record<number, Company>;
    pendingCompanies: Record<number, Company>;
    latestCompanyLogs: Record<number, CompanyLog>;
    pendingEntries: Record<number, PendingEntry>;
}

const initialState: CompaniesState = {
    isFetching: false,
    isSyncing: false,
    fetchError: null,
    syncError: null,
    isDeleting: false,
    deleteError: null,
    isPosting: false,
    postError: null,
    postSuccess: false,
    companies: {},
    latestCompanyLogs: {},
    pendingEntries: {},
    pendingCompanies: {},
    currentPage: 1,
    pageSize: 10,
    totalCount: null,
    totalPages: null,
};

export default createReducer(initialState, {
    [fetchCompaniesRequest.type]: handleFetchRequest,
    [fetchCompaniesFailure.type]: handleFetchFailure,
    [fetchCompaniesSuccess.type]: handleFetchAllSuccess,
    [fetchPendingCompaniesRequest.type]: handleFetchRequest,
    [fetchPendingCompaniesFailure.type]: handleFetchFailure,
    [fetchPendingCompaniesSuccess.type]: handleFetchPendingSuccess,
    [fetchSingleCompanyRequest.type]: handleFetchRequest,
    [fetchSingleCompanyFailure.type]: handleFetchFailure,
    [fetchSingleCompanySuccess.type]: handleFetchSingleSuccess,
    [syncCompanyRequest.type]: handleSyncRequest,
    [syncCompanyFailure.type]: handleSyncFailure,
    [syncCompanySuccess.type]: handleSyncSuccess,
    [deletePendingEntryRequest.type]: handleDeleteRequest,
    [deletePendingEntryFailure.type]: handleDeleteFailure,
    [deletePendingEntrySuccess.type]: handleDeletePendingEntrySuccess,
    [postPendingEntryResolveRequest.type]: handlePostRequest,
    [postPendingEntryResolveFailure.type]: handlePostFailure,
    [postPendingEntryResolveSuccess.type]: handleResolvePendingEntrySuccess,
});

function handleFetchRequest(state: CompaniesState) {
    state.isFetching = true;
    state.fetchError = null;
    state.latestCompanyLogs = {};
    state.pendingEntries = {};
}

function handleDeleteRequest(state: CompaniesState) {
    state.isDeleting = true;
    state.deleteError = null;
}

function handlePostRequest(state: CompaniesState) {
    state.isPosting = true;
    state.postError = null;
    state.postSuccess = false;
}

function handleFetchFailure(state: CompaniesState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.fetchError = action.payload;
}

function handleDeleteFailure(state: CompaniesState, action: PayloadAction<string>) {
    state.isDeleting = false;
    state.deleteError = action.payload;
}

function handlePostFailure(state: CompaniesState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.postError = action.payload;
}

function handleFetchAllSuccess(state: CompaniesState, action: PayloadAction<CompaniesResponse>) {
    const { companies, currentPage, pageSize, totalCount, totalPages } = action.payload;

    state.isFetching = false;
    state.companies = convertArrToObj(companies);
    state.currentPage = currentPage;
    state.pageSize = pageSize;
    state.totalCount = totalCount;
    state.totalPages = totalPages;
}

function handleFetchPendingSuccess(
    state: CompaniesState,
    action: PayloadAction<CompaniesResponse>,
) {
    const { companies, currentPage, pageSize, totalCount, totalPages } = action.payload;

    state.isFetching = false;
    state.pendingCompanies = convertArrToObj(companies);
    state.currentPage = currentPage;
    state.pageSize = pageSize;
    state.totalCount = totalCount;
    state.totalPages = totalPages;
}

function handleFetchSingleSuccess(
    state: CompaniesState,
    action: PayloadAction<SingleCompanyResponse>,
) {
    const { company, pendingEntries, latestCompanyLogs } = action.payload;

    state.isFetching = false;
    state.companies[company.id] = company;
    state.pendingEntries = convertArrToObj(pendingEntries);
    state.latestCompanyLogs = convertArrToObj(latestCompanyLogs);
}

function handleSyncRequest(state: CompaniesState) {
    state.isSyncing = true;
    state.syncError = null;
}

function handleSyncFailure(state: CompaniesState, action: PayloadAction<string>) {
    state.isSyncing = false;
    state.syncError = action.payload;
}

function handleSyncSuccess(state: CompaniesState, action: PayloadAction<SingleCompanyResponse>) {
    const { company, pendingEntries, latestCompanyLogs } = action.payload;

    state.isSyncing = false;
    state.companies[company.id] = company;
    state.pendingEntries = convertArrToObj(pendingEntries);
    state.latestCompanyLogs = convertArrToObj(latestCompanyLogs);
}

function handleDeletePendingEntrySuccess(state: CompaniesState, action: PayloadAction<number>) {
    state.isDeleting = false;
    delete state.pendingEntries[action.payload];
}

function handleResolvePendingEntrySuccess(
    state: CompaniesState,
    action: PayloadAction<SingleCompanyResponse>,
) {
    const { company, pendingEntries, latestCompanyLogs } = action.payload;

    state.isPosting = false;
    state.companies[company.id] = company;
    state.pendingEntries = convertArrToObj(pendingEntries);
    state.latestCompanyLogs = convertArrToObj(latestCompanyLogs);
    state.postSuccess = true;
}
