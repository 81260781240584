import React from 'react';

import { ImmerseUKContact } from '@customTypes/shared/ImmerseUKContact';

import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import Helmet from '@components/generic/helmet/Helmet';

const ImmerseUKContactSingleDetails: React.FC<ContactProps> = ({
    contact: {
        firstName,
        lastName,
        phone,
        twitter,
        userEmail,
        address1,
        address2,
        city,
        postcode,
        region,
        industry,
        description,
        immerseID,
        membershipType,
    },
}) => (
    <ContentBlock>
        <Helmet title={`${firstName ? firstName : ''} ${lastName ? lastName : ''}`} />
        <ContentRow>
            <ContentItem label="Name">
                <p>{!firstName && !lastName ? '--' : firstName + ' ' + lastName}</p>
            </ContentItem>
            <ContentItem label="Phone">
                <p>{!phone ? '--' : phone}</p>
            </ContentItem>
            <ContentItem label="Twitter">
                <p>{!twitter ? '--' : twitter}</p>
            </ContentItem>
        </ContentRow>

        <ContentRow>
            <ContentItem label="Email address">
                <p>{!userEmail ? '--' : userEmail}</p>
            </ContentItem>
            <ContentItem label="Address">
                <p>
                    {!address1 && !address2 && !city && !postcode && !region ? (
                        '--'
                    ) : (
                        <>
                            {!address1 ? '' : address1}
                            <br />
                            {!address2 ? '' : address2}
                            <br />
                            {!city ? '' : city}
                            <br />
                            {!postcode ? '' : postcode}
                            <br />
                            {!region ? '' : region}
                        </>
                    )}
                </p>
            </ContentItem>
            <ContentItem label="Industry">
                <p>{!industry ? '--' : industry}</p>
            </ContentItem>
        </ContentRow>

        <ContentRow>
            <ContentItem label="Description">
                <p>{!description ? '--' : description}</p>
            </ContentItem>
            <ContentItem label="Immerse ID">
                <p>{!immerseID ? '--' : immerseID}</p>
            </ContentItem>
            <ContentItem label="Membership Type">
                <p>{!membershipType ? '--' : membershipType}</p>
            </ContentItem>
        </ContentRow>
    </ContentBlock>
);

interface ContactProps {
    contact: ImmerseUKContact;
}

export default ImmerseUKContactSingleDetails;
