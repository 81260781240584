import { RootState } from '@reducers/index';
import {
    DotMailerContact,
    DotMailerContactConflict,
    DotMailerContactLog,
} from '@customTypes/shared/DotMailerContact';

export const getDotMailerContacts = (state: RootState): Record<number, DotMailerContact> =>
    state.dotMailerContactsReducer.contacts;
export const getPendingDotMailerContacts = (state: RootState): Record<number, DotMailerContact> =>
    state.dotMailerContactsReducer.pendingContacts;
export const getDotMailerContact = (state: RootState, id: number): DotMailerContact =>
    state.dotMailerContactsReducer.contacts[id];
export const getDotMailerContactsIsFetching = (state: RootState): boolean =>
    state.dotMailerContactsReducer.isFetching;
export const getDotMailerContactsFetchError = (state: RootState): string | null =>
    state.dotMailerContactsReducer.fetchError;
export const getDotMailerContactsIsSyncing = (state: RootState): boolean =>
    state.dotMailerContactsReducer.isSyncing;
export const getDotMailerContactsSyncError = (state: RootState): string | null =>
    state.dotMailerContactsReducer.syncError;
export const getDotMailerContactsIsPosting = (state: RootState): boolean =>
    state.dotMailerContactsReducer.isPosting;
export const getDotMailerContactsPostError = (state: RootState): string | null =>
    state.dotMailerContactsReducer.postError;
export const getDotMailerContactsPostSuccess = (state: RootState): boolean =>
    state.dotMailerContactsReducer.postSuccess;

export const getDotMailerContactsCurrentPage = (state: RootState): number =>
    state.dotMailerContactsReducer.currentPage;
export const getDotMailerContactsPageSize = (state: RootState): number =>
    state.dotMailerContactsReducer.pageSize;
export const getDotMailerContactsTotalPages = (state: RootState): number | null =>
    state.dotMailerContactsReducer.totalPages;
export const getDotMailerContactsTotalCount = (state: RootState): number | null =>
    state.dotMailerContactsReducer.totalCount;

export const getDotMailerContactConflicts = (
    state: RootState,
): Record<number, DotMailerContactConflict> => state.dotMailerContactsReducer.conflicts;
export const getDotMailerContactLogs = (state: RootState): Record<number, DotMailerContactLog> =>
    state.dotMailerContactsReducer.logs;
