import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { AdminUser } from '@customTypes/shared/AdminUser';

export const fetchAllAdminUsersRequest = createAction('fetchAllAdminUsersRequest');
export const fetchAllAdminUsersSuccess = createAction<AdminUser[]>('fetchAllAdminUsersSuccess');
export const fetchAllAdminUsersFailure = createAction('fetchAllAdminUsersFailure');

export const fetchAllAdminUsers = () => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchAllAdminUsersRequest());
    try {
        const { data }: Response = await api.get('admins');

        dispatch(fetchAllAdminUsersSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchAllAdminUsersFailure, e);
    }
};

interface Response {
    data: AdminUser[];
}
