import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DotMailerContact } from '@customTypes/shared/DotMailerContact';
import { Column } from '@customTypes/table';

import { fetchDotMailerContacts } from '@actions/dotMailerContacts';
import {
    getDotMailerContacts,
    getDotMailerContactsCurrentPage,
    getDotMailerContactsIsFetching,
    getDotMailerContactsPageSize,
    getDotMailerContactsTotalCount,
    getDotMailerContactsTotalPages,
} from '@selectors/dotMailerContacts';

import LinkButton from '@components/button/LinkButton';
import TableWithFetch from '@components/tableWithFetch/TableWithFetch';

import ButtonRow from '@components/button/ButtonRow';

const DotMailerContactsTable: React.FC = () => {
    const dispatch = useDispatch();
    const isFetching = useSelector(getDotMailerContactsIsFetching);
    const contacts = useSelector(getDotMailerContacts);
    const currentPage = useSelector(getDotMailerContactsCurrentPage);
    const pageSize = useSelector(getDotMailerContactsPageSize);
    const totalPages = useSelector(getDotMailerContactsTotalPages);
    const totalCount = useSelector(getDotMailerContactsTotalCount);

    const fetchData = useCallback(
        (page: number, pageSize: number, searchTerm: string) => {
            dispatch(fetchDotMailerContacts(page, pageSize, searchTerm));
        },
        [dispatch],
    );

    return (
        <TableWithFetch
            columns={columns}
            rows={Object.values(contacts)}
            isLoading={isFetching}
            fetchData={fetchData}
            currentPage={currentPage}
            pageSize={pageSize}
            totalPages={totalPages}
            totalCount={totalCount}
            searchType="dotmailerContacts"
        />
    );
};

const columns: Column<DotMailerContact>[] = [
    {
        key: 1,
        heading: 'Name',
        getValue: row =>
            `${row.firstName ? row.firstName : ''} ${row.lastName ? row.lastName : ''}`,
        searchable: true,
    },
    {
        key: 2,
        heading: 'Email address',
        getValue: row => `${row.email}`,
        searchable: true,
    },
    {
        key: 3,
        heading: 'Company',
        getValue: row => (row.companyName ? row.companyName : ''),
        searchable: true,
    },
    {
        key: 4,
        heading: 'Dotmailer ID',
        getValue: row => row.dotmailerID + '',
        searchable: true,
    },
    {
        key: 5,
        heading: '',
        getValue: row => (
            <ButtonRow alignment="right">
                <LinkButton color="grey" href={`/dotmailer-contacts/${row.id}`}>
                    View details
                </LinkButton>
            </ButtonRow>
        ),
        searchable: false,
    },
];

export default DotMailerContactsTable;
