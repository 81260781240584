import React, { Fragment } from 'react';

import { isEmpty } from '@utils/generic';

import DataCheck from '@components/generic/dataCheck/DataCheck';
import SingleFlowNode from './SingleFlowNode';
import { Flow, Node } from '@customTypes/shared/Flow';
import SingleFlowAPISource from './SingleFlowAPISource';
import SingleFlowAPIDest from './SingleFlowAPIDest';
import Arrows from './Arrows';

const formatNodes = (nodes: Node[]): FormattedNodes => {
    const result: Node[][] = [];
    // const nonEmpty = nodes.filter(node => node.fields.length); // Filter out nodes with no fields
    const orphans = nodes.filter(node => !node.previousNodeIDs.length && !node.nextNodeIDs.length);
    const rem1 = nodes.filter(node => node.previousNodeIDs.length || node.nextNodeIDs.length);
    result[0] = rem1.filter(node => !node.previousNodeIDs.length);
    const rem2 = rem1.filter(node => node.previousNodeIDs.length);
    const endNodes = rem2.filter(node => !node.nextNodeIDs.length);
    const rem3 = rem2.filter(node => node.nextNodeIDs.length);
    rem3.forEach((node: Node) => {
        if (result[result.length - 1][0].nextNodeIDs.includes(node.id)) {
            result.push([node]);
        } else if (result[result.length - 2][0].nextNodeIDs.includes(node.id)) {
            result[result.length - 1].push(node);
        }
    });

    return { result: [...result, endNodes], orphans };
};

const SingleFlow: React.FC<Props> = ({ flow, isFetching, fetchError }) => {
    const formattedNodes = formatNodes(flow.nodes);
    return (
        <DataCheck
            isEmpty={isEmpty(flow.nodes)}
            isFetching={isFetching}
            errorMessage={fetchError ? fetchError : undefined}
        >
            <div className="scroll-container flex-row justify-start">
                <div className="flow-container">
                    <div className="flow-wrapper flex-row justify-center align-stretch">
                        {/* Render Main Nodes */}
                        {formattedNodes.result.map((nodeGroup, i) => {
                            return (
                                <Fragment key={i}>
                                    {/* API Sources before Start Nodes */}
                                    {i === 0 && (
                                        <div className="flow-node-group">
                                            {nodeGroup.map((node: Node, k: number) => {
                                                return <SingleFlowAPISource key={k} node={node} />;
                                            })}
                                        </div>
                                    )}
                                    {/* Node group */}
                                    <div className="flow-node-group">
                                        {nodeGroup.map((node: Node, j: number) => {
                                            return <SingleFlowNode key={j} node={node} />;
                                        })}
                                    </div>
                                    {/* Arrows */}
                                    {i < formattedNodes.result.length - 1 && (
                                        <div className="flow-node-group">
                                            <Arrows
                                                nFrom={formattedNodes.result[i].length}
                                                nTo={formattedNodes.result[i + 1].length}
                                            />
                                        </div>
                                    )}
                                    {/* API Destinations after last Nodes */}
                                    {i === formattedNodes.result.length - 1 && (
                                        <div className="flow-node-group">
                                            {nodeGroup.map((node: Node, k: number) => {
                                                return <SingleFlowAPIDest key={k} node={node} />;
                                            })}
                                        </div>
                                    )}
                                </Fragment>
                            );
                        })}
                    </div>
                    {/* Render Orphan Nodes - DEBUG */}
                    {/* {!!formattedNodes.orphans.length && (
                        <>
                            <div style={{ borderBottom: '1px dashed' }} />
                            <div className="flow-wrapper orphans flex-row justify-center align-stretch">
                                <div className="flow-node-group">
                                    {formattedNodes.orphans.map((node: Node, k: number) => {
                                        return <SingleFlowAPISource key={k} node={node} />;
                                    })}
                                </div>

                                <div className="flow-node-group">
                                    {formattedNodes.orphans.map((node: Node, i: number) => {
                                        return <SingleFlowNode key={i} node={node} />;
                                    })}
                                </div>
                                <div className="flow-node-group">
                                    {formattedNodes.orphans.map((node: Node, k: number) => {
                                        return <SingleFlowAPIDest key={k} node={node} />;
                                    })}
                                </div>
                            </div>
                        </>
                    )} */}
                </div>
            </div>
        </DataCheck>
    );
};

interface Props {
    flow: Flow;
    isFetching: boolean;
    fetchError: string | null;
}

interface FormattedNodes {
    result: Node[][];
    orphans: Node[];
}

export default SingleFlow;
