import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { DotMailerContactsResponse } from '@customTypes/shared/DotMailerContact';

export const fetchDotMailerContactsRequest = createAction('fetchDotMailerContactsRequest');
export const fetchDotMailerContactsSuccess = createAction<DotMailerContactsResponse>(
    'fetchDotMailerContactsSuccess',
);
export const fetchDotMailerContactsFailure = createAction('fetchDotMailerContactsFailure');

export const fetchDotMailerContacts = (
    page: number,
    pageSize: number,
    searchTerm: string,
) => async (dispatch: AppDispatch): Promise<void> => {
    dispatch(fetchDotMailerContactsRequest());
    try {
        const { data }: Response = await api.get(
            `dotmailerContacts?page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}`,
        );

        dispatch(fetchDotMailerContactsSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchDotMailerContactsFailure, e);
    }
};

interface Response {
    data: DotMailerContactsResponse;
}
