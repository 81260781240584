import { ReactNode } from 'react';

const ContentBlock: React.FC<ContentBlockProps> = ({ children, extraClasses = '' }) => (
    <div className={`content-block ${extraClasses}`}>{children}</div>
);

interface ContentBlockProps {
    children: ReactNode;
    extraClasses?: string;
}

export default ContentBlock;
