import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { AdminUser } from '@customTypes/shared/AdminUser';

export const fetchSingleAdminUserRequest = createAction('fetchSingleAdminUserRequest');
export const fetchSingleAdminUserSuccess = createAction<AdminUser>('fetchSingleAdminUserSuccess');
export const fetchSingleAdminUserFailure = createAction('fetchSingleAdminUserFailure');

export const fetchSingleAdminUser = (id: number) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(fetchSingleAdminUserRequest());
    try {
        const { data }: AdminUserResponse = await api.get(`admins/${id}`);
        dispatch(fetchSingleAdminUserSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSingleAdminUserFailure, e);
    }
};

interface AdminUserResponse {
    data: AdminUser;
}
