import PageTitle from '@components/typography/PageTitle';
import Description from '@components/typography/Description';
import LeadOrganisationsTable from './LeadOrganisationsTable';

import Helmet from '@components/generic/helmet/Helmet';

const LeadOrganisations: React.FC = () => (
    <div className="lead-organisations-wrapper">
        <Helmet title="Lead records" />
        <PageTitle>Lead records</PageTitle>
        <Description>
            Here is a list of all the lead records currently in the system, use the search below to
            find a specific lead record.
        </Description>
        <LeadOrganisationsTable />
    </div>
);

export default LeadOrganisations;
