import React from 'react';

import { LeadOrganisation } from '@customTypes/shared/LeadOrganisation';

import ContentBlock from '@components/layout/contentBlock/ContentBlock';
import ContentItem from '@components/layout/contentBlock/ContentItem';
import ContentRow from '@components/layout/contentBlock/ContentRow';
import Helmet from '@components/generic/helmet/Helmet';

const LeadOrganisationSingleDetails: React.FC<DetailsProps> = ({
    leadOrganisation: {
        name,
        companyNumber,
        workbooksID,
        beauhurstProfile,
        sicCodes,
        iukGrantsTotal,
        numOfDirectors,
        numOfEmployees,
        postCode,
        turnover,
        leadSourceType,
    },
}) => (
    <ContentBlock>
        <Helmet title={name ? name : 'Lead Organisations'} />
        <ContentRow>
            <ContentItem label="Name">
                <p>{!name ? '--' : name}</p>
            </ContentItem>
            <ContentItem label="Company Number">
                <p>{!companyNumber ? '--' : companyNumber}</p>
            </ContentItem>
            <ContentItem label="Workbooks ID">
                <p>{!workbooksID ? '--' : workbooksID}</p>
            </ContentItem>
            <ContentItem label="Turnover">
                <p>{!turnover ? '--' : turnover}</p>
            </ContentItem>
        </ContentRow>

        <ContentRow>
            <ContentItem label="Beauhurst Profile">
                <p>
                    {!beauhurstProfile ? (
                        '--'
                    ) : (
                        <a href={beauhurstProfile} target="_blank" rel="noreferrer">
                            {beauhurstProfile}
                        </a>
                    )}
                </p>
            </ContentItem>
            <ContentItem label="Sic Codes">
                <p>{!sicCodes ? '--' : sicCodes}</p>
            </ContentItem>
            <ContentItem label="iUK Grants Total">
                <p>{!iukGrantsTotal ? '--' : iukGrantsTotal}</p>
            </ContentItem>
            <ContentItem label="Lead Source Type">
                <p>{!leadSourceType ? '--' : leadSourceType}</p>
            </ContentItem>
        </ContentRow>
        <ContentRow>
            <ContentItem label="Number of Employees">
                <p>{!numOfEmployees ? '--' : numOfEmployees}</p>
            </ContentItem>
            <ContentItem label="Number of Directors">
                <p>{!numOfDirectors ? '--' : numOfDirectors}</p>
            </ContentItem>
            <ContentItem label="Postcode">
                <p>{!postCode ? '--' : postCode}</p>
            </ContentItem>
            <ContentItem label="" />
        </ContentRow>
    </ContentBlock>
);

interface DetailsProps {
    leadOrganisation: LeadOrganisation;
}

export default LeadOrganisationSingleDetails;
