import { SingleCompanyResponse } from '@customTypes/shared/Company';
import { StringNumberType } from '@customTypes/shared/Generics';
import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';

export const syncCompanyRequest = createAction('syncCompanyRequest');
export const syncCompanySuccess = createAction<SingleCompanyResponse>('syncCompanySuccess');
export const syncCompanyFailure = createAction('syncCompanyFailure');

export const syncCompany = (id: StringNumberType) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(syncCompanyRequest());
    try {
        const { data }: Response = await api.post(`Companies/${id}`, null);
        dispatch(syncCompanySuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, syncCompanyFailure, e);
    }
};

interface Response {
    data: SingleCompanyResponse;
}
