import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { RootState } from '@reducers/index';
import LeadOrganisationLogTable from './LeadOrganisationLogTable';
import DataCheck from '@components/generic/dataCheck/DataCheck';
import LeadOrganisationSingleDetails from './LeadOrganisationSingleDetails';

import {
    conflictManagementLeadOrganisationsSingleBreadcrumb,
    leadOrganisationsSingleBreadcrumb,
} from '@constants/breadcrumbItems';
import { isEmpty } from '@utils/generic';

import useLeadOrganisationFetch from './hooks/useLeadOrganisationFetch';
import usePrevious from '@hooks/usePrevious';

import {
    getLeadOrganisationsIsFetching,
    getLeadOrganisationsFetchError,
    getLeadOrganisationSingle,
    getLeadOrganisationPendingChLeads,
    getLeadOrganisationPendingLeadEntries,
    getLeadOrganisationLogModels,
    getLeadOrganisationsIsDeleting,
    getLeadOrganisationsIsPosting,
    getLeadOrganisationsPostSuccess,
    getLeadOrganisationsSyncError,
} from '@selectors/leadOrganisations';
import { fetchLeadOrganisation } from '@actions/leadOrganisations';

import LeadOrganisationsPendingLeadEntryTable from './LeadOrganisationPendingLeadEntryTable';
import Subtitle from '@components/typography/Subtitle';
import Description from '@components/typography/Description';
import CreateHeader from '@components/layout/createHeader/CreateHeader';
import LeadOrganisationSingleSync from './LeadOrganisationSingleSync';
import LeadOrganisationPendingChLeadTable from './LeadOrganisationPendingChLeadTable';
import Breadcrumb from '@components/breadcrumb/Breadcrumb';
import ErrorModal from '@components/modal/ErrorModal';
import PageTitle from '@components/typography/PageTitle';

const LeadOrganisationSingle: React.FC = () => {
    const dispatch = useDispatch();
    const { id } = useParams<ParamsType>();
    const { pathname } = useLocation();
    useLeadOrganisationFetch(+id);

    const leadOrganisation = useSelector((state: RootState) =>
        getLeadOrganisationSingle(state, +id),
    );
    const pendingChLeads = useSelector(getLeadOrganisationPendingChLeads);
    const pendingLeadEntries = useSelector(getLeadOrganisationPendingLeadEntries);
    const logModels = useSelector(getLeadOrganisationLogModels);
    const isFetching = useSelector(getLeadOrganisationsIsFetching);
    const fetchError = useSelector(getLeadOrganisationsFetchError);
    const isDeleting = useSelector(getLeadOrganisationsIsDeleting);
    const isPosting = useSelector(getLeadOrganisationsIsPosting);
    const postSuccess = useSelector(getLeadOrganisationsPostSuccess);
    const syncError = useSelector(getLeadOrganisationsSyncError);
    const prevProps = usePrevious({ postSuccess, syncError });

    const [showError, setShowError] = useState(false);

    useEffect(() => {
        if (syncError && !prevProps.syncError) {
            setShowError(true);
        }

        if (postSuccess && !prevProps.postSuccess) {
            dispatch(fetchLeadOrganisation(id));
        }
    }, [dispatch, id, syncError, prevProps.syncError, postSuccess, prevProps.postSuccess]);

    useEffect(() => {
        if (!isDeleting && !isPosting) dispatch(fetchLeadOrganisation(id));
    }, [dispatch, id, isDeleting, isPosting]);

    return (
        <div className="lead-organisation-wrapper">
            <Breadcrumb
                items={
                    pathname.includes('conflict-management')
                        ? conflictManagementLeadOrganisationsSingleBreadcrumb
                        : leadOrganisationsSingleBreadcrumb
                }
            />

            <CreateHeader>
                <PageTitle>Lead Record Details</PageTitle>
                {!isEmpty(leadOrganisation) && <LeadOrganisationSingleSync />}
            </CreateHeader>
            <Description>View all the details and logs of this lead record.</Description>
            <DataCheck
                isFetching={isFetching}
                isEmpty={isEmpty(leadOrganisation)}
                error={fetchError}
            >
                <div className="single-section">
                    <LeadOrganisationSingleDetails leadOrganisation={leadOrganisation} />
                </div>

                {!isEmpty(pendingChLeads) && (
                    <div className="single-section">
                        <Subtitle>Pending Companies House Leads</Subtitle>
                        <Description>
                            This lead record was not found in companies house, see the closest
                            matches below.
                        </Description>
                        <Description>
                            <strong>Please note:</strong> The name <strong>will not</strong> be
                            updated.
                        </Description>
                        <LeadOrganisationPendingChLeadTable entries={pendingChLeads} />
                    </div>
                )}
                {!isEmpty(pendingLeadEntries) && (
                    <div className="single-section">
                        <Subtitle>Pending Lead Entries</Subtitle>
                        <LeadOrganisationsPendingLeadEntryTable entries={pendingLeadEntries} />
                    </div>
                )}

                <div className="single-section">
                    <Subtitle>Activity Logs</Subtitle>
                    <LeadOrganisationLogTable logs={logModels} isFetching={isFetching} />
                </div>
            </DataCheck>
            {showError && (
                <ErrorModal
                    description={syncError || undefined}
                    closeModal={() => setShowError(false)}
                />
            )}
        </div>
    );
};

type ParamsType = { id: string };

export default LeadOrganisationSingle;
