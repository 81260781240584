import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { setMenuOpen } from '@actions/mobileSettings';
import { getMenuOpen } from '@selectors/mobileSettings';

import usePrevious from '@hooks/usePrevious';

import AuthRoutes from './AuthRoutes';
import SubRouter from './SubRouter';
import AdminsRoutes from './AdminsRoutes';
import Dashboard from '@pages/dashboard/Dashboard';
import DotMailerContactsRoutes from './DotMailerContactsRoutes';
import LeadOrganisationsRoutes from './LeadOrganisationsRoutes';
import ImmerseUKContactsRoutes from './ImmerseUKContactsRoutes';
import CventContactsRoutes from './CventContactsRoutes';
import CompaniesRoutes from './CompaniesRoutes';
import SyncsRoutes from './SyncsRoutes';
import DataSearchRoutes from './DataSearchRoutes';
import ConflictManagementRoutes from './ConflictManagementRoutes';
import PageNotFound from '@pages/pageNotFound/PageNotFound';
import DefaultRedirect from './DefaultRedirect';

const Routes: React.FC = () => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const menuOpen = useSelector(getMenuOpen);
    const prevProps = usePrevious({ pathname });

    useEffect(() => {
        if (pathname !== prevProps.pathname && menuOpen) {
            dispatch(setMenuOpen(false));
        }
    }, [dispatch, pathname, prevProps.pathname, menuOpen]);

    return (
        <Switch>
            <SubRouter path="/auth">
                <AuthRoutes />
            </SubRouter>
            <SubRouter exact path="/" auth>
                <Dashboard />
            </SubRouter>
            <SubRouter exact path="/dashboard" auth>
                <Dashboard />
            </SubRouter>
            <SubRouter path="/syncs" auth>
                <SyncsRoutes />
            </SubRouter>
            <SubRouter path="/data-search" auth>
                <DataSearchRoutes />
            </SubRouter>

            <SubRouter path="/companies" auth>
                <CompaniesRoutes />
            </SubRouter>

            <SubRouter path="/lead-organisations" auth>
                <LeadOrganisationsRoutes />
            </SubRouter>

            <SubRouter path="/dotmailer-contacts" auth>
                <DotMailerContactsRoutes />
            </SubRouter>
            <SubRouter path="/immerse-uk-contacts" auth>
                <ImmerseUKContactsRoutes />
            </SubRouter>
            <SubRouter path="/cvent-contacts" auth>
                <CventContactsRoutes />
            </SubRouter>
            <SubRouter path="/conflict-management" auth>
                <ConflictManagementRoutes />
            </SubRouter>
            <SubRouter path="/admins" auth>
                <AdminsRoutes />
            </SubRouter>
            <DefaultRedirect to="/dashboard" />
            <SubRouter path="/page-not-found">
                <PageNotFound />
            </SubRouter>
            <Route>
                <Redirect to="/page-not-found" />
            </Route>
        </Switch>
    );
};

export default Routes;
