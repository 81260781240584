import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DropdownOption } from '@customTypes/shared/DropdownOption';
import { Column, TableFetchDataType } from '@customTypes/table';
import { PaginationProps } from '@customTypes/shared/Pagination';
import usePrevious from '@hooks/usePrevious';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@reducers/index';
import { getSearchTerm } from '@selectors/searchTerms';
import { setSavedSearchTerm } from '@actions/searchTerms';

export default function useTableWithFetch<T>({
    columns = [],
    rows = [],
    pageSizes = [],
    fetchData = () => {},
    currentPage,
    pageSize,
    totalCount,
    searchType,
}: useTableProps<T>) {
    const dispatch = useDispatch();
    const savedSearchTerm = useSelector((state: RootState) => getSearchTerm(state, searchType));
    const [sortColumn, setSortColumn] = useState(columns.find(col => col.getSort));
    const [sortAsc, setSortAsc] = useState(true);
    const [searchTerm, setSearchTerm] = useState(savedSearchTerm || '');
    const searchTermTimeout = useRef<number | null>(null);

    const [curPageSize, setCurPageSize] = useState(
        pageSizes.find(size => size === pageSize) || pageSizes[0],
    );
    const [page, setPage] = useState(currentPage);

    const prevProps = usePrevious({ searchTerm });

    useEffect(() => {
        if (searchTerm !== prevProps.searchTerm) {
            window.clearTimeout(searchTermTimeout.current || 0);
            searchTermTimeout.current = window.setTimeout(() => {
                dispatch(setSavedSearchTerm(searchType, searchTerm));
                setPage(1);
                fetchData(1, curPageSize, searchTerm);
            }, 1000);
        } else {
            fetchData(page, curPageSize, searchTerm);
        }
    }, [dispatch, searchType, fetchData, page, curPageSize, searchTerm, prevProps.searchTerm]);

    const filteredRows = useMemo(() => {
        const formatedTerm = searchTerm.replace(/\s/g, '').toLowerCase();
        if (!formatedTerm) return rows;

        return rows.filter(row =>
            columns
                .filter(col => col.searchable)
                .map(col => col.getValue(row))
                .join('')
                .replace(/\s/g, '')
                .toLowerCase()
                .includes(formatedTerm),
        );
    }, [searchTerm, rows, columns]);

    const sortedRows = useMemo(() => {
        if (!sortColumn) return rows;
        if (sortAsc) return [...filteredRows].sort(sortColumn.getSort);
        return [...filteredRows].sort((a, b) => {
            if (!sortColumn.getSort) {
                return 0;
            }
            return sortColumn.getSort(a, b) * -1;
        });
    }, [sortColumn, filteredRows, sortAsc, rows]);

    const handleSortClick = useCallback(
        (e, col) => {
            e.preventDefault();

            const isSortable = col.getSort !== undefined;
            if (!isSortable) return;

            if (sortColumn?.key === col.key) {
                setSortAsc(prev => !prev);
            } else {
                setSortColumn(col);
                setSortAsc(true);
            }
        },
        [sortColumn],
    );

    const pageSizeOpts: DropdownOption<number>[] = useMemo(
        () => pageSizes.map<DropdownOption<number>>(ps => ({ label: ps.toString(), value: ps })),
        [pageSizes],
    );

    const canSearch = useMemo(() => columns.some(col => col.searchable), [columns]);

    const paginationDescription = useMemo(() => {
        const rowCount = filteredRows.length;
        const isLastResults = rowCount < curPageSize;
        const startRow = rowCount ? (page - 1) * curPageSize + 1 : 0;
        let endRow: number = rowCount ? (page - 1) * curPageSize + curPageSize : 0;

        if (isLastResults && totalCount) endRow = totalCount;

        return `${startRow}-${endRow} of ${totalCount}`;
    }, [filteredRows, page, curPageSize, totalCount]);

    const handleSetPage = useCallback(size => {
        setCurPageSize(size);
        setPage(1);
    }, []);
    return {
        sortedRows,
        handleSortClick,
        sortColumn,
        sortAsc,
        setPage,
        page,
        pageSizeOpts,
        curPageSize,
        setPageSize: handleSetPage,
        paginationDescription,
        canSearch,
        searchTerm,
        setSearchTerm,
    };
}

interface useTableProps<T> extends PaginationProps {
    pageSizes: number[];
    rows: T[];
    columns: Column<T>[];
    fetchData: TableFetchDataType;
    searchType: string;
}
