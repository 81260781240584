import { Switch, Route, useRouteMatch } from 'react-router-dom';

import ImmerseUKContacts from '@pages/contacts/immerseUKContacts/ImmerseUKContacts';
import ImmerseUKContactSingle from '@pages/contacts/immerseUKContacts/ImmerseUKContactSingle';

const ImmerseUKContactsRoutes: React.FC = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={path}>
                <ImmerseUKContacts />
            </Route>
            <Route exact path={`${path}/:id`}>
                <ImmerseUKContactSingle />
            </Route>
        </Switch>
    );
};

export default ImmerseUKContactsRoutes;
