import ActionButton from '@components/button/ActionButton';
import ButtonRow from '@components/button/ButtonRow';
import Form from '@components/form/Form';
import FormRow from '@components/form/FormRow';
import TextInput from '@components/form/TextInput';
import Title from '@components/typography/Title';
import { CustomValidateFunction } from '@customTypes/shared/formValidation';

const LoginForm: React.FC<LoginFormProps> = ({
    formState: { email, password },
    handleChange,
    handleSubmit,
    isPosting,
    customValidate,
}) => (
    <>
        <Title>Login</Title>
        <Form onSubmit={handleSubmit} isPosting={isPosting} omitButtons>
            <FormRow>
                <TextInput
                    name="email"
                    type="email"
                    value={email}
                    label="Email address"
                    onChange={handleChange}
                    required
                />
            </FormRow>
            <FormRow>
                <TextInput
                    name="password"
                    value={password}
                    label="Password"
                    onChange={handleChange}
                    type="password"
                    required
                    customValidate={customValidate}
                />
            </FormRow>
            <ButtonRow alignment="right">
                <ActionButton icon="sign-in" isPosting={isPosting}>
                    Login
                </ActionButton>
            </ButtonRow>
        </Form>
    </>
);

interface LoginFormProps {
    handleChange: (name: string, value: string) => void;
    handleSubmit: () => void;
    isPosting: boolean;
    customValidate: CustomValidateFunction<string>;
    formState: {
        email: string;
        password: string;
    };
}

export default LoginForm;
