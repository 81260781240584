import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { convertArrToObj } from '@utils/generic';

import {
    fetchCventContactsRequest,
    fetchCventContactsSuccess,
    fetchCventContactsFailure,
    fetchPendingCventContactsRequest,
    fetchPendingCventContactsSuccess,
    fetchPendingCventContactsFailure,
    fetchSingleCventContactRequest,
    fetchSingleCventContactSuccess,
    fetchSingleCventContactFailure,
    updateCventConflictRequest,
    updateCventConflictSuccess,
    updateCventConflictFailure,
} from '@actions/cventContacts';

import {
    CventContact,
    CventContactConflict,
    CventContactLog,
    CventContactsResponse,
    CventSingleContactResponse,
} from '@customTypes/shared/CventContact';
import { PaginationProps } from '@customTypes/shared/Pagination';

interface CventContactsState extends PaginationProps {
    isFetching: boolean;
    isPosting: boolean;
    fetchError: string | null;
    postError: string | null;
    postSuccess: boolean;
    contacts: Record<number, CventContact>;
    pendingContacts: Record<number, CventContact>;
    logs: Record<number, CventContactLog>;
    conflicts: Record<number, CventContactConflict>;
}

const initialState: CventContactsState = {
    isFetching: false,
    isPosting: false,
    fetchError: null,
    postError: null,
    postSuccess: false,
    contacts: {},
    pendingContacts: {},
    logs: {},
    conflicts: {},
    currentPage: 1,
    pageSize: 10,
    totalCount: null,
    totalPages: null,
};

export default createReducer(initialState, {
    [fetchCventContactsRequest.type]: handleFetchRequest,
    [fetchCventContactsFailure.type]: handleFetchFailure,
    [fetchCventContactsSuccess.type]: handleFetchAllSuccess,
    [fetchPendingCventContactsRequest.type]: handleFetchRequest,
    [fetchPendingCventContactsFailure.type]: handleFetchFailure,
    [fetchPendingCventContactsSuccess.type]: handleFetchPendingSuccess,
    [fetchSingleCventContactRequest.type]: handleFetchRequest,
    [fetchSingleCventContactFailure.type]: handleFetchFailure,
    [fetchSingleCventContactSuccess.type]: handleFetchSingleSuccess,
    [updateCventConflictRequest.type]: handlePostRequest,
    [updateCventConflictFailure.type]: handlePostFailure,
    [updateCventConflictSuccess.type]: handleUpdateConflictSuccess,
});

function handleFetchRequest(state: CventContactsState, action: PayloadAction<boolean>) {
    state.isFetching = true;
    state.fetchError = null;
    if (!action.payload) {
        state.logs = {};
        state.conflicts = {};
    }
}

function handleFetchFailure(state: CventContactsState, action: PayloadAction<string>) {
    state.isFetching = false;
    state.fetchError = action.payload;
}

function handleFetchAllSuccess(
    state: CventContactsState,
    action: PayloadAction<CventContactsResponse>,
) {
    const { contacts, currentPage, pageSize, totalCount, totalPages } = action.payload;

    state.isFetching = false;
    state.contacts = convertArrToObj(contacts);
    state.currentPage = currentPage;
    state.pageSize = pageSize;
    state.totalCount = totalCount;
    state.totalPages = totalPages;
}

function handleFetchPendingSuccess(
    state: CventContactsState,
    action: PayloadAction<CventContactsResponse>,
) {
    const { contacts, currentPage, pageSize, totalCount, totalPages } = action.payload;

    state.isFetching = false;
    state.pendingContacts = convertArrToObj(contacts);
    state.currentPage = currentPage;
    state.pageSize = pageSize;
    state.totalCount = totalCount;
    state.totalPages = totalPages;
}

function handleFetchSingleSuccess(
    state: CventContactsState,
    action: PayloadAction<CventSingleContactResponse>,
) {
    const { contact, conflicts, logs } = action.payload;

    state.isFetching = false;
    state.contacts[contact.id] = contact;
    state.conflicts = convertArrToObj(conflicts);
    state.logs = convertArrToObj(logs);
}

function handlePostRequest(state: CventContactsState) {
    state.isPosting = true;
    state.postError = null;
    state.postSuccess = false;
}

function handlePostFailure(state: CventContactsState, action: PayloadAction<string>) {
    state.isPosting = false;
    state.postError = action.payload;
}

function handleUpdateConflictSuccess(state: CventContactsState, action: PayloadAction<number>) {
    state.isPosting = false;
    state.postSuccess = true;
    delete state.conflicts[action.payload];
}
