import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import usePrevious from '@hooks/usePrevious';
import { deleteAdminUser } from '@actions/adminUsers/deleteAdminUser';
import { getAdminUserIsPosting, getAdminUserPostSuccess } from '@selectors/adminUsers';
import { useHistory } from 'react-router-dom';

export default function useDeleteAdmin(userID: number): UseDeleteAdminReturn {
    const dispatch = useDispatch();
    const history = useHistory();

    const deleteUser = useCallback(() => {
        dispatch(deleteAdminUser(userID));
    }, [dispatch, userID]);

    const isPosting = useSelector(getAdminUserIsPosting);
    const successs = useSelector(getAdminUserPostSuccess);
    const prevSuccess = usePrevious(successs);

    useEffect(() => {
        if (!prevSuccess && successs) {
            history.replace('/admins');
        }
    }, [successs, prevSuccess, history]);

    return {
        deleteUser,
        isPosting,
    };
}

interface UseDeleteAdminReturn {
    deleteUser: () => void;
    isPosting: boolean;
}
