import { createAction } from '@reduxjs/toolkit';
import { api, handleApiErrors } from '@utils/api';
import { AppDispatch } from 'src/redux/store';
import { CventSingleContactResponse } from '@customTypes/shared/CventContact';
import { StringNumberType } from '@customTypes/shared/Generics';

export const fetchSingleCventContactRequest = createAction<boolean>(
    'fetchSingleCventContactRequest',
);
export const fetchSingleCventContactSuccess = createAction<CventSingleContactResponse>(
    'fetchSingleCventContactSuccess',
);
export const fetchSingleCventContactFailure = createAction('fetchSingleCventContactFailure');

export const fetchSingleCventContact = (id: StringNumberType, isRefetch = false) => async (
    dispatch: AppDispatch,
): Promise<void> => {
    dispatch(fetchSingleCventContactRequest(isRefetch));
    try {
        const { data }: Response = await api.get(`cventContacts/${id}`);
        dispatch(fetchSingleCventContactSuccess(data));
    } catch (e) {
        handleApiErrors(dispatch, fetchSingleCventContactFailure, e);
    }
};

interface Response {
    data: CventSingleContactResponse;
}
