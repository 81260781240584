export enum companyStatusValues {
    subscribed = 0,
    unsubscribed = 1,
    softBounced = 2,
    hardBounced = 3,
    ispComplained = 4,
    mailBlocked = 5,
    pendingOptIn = 6,
    directComplaint = 7,
    deleted = 8,
    sharedSuppression = 9,
    suppressed = 10,
    notAllowed = 11,
    domainSuppression = 12,
    noMxRecord = 13,
    notAvailableInThisVersion = 14,
}

export const companyStatusNames = {
    [companyStatusValues.subscribed]: 'Subscribed',
    [companyStatusValues.unsubscribed]: 'Unsubscribed',
    [companyStatusValues.softBounced]: 'Soft Bounced',
    [companyStatusValues.hardBounced]: 'Hard Bounced',
    [companyStatusValues.ispComplained]: 'ISP Complained',
    [companyStatusValues.mailBlocked]: 'Mail Blocked',
    [companyStatusValues.pendingOptIn]: 'Pending Opt In',
    [companyStatusValues.directComplaint]: 'Direct Complaint',
    [companyStatusValues.deleted]: 'Deleted',
    [companyStatusValues.sharedSuppression]: 'Shared Suppression',
    [companyStatusValues.suppressed]: 'Suppressed',
    [companyStatusValues.notAllowed]: 'Not Allowed',
    [companyStatusValues.domainSuppression]: 'Domain Suppression',
    [companyStatusValues.noMxRecord]: 'No Mx Record',
    [companyStatusValues.notAvailableInThisVersion]: 'Not Available In This Version',
};

export enum companyLogTypeValues {
    notification = 10,
    updated = 11,
    created = 12,
    deleted = 13,
    unchanged = 14,
    pending = 20,
    error = 30,
}

export const companyLogTypeNames = {
    [companyLogTypeValues.notification]: 'Success',
    [companyLogTypeValues.updated]: 'Updated',
    [companyLogTypeValues.created]: 'Created',
    [companyLogTypeValues.deleted]: 'Deleted',
    [companyLogTypeValues.unchanged]: 'Unchanged',
    [companyLogTypeValues.pending]: 'Pending',
    [companyLogTypeValues.error]: 'Error',
};

interface LogType {
    color?: string;
    text?: string;
    icon?: string;
}
export const logTypes: Record<number, LogType> = {
    [companyLogTypeValues.notification]: {
        color: 'green',
        text: companyLogTypeNames[companyLogTypeValues.notification],
        icon: 'check',
    },
    [companyLogTypeValues.updated]: {
        color: 'green',
        text: companyLogTypeNames[companyLogTypeValues.updated],
        icon: 'check',
    },
    [companyLogTypeValues.created]: {
        color: 'green',
        text: companyLogTypeNames[companyLogTypeValues.created],
        icon: 'check',
    },
    [companyLogTypeValues.deleted]: {
        color: 'green',
        text: companyLogTypeNames[companyLogTypeValues.deleted],
        icon: 'check',
    },
    [companyLogTypeValues.unchanged]: {
        color: 'green',
        text: companyLogTypeNames[companyLogTypeValues.unchanged],
        icon: 'check',
    },
    [companyLogTypeValues.pending]: {
        color: 'blue',
        text: companyLogTypeNames[companyLogTypeValues.pending],
        icon: 'ellipsis-h',
    },
    [companyLogTypeValues.error]: {
        color: 'red',
        text: companyLogTypeNames[companyLogTypeValues.error],
        icon: 'times',
    },
};
