import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getCompaniesDeleteError, getCompaniesIsDeleting } from '@selectors/companies';

import usePrevious from '@hooks/usePrevious';

import ConfirmModal from '@components/modal/ConfirmModal';
import ErrorModal from '@components/modal/ErrorModal';

const CompanySinglePendingIgnoreModal: React.FC<ModalProps> = ({
    visible,
    handleClose,
    handleSubmit,
}) => {
    const [showError, setShowError] = useState(false);
    const isDeleting = useSelector(getCompaniesIsDeleting);
    const deleteError = useSelector(getCompaniesDeleteError);
    const prevProps = usePrevious({ deleteError });

    useEffect(() => {
        if (deleteError && !prevProps.deleteError && visible) {
            setShowError(true);
        }
    }, [deleteError, prevProps.deleteError, visible]);

    if (!visible) return null;

    if (showError)
        return <ErrorModal description={deleteError || undefined} closeModal={closeError} />;

    return (
        <ConfirmModal
            description="Are you sure you would like to ignore this entry?"
            isPosting={isDeleting}
            handleSubmit={handleSubmit}
            closeModal={handleClose}
        />
    );

    function closeError(): void {
        setShowError(false);
        handleClose();
    }
};

interface ModalProps {
    visible: boolean;
    handleClose: () => void;
    handleSubmit: () => void;
}

export default CompanySinglePendingIgnoreModal;
